import React, { FC } from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';

interface ComponentProps extends RouteProps {
  isAuthenticated: boolean;
}

export const PrivateRoute: FC<ComponentProps> = ({ children, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
