import React, { FC, useCallback, useState } from 'react';
import pageStyles from 'assets/styles/page.module.scss';
import './commerces.scss';
import { Button, Input, Pagination, Table } from 'semantic-ui-react';
import { CustCommercePanel } from './commerce-panel';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import DefaultUserAvatar from '../../../assets/images/galery.png';

export type ComponentProps = {
  commerces: Customer[];
  pagination: PaginationProps;
  onSearch: (value: string) => void;
  onPageChange: (page: number) => void;
};

export const CommerceUnitsPage: FC<ComponentProps> = ({
  commerces,
  pagination,
  onSearch,
  onPageChange,
}) => {
  const [showCustCommercePanel, setShowCustCommercePanel] = useState(false);
  const [selectedCustCommerce, setSelectedCustCommerce] = useState<string>(null);

  const openCustCommercePanel = useCallback((custCommerceId: string) => {
    setSelectedCustCommerce(custCommerceId);
    setShowCustCommercePanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Clientes</div>
        <div className={pageStyles.tableHeader}>
          <div className={pageStyles.controls}>
            <Input
              icon="search"
              placeholder="Buscar"
              className={pageStyles.searchInput}
              onChange={(_, data) => onSearch(data.value)}
            />
          </div>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedCustCommerce(null);
              setShowCustCommercePanel(true);
            }}
          >
            Crear Cliente <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} textAlign="center" />
                <Table.HeaderCell width={3}>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Código Único</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {commerces.map((commerce) => (
                <Table.Row key={commerce.id}>
                  <Table.Cell className={pageStyles.userAvatar} textAlign="center">
                    <img
                      src={commerce.imageUrl ?? DefaultUserAvatar}
                      alt="deviceReference"
                      className={pageStyles.avatar}
                    />
                  </Table.Cell>
                  <Table.Cell className={pageStyles.boldText}>{commerce.name}</Table.Cell>
                  <Table.Cell>{commerce.uniqueCode}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction} textAlign="center">
                    <div
                      onClick={() => openCustCommercePanel(commerce.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <CustCommercePanel
        visible={showCustCommercePanel}
        onClose={() => {
          setSelectedCustCommerce(null);
          setShowCustCommercePanel(false);
        }}
        customerId={selectedCustCommerce}
      />
      {pagination && (
        <div className={pageStyles.paginationContainer}>
          <Pagination
            totalPages={pagination.totalPages}
            activePage={pagination.pageNumber}
            firstItem={null}
            lastItem={null}
            onPageChange={(event, data) => onPageChange(data.activePage as number)}
          />
        </div>
      )}
    </div>
  );
};
