import React, { FC, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  createDeviceTypeGQL,
  deleteDeviceTypeGQL,
  deviceTypeGQL,
  updateDeviceTypeGQL,
} from 'graphql/device-type';

import { DeviceTypePanel as Component } from './type-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  deviceTypeId: string;
};

export const DeviceTypePanel: FC<ComponentProps> = ({ deviceTypeId, onClose, visible }) => {
  const { data: deviceTypeData } = useQuery<{ deviceType: DeviceType }>(deviceTypeGQL, {
    variables: { deviceTypeId: parseFloat(deviceTypeId) },
    skip: deviceTypeId === null,
  });

  const [createDeviceTypeMutation] = useMutation(createDeviceTypeGQL);
  const [updateDeviceTypeMutation] = useMutation(updateDeviceTypeGQL);
  const [deleteDeviceTypeMutation, { loading }] = useMutation(deleteDeviceTypeGQL);

  const createDeviceType = useCallback(
    async (deviceType: DeviceType) => {
      await createDeviceTypeMutation({ variables: { deviceType } });
    },
    [createDeviceTypeMutation],
  );
  const updateDeviceType = useCallback(
    async (deviceType: DeviceType) => {
      await updateDeviceTypeMutation({
        variables: { deviceTypeId, deviceType },
      });
    },
    [deviceTypeId, updateDeviceTypeMutation],
  );
  const deleteDeviceType = useCallback(async () => {
    await deleteDeviceTypeMutation({ variables: { deviceTypeId: parseFloat(deviceTypeId) } });
  }, [deviceTypeId, deleteDeviceTypeMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      deviceType={deviceTypeData?.deviceType ?? null}
      updateDeviceType={updateDeviceType}
      createDeviceType={createDeviceType}
      deleteDeviceType={deleteDeviceType}
      deletingDeviceType={loading}
    />
  );
};
