import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, Form, Icon } from 'semantic-ui-react';
import DefaultUserAvatar from '../../../../assets/images/galery.png';
import styles from './commerce-panel.module.scss';

interface ComponentProps {
  visible: boolean;
  onClose: () => void;
  custCommerce: Customer;
  updateCustCommerce: (custCommerce: Customer) => Promise<void>;
  createCustCommerce: (custCommerce: Customer) => Promise<void>;
  deleteCustCommerce: () => Promise<void>;
  deletingCustCommerce: boolean;
}

interface FormValues {
  name: string;
  uniqueCode: string;
}

const initialValues: FormValues = {
  name: '',
  uniqueCode: '',
};

export const CustCommercePanel: FC<ComponentProps> = ({
  visible,
  onClose,
  custCommerce,
  updateCustCommerce,
  createCustCommerce,
  deleteCustCommerce,
  deletingCustCommerce,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const fileRef = useRef<HTMLInputElement>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);
  const [userAvatarUrl, setUserAvatarUrl] = useState(custCommerce?.imageUrl ?? DefaultUserAvatar);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      if (custCommerce) updateCustCommerce(values).then(onClose);
      else createCustCommerce(values).then(onClose);
    },
    [custCommerce, updateCustCommerce, createCustCommerce, onClose],
  );

  useEffect(() => {
    if (custCommerce) {
      formRef.current.setFieldValue('name', custCommerce.name);
      formRef.current.setFieldValue('uniqueCode', custCommerce.uniqueCode);
      setUserAvatarUrl(custCommerce.imageUrl ?? DefaultUserAvatar);
    } else {
      setUserAvatarUrl(DefaultUserAvatar);
    }
  }, [custCommerce]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">{custCommerce?.name ?? 'Crear Comercio'}</div>
      {custCommerce && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingCustCommerce}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar el comercio seleccionado?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteCustCommerce().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit} innerRef={formRef}>
        {({ handleSubmit, values, isSubmitting, setFieldValue }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <div className={styles.profileImageSelector}>
                <div className={styles.avatarContainer}>
                  <img src={userAvatarUrl} alt="" className={styles.avatar} />
                </div>
                <Button
                  icon="camera"
                  circular
                  size="small"
                  className={styles.cameraButton}
                  onClick={() => fileRef.current.click()}
                  type="button"
                />
              </div>
              <div hidden>
                <input
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/png, image/jpeg"
                  ref={fileRef}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('imageFile', event.target.files[0]);
                    setUserAvatarUrl(URL.createObjectURL(event.target.files[0]));
                  }}
                />
              </div>
              <FastField
                name="name"
                label="Nombre"
                component={InputField}
                value={values.name}
                placeholder="Nombre comercio"
              />
              <FastField
                name="uniqueCode"
                label="Código Único"
                component={InputField}
                value={values.uniqueCode}
                placeholder="Código Único"
              />
              <div className="buttons-container">
                <Button size="medium" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="medium" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
