import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  allServiceStatesGQL,
  serviceStateCreatedGQL,
  serviceStateDeletedGQL,
  serviceStateUpdatedGQL,
} from 'graphql/service-state';
import { ServiceStates as Component } from './service-states';

export const ServiceStates: FC = () => {
  const { data, refetch } = useQuery<{ serviceStates: ServiceState[] }>(allServiceStatesGQL);

  useSubscription(serviceStateUpdatedGQL);
  useSubscription(serviceStateCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(serviceStateDeletedGQL, { onSubscriptionData: () => refetch() });

  return <Component serviceStates={data?.serviceStates ?? []} />;
};
