import { gql } from '@apollo/client';

export const allFranchiseTypesGQL = gql`
  query allFranchiseTypes {
    franchiseTypes: allFranchiseTypes {
      id
      name
      priority
    }
  }
`;

export const franchiseTypeGQL = gql`
  query franchiseType($franchiseTypeId: String!) {
    franchiseType(franchiseTypeId: $franchiseTypeId) {
      id
      name
      priority
    }
  }
`;

export const createFranchiseTypeGQL = gql`
  mutation createFranchiseType($franchiseTypeData: FranchiseTypeInputType!) {
    createFranchiseType(franchiseTypeData: $franchiseTypeData) {
      id
      name
      priority
    }
  }
`;

export const updateFranchiseTypeGQL = gql`
  mutation updateFranchiseType(
    $franchiseTypeId: String!
    $franchiseTypeData: FranchiseTypeInputType!
  ) {
    updateFranchiseType(franchiseTypeId: $franchiseTypeId, franchiseTypeData: $franchiseTypeData) {
      message
      entityId
    }
  }
`;

export const deleteFranchiseTypeGQL = gql`
  mutation deleteFranchiseType($franchiseTypeId: String!) {
    deleteFranchiseType(franchiseTypeId: $franchiseTypeId) {
      message
      entityId
    }
  }
`;

export const franchiseTypeCreatedGQL = gql`
  subscription franchiseTypeCreated {
    franchiseTypeCreated {
      id
      name
      priority
    }
  }
`;

export const franchiseTypeUpdatedGQL = gql`
  subscription franchiseTypeUpdated {
    franchiseTypeUpdated {
      id
      name
      priority
    }
  }
`;

export const franchiseTypeDeletedGQL = gql`
  subscription franchiseTypeDeleted {
    franchiseTypeDeleted {
      message
      entityId
    }
  }
`;
