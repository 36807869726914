import React, { FC, useCallback, useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import pageStyles from 'assets/styles/page.module.scss';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import { DemoTypePanel } from './demo-type-panel';

export type ComponentProps = {
  demoTypes: DemoType[];
};

export const DemoTypes: FC<ComponentProps> = ({ demoTypes }) => {
  const [showDemoTypePanel, setShowDemoTypePanel] = useState(false);
  const [selectedDemoType, setSelecteDemoType] = useState<string>(null);

  const openDemoTypePanel = useCallback((demoTypeId: string) => {
    setSelecteDemoType(demoTypeId);
    setShowDemoTypePanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Tipos de Demo</div>
        <div className={pageStyles.tableHeader}>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelecteDemoType(null);
              setShowDemoTypePanel(true);
            }}
          >
            Crear Tipo <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>

      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {demoTypes.map((demoType) => (
                <Table.Row key={demoType.id}>
                  <Table.Cell className={pageStyles.boldText}>{demoType.name}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openDemoTypePanel(demoType.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <DemoTypePanel
        visible={showDemoTypePanel}
        onClose={() => {
          setSelecteDemoType(null);
          setShowDemoTypePanel(false);
        }}
        demoTypeId={selectedDemoType}
      />
    </div>
  );
};
