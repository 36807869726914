import React, { FC, useCallback } from 'react';
import { Button, Form, Icon } from 'semantic-ui-react';
import { ValidationMessages } from 'utils/validation-messages';
import { FastField, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import styles from './active-chat.module.scss';
import { InputField } from '../../../../components/input-field';

type FormValues = {
  message: string;
};

const initialValues: FormValues = {
  message: '',
};

const validationSchema = Yup.object().shape<FormValues>({
  message: Yup.string().required(ValidationMessages.required),
});

type ComponentProps = {
  chat: Chat;
  createChatMessage: (message: string) => void;
  chatMessages: ChatMessage[];
  finishChat: (chatId: string) => void;
};

export const ActiveChat: FC<ComponentProps> = ({
  chat,
  createChatMessage,
  chatMessages,
  finishChat,
}) => {
  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      createChatMessage(values.message);
      helpers.resetForm();
    },
    [createChatMessage],
  );

  if (chat === null) return null;
  return (
    <div className={styles.activeChatContainer}>
      <div className={styles.header}>
        <div className={styles.franchise}>
          {chat.user?.franchise && (
            <>
              <div>{chat.user.franchise.name}</div>
              <div className={styles.franchiseType}>{chat.user.franchise.franchiseType?.name}</div>
            </>
          )}
        </div>
        <div className={styles.userInfo}>
          <div className={styles.avatarContainer}>
            <img src={chat.user.profileImageUrl} alt="" className={styles.avatar} />
          </div>
          <div>
            <div>{chat.user.firstName}</div>
            <div>{chat.user.lastName}</div>
          </div>
        </div>
        <div>
          <Button primary size="mini" onClick={() => finishChat(chat.id)}>
            Finalizar
          </Button>
        </div>
      </div>
      <div className={styles.messages}>
        {chatMessages.map((message) => (
          <div
            className={styles.message}
            key={message.id}
            style={{ alignSelf: chat.user.id === message.user.id ? 'flex-start' : 'flex-end' }}
          >
            {message.message}
          </div>
        ))}
      </div>
      <div className={styles.form}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({ submitForm }) => (
            <>
              <div className={styles.inputContainer}>
                <FastField
                  name="message"
                  component={InputField}
                  placeholder="Escriba un mensaje"
                  className={styles.messageInput}
                />
              </div>
              <div className={styles.buttonContainer}>
                <Button primary icon size="medium" onClick={submitForm}>
                  Enviar &nbsp; <Icon name="send" />
                </Button>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};
