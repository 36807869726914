import { gql } from '@apollo/client';

export const techniciansGQL = gql`
  query technicians($pageNumber: Float, $pageSize: Float, $searchTerm: String) {
    technicians(pageNumber: $pageNumber, pageSize: $pageSize, searchTerm: $searchTerm) {
      data {
        id
        username
        firstName
        lastName
        email
        phoneNumber
        enabled
        profileImageUrl
        documentNumber
        jobTitle
      }
      pageSize
      pageNumber
      totalPages
    }
  }
`;

export const allTechniciansGQL = gql`
  query allTechnicians {
    technicians: allTechnicians {
      id
      username
      firstName
      lastName
      email
      phoneNumber
      enabled
      profileImageUrl
      jobTitle
      documentNumber
    }
  }
`;

export const technicianGQL = gql`
  query technician($technicianId: String!) {
    technician(technicianId: $technicianId) {
      id
      username
      firstName
      lastName
      email
      phoneNumber
      enabled
      profileImageUrl
      jobTitle
      documentNumber
    }
  }
`;

export const createTechnicianGQL = gql`
  mutation createTechnician($technicianData: TechnicianInputType!) {
    createTechnician(technicianData: $technicianData) {
      id
      username
      firstName
      lastName
      email
      phoneNumber
      enabled
      profileImageUrl
      jobTitle
      documentNumber
    }
  }
`;

export const updateTechnicianGQL = gql`
  mutation updateTechnician($technicianId: String!, $technicianData: TechnicianInputType!) {
    updateTechnician(technicianId: $technicianId, technicianData: $technicianData) {
      message
      entityId
    }
  }
`;

export const deleteTechnicianGQL = gql`
  mutation deleteTechnician($technicianId: String!) {
    deleteTechnician(technicianId: $technicianId) {
      message
      entityId
    }
  }
`;

export const technicianCreatedGQL = gql`
  subscription technicianCreated {
    technicianCreated {
      id
      username
      firstName
      lastName
      email
      phoneNumber
      enabled
      profileImageUrl
      jobTitle
      documentNumber
    }
  }
`;
export const technicianUpdatedGQL = gql`
  subscription technicianUpdated {
    technicianUpdated {
      id
      username
      firstName
      lastName
      email
      phoneNumber
      enabled
      profileImageUrl
      jobTitle
      documentNumber
    }
  }
`;
export const technicianDeletedGQL = gql`
  subscription technicianDeleted {
    technicianDeleted {
      message
      entityId
    }
  }
`;
