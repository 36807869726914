import React, { FC, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  serviceReasonGQL,
  createServiceReasonGQL,
  updateServiceReasonGQL,
  deleteServiceReasonGQL,
} from 'graphql/service-reason';

import { ServiceReasonPanel as Component } from './service-reason-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  serviceReasonId: string;
};

export const ServiceReasonPanel: FC<ComponentProps> = ({ serviceReasonId, onClose, visible }) => {
  const { data } = useQuery<{ serviceReason: ServiceReason }>(serviceReasonGQL, {
    variables: { serviceReasonId },
    skip: serviceReasonId === null,
  });

  const [createServiceReasonMutation] = useMutation(createServiceReasonGQL);
  const [updateServiceReasonMutation] = useMutation(updateServiceReasonGQL);
  const [deleteServiceReasonMutation, { loading }] = useMutation(deleteServiceReasonGQL);

  const createServiceReason = useCallback(
    async (serviceReasonData: ServiceReason) => {
      await createServiceReasonMutation({ variables: { serviceReasonData } });
    },
    [createServiceReasonMutation],
  );

  const updateServiceReason = useCallback(
    async (serviceReasonData: ServiceReason) => {
      await updateServiceReasonMutation({ variables: { serviceReasonId, serviceReasonData } });
    },
    [serviceReasonId, updateServiceReasonMutation],
  );

  const deleteServiceReason = useCallback(async () => {
    await deleteServiceReasonMutation({ variables: { serviceReasonId } });
  }, [serviceReasonId, deleteServiceReasonMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      serviceReason={data?.serviceReason ?? null}
      updateServiceReason={updateServiceReason}
      createServiceReason={createServiceReason}
      deleteServiceReason={deleteServiceReason}
      deletingServiceReason={loading}
    />
  );
};
