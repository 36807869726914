import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  allServiceReasonsGQL,
  serviceReasonCreatedGQL,
  serviceReasonDeletedGQL,
  serviceReasonUpdatedGQL,
} from 'graphql/service-reason';

import { ServiceReasons as Component } from './service-reasons';

export const ServiceReasons: FC = () => {
  const { data, refetch } = useQuery<{ serviceReasons: ServiceReason[] }>(allServiceReasonsGQL);

  useSubscription(serviceReasonUpdatedGQL);
  useSubscription(serviceReasonCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(serviceReasonDeletedGQL, { onSubscriptionData: () => refetch() });

  return <Component serviceReasons={data?.serviceReasons ?? []} />;
};
