import React, { FC, useState } from 'react';
import { Modal, Tab } from 'semantic-ui-react';
import ManageAccountIcon from '@material-ui/icons/AccountCircleOutlined';

import { UserDetailsForm } from './user-details';
import { PasswordForm } from './password';

import styles from './edit-profile.module.scss';

export const EditProfile: FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.logoutItem}>
        <div className={styles.label}>Editar Perfil</div>
        <Modal
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          trigger={<ManageAccountIcon className={styles.icon} />}
          className="modal-selector-containe"
          size="mini"
          centered={false}
        >
          <Modal.Header className={styles.modalTitle}>Editar Perfil</Modal.Header>
          <Modal.Content>
            <Tab
              menu={{ secondary: true }}
              className={styles.tabsContainer}
              panes={[
                {
                  menuItem: 'Datos de Usuario',
                  render: () => <UserDetailsForm />,
                },
                {
                  menuItem: 'Contraseña',
                  render: () => <PasswordForm />,
                },
              ]}
            />
          </Modal.Content>
        </Modal>
      </div>
    </div>
  );
};
