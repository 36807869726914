import { gql } from '@apollo/client';

export const suppliesGQL = gql`
  query supplies($pageSize: Float, $pageNumber: Float, $searchTerm: String) {
    supplies(pageSize: $pageSize, pageNumber: $pageNumber, searchTerm: $searchTerm) {
      data {
        id
        name
        stockAmount
        supplyType {
          id
          name
        }
      }
      pageSize
      pageNumber
      totalPages
    }
  }
`;

export const allSuppliesGQL = gql`
  query allSupplies {
    supplies: allSupplies {
      id
      name
      stockAmount
      supplyType {
        id
        name
      }
    }
  }
`;

export const supplyGQL = gql`
  query supply($supplyId: String!) {
    supply(supplyId: $supplyId) {
      id
      name
      stockAmount
      supplyType {
        id
        name
      }
    }
  }
`;

export const createSupplyGQL = gql`
  mutation createSupply($supplyData: SupplyInputType!) {
    createSupply(supplyData: $supplyData) {
      id
      name
      stockAmount
      supplyType {
        id
        name
      }
    }
  }
`;

export const updateSupplyGQL = gql`
  mutation updateSupply($supplyId: String!, $supplyData: SupplyInputType!) {
    updateSupply(supplyId: $supplyId, supplyData: $supplyData) {
      message
      entityId
    }
  }
`;

export const deleteSupplyGQL = gql`
  mutation deleteSupply($supplyId: String!) {
    deleteSupply(supplyId: $supplyId) {
      message
      entityId
    }
  }
`;

export const supplyCreatedGQL = gql`
  subscription supplyCreated {
    supplyCreated {
      id
      name
      stockAmount
      supplyType {
        id
        name
      }
    }
  }
`;

export const supplyUpdatedGQL = gql`
  subscription supplyUpdated {
    supplyUpdated {
      id
      name
      stockAmount
      supplyType {
        id
        name
      }
    }
  }
`;

export const supplyDeletedGQL = gql`
  subscription supplyDeleted {
    supplyDeleted {
      message
      entityId
    }
  }
`;
