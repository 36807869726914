import { gql } from '@apollo/client';

export const allIssuesGQL = gql`
  query allIssues {
    issues: allIssues {
      id
      idLabel
      comments
      creationDate
      solved
      device {
        id
        serial
        franchise {
          id
          name
        }
      }
      franchise {
        id
        name
      }
      issueTypes {
        id
        name
      }
    }
  }
`;

export const issuesGQL = gql`
  query issues($pageSize: Float, $pageNumber: Float, $searchTerm: String) {
    issues(pageSize: $pageSize, pageNumber: $pageNumber, searchTerm: $searchTerm) {
      data {
        id
        idLabel
        comments
        creationDate
        solved
        device {
          id
          serial
          franchise {
            id
            name
          }
        }
        franchise {
          id
          name
        }
        issueTypes {
          id
          name
        }
      }
      pageSize
      pageNumber
      totalPages
    }
  }
`;

export const issueGQL = gql`
  query issue($issueId: String!) {
    issue(issueId: $issueId) {
      id
      idLabel
      comments
      creationDate
      solved
      device {
        id
        serial
      }
      franchise {
        id
        name
      }
      issueTypes {
        id
        name
      }
    }
  }
`;

export const createIssueGQL = gql`
  mutation createIssue($issueData: IssueInputType!) {
    createIssue(issueData: $issueData) {
      id
      idLabel
      comments
      creationDate
      solved
      device {
        id
        serial
      }
      franchise {
        id
        name
      }
      issueTypes {
        id
        name
      }
    }
  }
`;

export const updateIssueGQL = gql`
  mutation updateIssue($issueId: String!, $issueData: IssueInputType!) {
    updateIssue(issueId: $issueId, issueData: $issueData) {
      message
      entityId
    }
  }
`;

export const deleteIssueGQL = gql`
  mutation deleteIssue($issueId: String!) {
    deleteIssue(issueId: $issueId) {
      message
      entityId
    }
  }
`;

export const issueCreatedGQL = gql`
  subscription issueCreated {
    issueCreated {
      id
      idLabel
      comments
      creationDate
      solved
      device {
        id
        serial
      }
      franchise {
        id
        name
      }
      issueTypes {
        id
        name
      }
    }
  }
`;

export const issueUpdatedGQL = gql`
  subscription issueUpdated {
    issueUpdated {
      id
      idLabel
      comments
      creationDate
      solved
      device {
        id
        serial
      }
      franchise {
        id
        name
      }
      issueTypes {
        id
        name
      }
    }
  }
`;

export const issueDeletedGQL = gql`
  subscription issueDeleted {
    issueDeleted {
      message
      entityId
    }
  }
`;

export const deviceHasIssuesGQL = gql`
  query deviceHasIssues($deviceId: String!) {
    deviceHasIssues(deviceId: $deviceId)
  }
`;
