import { gql } from '@apollo/client';

export const allUsersGQL = gql`
  query allUsers {
    users: allUsers {
      id
      username
      firstName
      lastName
      email
      phoneNumber
      enabled
      profileImageUrl
    }
  }
`;

export const userQuery = gql`
  query user($userId: Float!) {
    user(userId: $userId) {
      id
      username
      firstName
      lastName
      email
      phoneNumber
      enabled
      profileImageUrl
      roles {
        id
        name
      }
    }
  }
`;

export const usersQuery = gql`
  query users($pageSize: Float, $pageNumber: Float, $searchTerm: String) {
    users(pageSize: $pageSize, pageNumber: $pageNumber, searchTerm: $searchTerm) {
      data {
        id
        username
        firstName
        lastName
        email
        phoneNumber
        enabled
        profileImageUrl
      }
      pageSize
      pageNumber
      totalPages
    }
  }
`;

export const updateUserMutationGQL = gql`
  mutation updateUser($userData: UserInputType!, $userId: Float!) {
    updateUser(userData: $userData, userId: $userId)
  }
`;

export const userUpdatedSubGQL = gql`
  subscription userUpdated {
    userUpdatedSub {
      id
      username
      firstName
      lastName
      lastName
      email
      phoneNumber
      enabled
      profileImageUrl
      roles {
        id
        name
      }
    }
  }
`;

export const franchiseAvailableUsersGQL = gql`
  query franchiseAvailableUsers($franchiseId: String!) {
    users: franchiseAvailableUsers(franchiseId: $franchiseId) {
      id
      username
      firstName
      lastName
      lastName
      email
      phoneNumber
      enabled
      profileImageUrl
    }
  }
`;

export const userCreatedSubGQL = gql`
  subscription userCreated {
    userCreatedSub {
      id
      username
      firstName
      lastName
      lastName
      email
      phoneNumber
      enabled
      profileImageUrl
      roles {
        id
        name
      }
    }
  }
`;

export const userDeletedSubGQL = gql`
  subscription userDeleted {
    userDeletedSub
  }
`;

export const deleteUserMutationGQL = gql`
  mutation deleteUser($userId: Float!) {
    deleteUser(userId: $userId)
  }
`;

export const createUserMutationGQL = gql`
  mutation createUser($userData: UserInputType!) {
    createUser(userData: $userData) {
      id
      username
      firstName
      lastName
      email
      phoneNumber
      enabled
      profileImageUrl
      roles {
        id
        name
      }
    }
  }
`;
