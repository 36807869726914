import React, { FC, useCallback, useState } from 'react';
import './services.scss';
import { DeviceCard } from './device-card';
import { Input, Form, TextArea, Button } from 'semantic-ui-react';
import { Sliders } from 'react-feather';
import { SidePanel } from '../../../components/side-panel';
import { IssueSelectionItem } from './issue-selection-item';

export const RequestServicePage: FC = () => {
  const [showReportForm, setShowReportForm] = useState(false);
  const openReportForm = useCallback(() => {
    setShowReportForm(true);
  }, []);

  const closeReportForm = useCallback(() => {
    setShowReportForm(false);
  }, []);

  return (
    <div className="request-service-page">
      <div>
        <div className="page-title">Solicitud de Servicio</div>
        <div className="table-header">
          <div className="controls">
            <Input icon="search" placeholder="Buscar" />
            <div className="filters-button">
              <Sliders />
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <DeviceCard onReport={openReportForm} />
        <DeviceCard onReport={openReportForm} />
        <DeviceCard onReport={openReportForm} />
        <DeviceCard onReport={openReportForm} />
        <DeviceCard onReport={openReportForm} />
        <DeviceCard onReport={openReportForm} />
      </div>
      <SidePanel visible={showReportForm} onClose={closeReportForm}>
        <div className="page-title">Reportar Falla</div>
        <Input icon="search" placeholder="Buscar" fluid />
        <Form className="panel-form">
          <div className="issues-list">
            <IssueSelectionItem id={1} issueName="No Prende" />
            <IssueSelectionItem id={1} issueName="No Carga Master Key" />
            <IssueSelectionItem id={1} issueName="Terminal Bloqueada" />
            <IssueSelectionItem id={1} issueName="Display Malo" />
            <IssueSelectionItem id={1} issueName="Flash Full" />
            <IssueSelectionItem id={1} issueName="No Comunica Modem" />
          </div>
          <Form.Field>
            <label htmlFor="">Descripción de la falla</label>
            <TextArea rows={5} />
          </Form.Field>
          <Button floated="right" primary>
            Crear Reporte
          </Button>
        </Form>
      </SidePanel>
    </div>
  );
};
