import { gql } from '@apollo/client';

export const supplyRequestsGQL = gql`
  query supplyRequests($pageNumber: Float, $pageSize: Float, $searchTerm: String) {
    supplyRequests(pageNumber: $pageNumber, pageSize: $pageSize, searchTerm: $searchTerm) {
      data {
        id
        idLabel
        creationDate
        delivered
        requestItems {
          id
          ammount
          supply {
            id
            name
          }
        }
        franchise {
          id
          name
        }
      }
      pageSize
      pageNumber
      totalPages
    }
  }
`;

export const allSupplyRequestGQL = gql`
  query allSupplyRequests {
    supplyRequests: allSupplyRequests {
      id
      idLabel
      creationDate
      delivered
      requestItems {
        id
        ammount
        supply {
          id
          name
        }
      }
      franchise {
        id
        name
      }
    }
  }
`;

export const supplyRequestGQL = gql`
  query supplyRequest($supplyRequestId: String!) {
    supplyRequest(supplyRequestId: $supplyRequestId) {
      id
      idLabel
      creationDate
      delivered
      requestItems {
        id
        ammount
        supply {
          id
          name
        }
      }
      franchise {
        id
        name
      }
    }
  }
`;

export const createSupplyRequestGQl = gql`
  mutation createSupplyRequest($supplyRequestData: SupplyRequestInputType!) {
    createSupplyRequest(supplyRequestData: $supplyRequestData) {
      id
      idLabel
      creationDate
      delivered
      requestItems {
        id
        ammount
        supply {
          id
          name
        }
      }
      franchise {
        id
        name
      }
    }
  }
`;

export const updateSupplyRequestGQL = gql`
  mutation updateSupplyRequest(
    $supplyRequestId: String!
    $supplyRequestData: SupplyRequestInputType!
  ) {
    updateSupplyRequest(supplyRequestId: $supplyRequestId, supplyRequestData: $supplyRequestData) {
      message
      entityId
    }
  }
`;

export const deleteSupplyRequestGQL = gql`
  mutation deleteSupplyRequest($supplyRequestId: String!) {
    deleteSupplyRequest(supplyRequestId: $supplyRequestId) {
      message
      entityId
    }
  }
`;

export const supplyRequestCreatedGQL = gql`
  subscription supplyRequestCreated {
    supplyRequestCreated {
      id
      idLabel
      creationDate
      delivered
      requestItems {
        id
        ammount
        supply {
          id
          name
        }
      }
      franchise {
        id
        name
      }
    }
  }
`;

export const supplyRequestUpdatedGQL = gql`
  subscription supplyRequestUpdated {
    supplyRequestUpdated {
      id
      idLabel
      creationDate
      delivered
      requestItems {
        id
        ammount
        supply {
          id
          name
        }
      }
      franchise {
        id
        name
      }
    }
  }
`;
export const supplyRequestDeletedGQL = gql`
  subscription supplyRequestDeleted {
    supplyRequestDeleted {
      message
      entityId
    }
  }
`;
