import React, { FC, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  supplyTypeGQL,
  createSupplyTypeGQL,
  updateSupplyTypeGQL,
  deleteSupplyTypeGQL,
} from 'graphql/supply-type';

import { SupplyTypePanel as Component } from './supply-type-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  supplyTypeId: string;
};

export const SupplyTypePanel: FC<ComponentProps> = ({ supplyTypeId, onClose, visible }) => {
  console.log('supplyTypeId', supplyTypeId);
  const { data } = useQuery<{ supplyType: SupplyType }>(supplyTypeGQL, {
    variables: { supplyTypeId },
    skip: supplyTypeId === null,
  });

  const [createSupplyTypeMutation] = useMutation(createSupplyTypeGQL);
  const [updateSupplyTypeMutation] = useMutation(updateSupplyTypeGQL);
  const [deleteSupplyTypeMutation, { loading }] = useMutation(deleteSupplyTypeGQL);

  const createSupplyType = useCallback(
    async (supplyTypeData: SupplyType) => {
      await createSupplyTypeMutation({ variables: { supplyTypeData } });
    },
    [createSupplyTypeMutation],
  );
  const updateSupplyType = useCallback(
    async (supplyTypeData: SupplyType) => {
      await updateSupplyTypeMutation({ variables: { supplyTypeData, supplyTypeId } });
    },
    [supplyTypeId, updateSupplyTypeMutation],
  );
  const deleteSupplyType = useCallback(async () => {
    await deleteSupplyTypeMutation({ variables: { supplyTypeId } });
  }, [supplyTypeId, deleteSupplyTypeMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      supplyType={data?.supplyType ?? null}
      updateSupplyType={updateSupplyType}
      createSupplyType={createSupplyType}
      deleteSupplyType={deleteSupplyType}
      deletingSupplyType={loading}
    />
  );
};
