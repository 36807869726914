import React, { FC, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { allSupplyTypesGQL } from 'graphql/supply-type';
import { supplyGQL, createSupplyGQL, deleteSupplyGQL, updateSupplyGQL } from 'graphql/supply';
import { SupplyUnitsPanel as Component } from './supply-units-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  supplyId: string;
};

export const SupplyUnitsPanel: FC<ComponentProps> = ({ supplyId, onClose, visible }) => {
  const { data: supplyTypesResponse } = useQuery<{ supplyTypes: SupplyType[] }>(allSupplyTypesGQL);
  const { data: supplyResponse } = useQuery<{ supply: Supply }>(supplyGQL, {
    variables: { supplyId },
    skip: supplyId === null,
  });

  const [createSupplyMutation] = useMutation(createSupplyGQL);
  const [updateSupplyMutation] = useMutation(updateSupplyGQL);
  const [deleteSupplyMutation, { loading }] = useMutation(deleteSupplyGQL);

  const createSupply = useCallback(
    async (supplyData: Supply) => {
      await createSupplyMutation({ variables: { supplyData } });
    },
    [createSupplyMutation],
  );
  const updateSupply = useCallback(
    async (supplyData: Supply) => {
      await updateSupplyMutation({ variables: { supplyId, supplyData } });
    },
    [supplyId, updateSupplyMutation],
  );
  const deleteSupply = useCallback(async () => {
    await deleteSupplyMutation({ variables: { supplyId } });
  }, [supplyId, deleteSupplyMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      supplyUnits={supplyResponse?.supply ?? null}
      updateSupplyUnits={updateSupply}
      createSupplyUnits={createSupply}
      deleteSupplyUnits={deleteSupply}
      deletingSupplyUnits={loading}
      supplyTypes={supplyTypesResponse?.supplyTypes ?? []}
    />
  );
};
