import React, { FC, useCallback, useState } from 'react';
import pageStyles from 'assets/styles/page.module.scss';
import './service-reasons.module.scss';
import { Button, Table } from 'semantic-ui-react';
import { Checkbox } from 'components/checkbox';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import { ServiceReasonPanel } from './service-reason-panel';

export type ComponentProps = {
  serviceReasons: ServiceReason[];
};

export const ServiceReasons: FC<ComponentProps> = ({ serviceReasons }) => {
  const [showServiceReasonPanel, setShowServiceReasonPanel] = useState(false);
  const [selectedServiceReason, setSelectedServiceReason] = useState<string>(null);
  const openServiceReasonDetails = useCallback((reasonId: string) => {
    setSelectedServiceReason(reasonId);
    setShowServiceReasonPanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Razones de Servicio</div>
        <div className={pageStyles.tableHeader}>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedServiceReason(null);
              setShowServiceReasonPanel(true);
            }}
          >
            Crear Razon <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Razon de mantenimiento</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {serviceReasons.map((reason) => (
                <Table.Row key={reason.id}>
                  <Table.Cell>{reason.name}</Table.Cell>
                  <Table.Cell>
                    <Checkbox selected={reason.maintenance} />
                  </Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openServiceReasonDetails(reason.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <ServiceReasonPanel
        serviceReasonId={selectedServiceReason}
        visible={showServiceReasonPanel}
        onClose={() => {
          setSelectedServiceReason(null);
          setShowServiceReasonPanel(false);
        }}
      />
    </div>
  );
};
