import React, { FC } from 'react';
import './issue-selection-item.scss';

interface ComponentProps {
  id: number;
  issueName: string;
}

export const IssueSelectionItem: FC<ComponentProps> = ({ id, issueName }) => {
  return (
    <div className="issue-selection-item">
      <input type="checkbox" value={id} name="issue" />
      <div className="issue-name">{issueName}</div>
    </div>
  );
};
