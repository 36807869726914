import React, { ChangeEvent, FC, useCallback, useMemo, useRef, useState } from 'react';
import { Button, Form, Icon } from 'semantic-ui-react';
import DefaultUserAvatar from 'assets/images/user.jpg';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ValidationMessages } from 'utils/validation-messages';
import styles from '../../users/persons/person-panel/person-panel.module.scss';
import { InputField } from 'components/input-field';
import { SelectorField } from '../../../components/selector-field';

export type ComponentProps = {
  user: User;
  checkEmailAvailability: (email: string) => Promise<EmailAvailability>;
  updateUser: (user: User) => void;
  updatingUser: boolean;
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  phoneNumber: string;
  profileImage: File;
};

export const UserDetailsForm: FC<ComponentProps> = ({
  user,
  checkEmailAvailability,
  updateUser,
  updatingUser,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const fileRef = useRef<HTMLInputElement>();
  const [userAvatarUrl, setUserAvatarUrl] = useState(user?.profileImageUrl ?? DefaultUserAvatar);
  const initialValues = useMemo<FormValues>(
    () => ({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      username: user.username,
      phoneNumber: user.phoneNumber,
      profileImage: null,
    }),
    [user],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape<FormValues>({
        firstName: Yup.string().required(ValidationMessages.required),
        lastName: Yup.string().required(ValidationMessages.required),
        email: Yup.string()
          .required(ValidationMessages.required)
          .email(ValidationMessages.email)
          .test('isAvailable', 'El email no esta disponible', (value) =>
            user === null && value !== undefined
              ? checkEmailAvailability(value).then((result) => result.available)
              : true,
          ),
        username: Yup.string().required(ValidationMessages.required),
        phoneNumber: Yup.string().required(ValidationMessages.required),
        profileImage: null,
      }),
    [checkEmailAvailability, user],
  );

  const handleFormSubmit = useCallback(
    (values: FormValues) => {
      updateUser({ ...values, enabled: true });
    },
    [updateUser],
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit} className="pane-form">
            <div>
              <div className={styles.profileImageSelector}>
                <div className={styles.avatarContainer}>
                  <img src={userAvatarUrl} alt="" className={styles.avatar} />
                </div>
                <Button
                  icon="camera"
                  circular
                  size="small"
                  className={styles.cameraButton}
                  onClick={() => fileRef.current.click()}
                  type="button"
                />
              </div>
              <div hidden>
                <input
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/png, image/jpeg"
                  ref={fileRef}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('profileImage', event.target.files[0]);
                    setUserAvatarUrl(URL.createObjectURL(event.target.files[0]));
                  }}
                />
              </div>
              <FastField
                name="firstName"
                label="Nombre"
                component={InputField}
                value={values.firstName}
                placeholder="Nombre"
              />
              <FastField
                name="lastName"
                label="Apellido"
                component={InputField}
                value={values.lastName}
                placeholder="Apellido"
              />
              <FastField
                name="email"
                label="Email"
                component={InputField}
                value={values.email}
                placeholder="Correo Electronico"
              />
              <FastField
                name="username"
                label="Username"
                component={InputField}
                value={values.username}
                placeholder="Username"
              />
              <FastField
                name="phoneNumber"
                label="Número de Telefono"
                component={InputField}
                value={values.phoneNumber}
                placeholder="Número de telefono"
              />
            </div>
            <div>
              <Button primary size="medium" icon loading={updatingUser}>
                Guardar &nbsp;
                <Icon name="save" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
