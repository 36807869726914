import React, { FC, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { allDeviceTypesGQL } from 'graphql/device-type';
import {
  deviceReferenceGQL,
  createDeviceReferenceGQL,
  updateDeviceReferenceGQL,
  deleteDeviceReferenceGQL,
} from 'graphql/device-reference';

import { DeviceReferencePanel as Component } from './device-reference-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  deviceReferenceId: string;
};

export const DeviceReferencePanel: FC<ComponentProps> = ({
  deviceReferenceId,
  onClose,
  visible,
}) => {
  const { data: deviceTypesResponse } = useQuery<{ deviceTypes: DeviceType[] }>(allDeviceTypesGQL);
  const { data: deviceReferenceResponse } = useQuery<{ deviceReference: DeviceReference }>(
    deviceReferenceGQL,
    {
      variables: { deviceReferenceId },
      skip: deviceReferenceId === null,
    },
  );

  const [createDeviceReferenceMutation] = useMutation(createDeviceReferenceGQL);
  const [updateDeviceReferenceMutation] = useMutation(updateDeviceReferenceGQL);
  const [deleteDeviceReferenceMutation, { loading }] = useMutation(deleteDeviceReferenceGQL);

  const createDeviceReference = useCallback(
    async (deviceReferenceData: DeviceReference) => {
      await createDeviceReferenceMutation({ variables: { deviceReferenceData } });
    },
    [createDeviceReferenceMutation],
  );
  const updateDeviceReference = useCallback(
    async (deviceReferenceData: DeviceReference) => {
      await updateDeviceReferenceMutation({
        variables: { deviceReferenceId, deviceReferenceData },
      });
    },
    [deviceReferenceId, updateDeviceReferenceMutation],
  );
  const deleteDeviceReference = useCallback(async () => {
    await deleteDeviceReferenceMutation({ variables: { deviceReferenceId } });
  }, [deviceReferenceId, deleteDeviceReferenceMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      deviceReference={deviceReferenceResponse?.deviceReference ?? null}
      updateDeviceReference={updateDeviceReference}
      createDeviceReference={createDeviceReference}
      deleteDeviceReference={deleteDeviceReference}
      deletingDeviceReference={loading}
      deviceTypes={deviceTypesResponse?.deviceTypes ?? []}
    />
  );
};
