import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { TextAreaField } from 'components/text-area-field';
import { SelectorField } from 'components/selector-field';
import { Checkbox } from 'components/checkbox';
import { Formik, FormikProps, FastField, FormikHelpers, FieldArray } from 'formik';
import { Button, Form, Icon, Confirm, DropdownItemProps } from 'semantic-ui-react';
import * as Yup from 'yup';
import { ValidationMessages } from '../../../../utils/validation-messages';
import DeleteForeverRounded from '@material-ui/icons/HighlightOffOutlined';
import styles from './supply-rp.module.scss';

interface ComponentProps {
  visible: boolean;
  onClose: () => void;
  supplyRequest: SupplyRequest;
  updateSupplyRequest: (SupplyRequest: SupplyRequest) => Promise<void>;
  createSupplyRequest: (SupplyRequest: SupplyRequest) => Promise<void>;
  deleteSupplyRequest: () => Promise<void>;
  deletingSupplyRequest: boolean;
  supplies: Supply[];
  franchises: Franchise[];
}

interface FormValues {
  requestItems: { id?: string; supplyId: string; ammount: number }[];
  franchiseId: string;
  delivered: boolean;
}

const initialValues: FormValues = {
  requestItems: [{ supplyId: null, ammount: null }],
  franchiseId: null,
  delivered: false,
};

const validationSchema = Yup.object().shape<FormValues>({
  delivered: Yup.boolean(),
  franchiseId: Yup.string().nullable().required(ValidationMessages.required),
  requestItems: Yup.array()
    .of(
      Yup.object().shape<{ supplyId: string; ammount: number }>({
        supplyId: Yup.string().nullable().required(ValidationMessages.required),
        ammount: Yup.number()
          .nullable()
          .required(ValidationMessages.required)
          .moreThan(0, 'Debe ser mayor a 0'),
      }),
    )
    .min(1, 'asd'),
});

export const SupplyRequestPanel: FC<ComponentProps> = ({
  visible,
  onClose,
  supplyRequest,
  updateSupplyRequest,
  createSupplyRequest,
  deleteSupplyRequest,
  deletingSupplyRequest,
  supplies,
  franchises,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      for (const item of values.requestItems) {
        item.ammount = parseFloat(item.ammount as any);
      }
      helpers.setSubmitting(true);
      if (supplyRequest) updateSupplyRequest(values).then(onClose);
      else createSupplyRequest(values).then(onClose);
    },
    [supplyRequest, updateSupplyRequest, createSupplyRequest, onClose],
  );

  const supplyOptions = useMemo<DropdownItemProps[]>(() => {
    return supplies.map((supply) => ({
      key: supply.id,
      text: supply.name,
      value: supply.id,
    }));
  }, [supplies]);

  const franchiseOptions = useMemo<DropdownItemProps[]>(() => {
    return franchises.map((franchise) => ({
      key: franchise.id,
      text: franchise.name,
      value: franchise.id,
    }));
  }, [franchises]);

  useEffect(() => {
    if (supplyRequest) {
      formRef.current.setValues({
        requestItems: supplyRequest.requestItems.map(({ id, supply, ammount }) => ({
          supplyId: supply?.id,
          ammount,
        })),
        delivered: supplyRequest.delivered,
        franchiseId: supplyRequest.franchise?.id,
      });
    }
  }, [supplyRequest]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">
        {supplyRequest ? `SR${supplyRequest.id.padStart(6, '0')}` : 'Crear solicitud'}
      </div>
      {supplyRequest && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingSupplyRequest}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar el tipo de novedad seleccionado?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteSupplyRequest().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, isSubmitting, setFieldValue }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <FastField
                name="franchiseId"
                label="Franquicia"
                placeholder="Seleccione Franquicia"
                options={franchiseOptions}
                component={SelectorField}
                value={values.franchiseId}
              />
              <Form.Field>
                <FieldArray
                  name="requestItems"
                  render={({ push, remove }) => (
                    <div>
                      <div className={styles.itemsTitle}>
                        <label>Selección de Insumos</label>
                        <Button
                          type="button"
                          primary
                          size="mini"
                          icon
                          loading={isSubmitting}
                          onClick={() => push({ supplyId: null, ammount: null })}
                        >
                          Agregar &nbsp;
                          <Icon name="plus" />
                        </Button>
                      </div>
                      {values.requestItems.map((value, index) => (
                        <div key={`I${index}`} className={styles.requestItem}>
                          <FastField
                            label="Insumo"
                            name={`requestItems.${index}.supplyId`}
                            placeholder="Seleccione un Insumo"
                            options={supplyOptions}
                            component={SelectorField}
                            value={value.supplyId}
                          />
                          <FastField
                            label="Candidad"
                            name={`requestItems.${index}.ammount`}
                            placeholder="Cantidad"
                            component={InputField}
                            value={value.ammount}
                          />
                          {values.requestItems.length > 1 && (
                            <div
                              className={styles.deleteIcon}
                              onClick={() => remove(index)}
                              aria-hidden
                            >
                              <DeleteForeverRounded />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                />
              </Form.Field>
              <div className={`field ${styles.itemsTitle}`}>
                <label>Entregado</label>
                <Checkbox
                  selected={values.delivered}
                  onSelect={() => setFieldValue('delivered', true)}
                  onDeselect={() => setFieldValue('delivered', false)}
                />
              </div>
              <div className="buttons-container">
                <Button size="medium" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="medium" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
