import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { ValidationMessages } from 'utils/validation-messages';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, Form, Icon } from 'semantic-ui-react';
import { InputField } from 'components/input-field';
import { TextAreaField } from 'components/text-area-field';
import { SidePanel } from 'components/side-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  demoType: DemoType;
  updateDemoType: (demoType: DemoType) => Promise<void>;
  createDemoType: (demoType: DemoType) => Promise<void>;
  deleteDemoType: () => Promise<void>;
  deletingDemoType: boolean;
};

interface FormValues {
  name: string;
  description: string;
}

const initialValues: FormValues = {
  name: '',
  description: '',
};

const validationSchema = Yup.object().shape<FormValues>({
  name: Yup.string().required(ValidationMessages.required),
  description: Yup.string().required(ValidationMessages.required),
});

export const DemoTypePanel: FC<ComponentProps> = ({
  demoType,
  createDemoType,
  deleteDemoType,
  deletingDemoType,
  updateDemoType,
  onClose,
  visible,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      if (demoType) updateDemoType(values).then(onClose);
      else createDemoType(values).then(onClose);
    },
    [updateDemoType, createDemoType, onClose, demoType],
  );

  useEffect(() => {
    if (demoType) {
      formRef.current.setValues({
        name: demoType.name,
        description: demoType.description,
      });
    }
  }, [demoType]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">{demoType?.name ?? 'Crear Tipo de Demo'}</div>
      {demoType && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingDemoType}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar el permiso seleccionado?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteDemoType().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, isSubmitting }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <FastField
                name="name"
                label="Nombre"
                component={InputField}
                value={values.name}
                placeholder="Titulo"
              />
              <FastField
                name="description"
                label="Descripción"
                component={TextAreaField}
                value={values.description}
                placeholder="Descripción"
              />
              <div className="buttons-container">
                <Button size="medium" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="medium" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
