import { gql } from '@apollo/client';

export const pendingChatsGQL = gql`
  query pendingChats {
    pendingChats {
      id
      user {
        id
        username
        firstName
        lastName
        profileImageUrl
        franchise {
          id
          name
          franchiseType {
            id
            name
          }
        }
      }
      createdAt
      enabled
      finished
    }
  }
`;

export const chatCreatedGQL = gql`
  subscription chatCreated {
    chatCreated {
      id
      user {
        id
        username
        profileImageUrl
        franchise {
          id
          name
        }
      }
      createdAt
      enabled
      finished
      chatMessages {
        creationDate
        id
        message
        user {
          id
        }
      }
    }
  }
`;

export const serviceUserActiveChatGQL = gql`
  query serviceUserActiveChat($serviceUserId: String!) {
    chat: serviceUserActiveChat(serviceUserId: $serviceUserId) {
      id
      user {
        id
        username
        firstName
        lastName
        profileImageUrl
        franchise {
          id
          name
          franchiseType {
            id
            name
          }
        }
      }
      serviceUser {
        id
        username
        firstName
        lastName
        profileImageUrl
      }
      createdAt
      enabled
      finished
      chatMessages {
        creationDate
        id
        message
        user {
          id
          profileImageUrl
          firstName
          lastName
        }
      }
    }
  }
`;

export const initChatGQl = gql`
  mutation initChat($serviceUserId: String!, $chatId: String!) {
    initChat(serviceUserId: $serviceUserId, chatId: $chatId) {
      message
      entityId
    }
  }
`;

export const finishChatGQl = gql`
  mutation finishChat($chatId: String!) {
    finishChat(chatId: $chatId) {
      message
      entityId
    }
  }
`;

export const createChatMessageGQL = gql`
  mutation createChatMessage($chatMessageData: ChatMessageInputType!) {
    createChatMessage(chatMessageData: $chatMessageData) {
      id
      user {
        id
        profileImageUrl
        firstName
        lastName
      }
      message
      creationDate
    }
  }
`;

export const chatMessagesGQl = gql`
  query chatMessages($chatId: String!) {
    chatMessages(chatId: $chatId) {
      id
      user {
        id
        profileImageUrl
        firstName
        lastName
      }
      message
      creationDate
    }
  }
`;

export const chatMessagesSubGQL = gql`
  subscription chatMessagesSub($chatId: String!) {
    chatMessagesSub(chatId: $chatId) {
      id
      user {
        id
        profileImageUrl
        firstName
        lastName
      }
      message
      creationDate
    }
  }
`;

export const chatEnabledGQL = gql`
  subscription chatEnabled($userId: String!) {
    chatEnabled(userId: $userId) {
      id
      user {
        id
        username
        firstName
        lastName
        profileImageUrl
        franchise {
          id
          name
          franchiseType {
            id
            name
          }
        }
      }
      serviceUser {
        id
        username
        firstName
        lastName
        profileImageUrl
      }
      createdAt
      enabled
      finished
    }
  }
`;

export const chatFinishedGQL = gql`
  subscription chatFinished($userId: String!) {
    chatFinished(userId: $userId) {
      id
      user {
        id
        username
        firstName
        lastName
        profileImageUrl
        franchise {
          id
          name
          franchiseType {
            id
            name
          }
        }
      }
      serviceUser {
        id
        username
        firstName
        lastName
        profileImageUrl
      }
      createdAt
      enabled
      finished
    }
  }
`;
