import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { SelectorField } from 'components/selector-field';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, DropdownItemProps, Form, Icon } from 'semantic-ui-react';
import DefaultUserAvatar from 'assets/images/user.jpg';
import styles from './device-reference-panel.module.scss';

interface ComponentProps {
  visible: boolean;
  onClose: () => void;
  deviceReference: DeviceReference;
  updateDeviceReference: (deviceReference: DeviceReference) => Promise<void>;
  createDeviceReference: (deviceReference: DeviceReference) => Promise<void>;
  deleteDeviceReference: () => Promise<void>;
  deletingDeviceReference: boolean;
  deviceTypes: DeviceType[];
}

interface FormValues {
  name: string;
  imageFile: File;
  deviceTypeId: number;
}

const initialValues: FormValues = {
  name: '',
  imageFile: null,
  deviceTypeId: null,
};

export const DeviceReferencePanel: FC<ComponentProps> = ({
  visible,
  onClose,
  deviceReference,
  updateDeviceReference,
  createDeviceReference,
  deleteDeviceReference,
  deletingDeviceReference,
  deviceTypes,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const fileRef = useRef<HTMLInputElement>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);
  const [userAvatarUrl, setUserAvatarUrl] = useState(
    deviceReference?.imageUrl ?? DefaultUserAvatar,
  );

  const deviceTypeOptions = useMemo<DropdownItemProps[]>(() => {
    return deviceTypes.map((dType) => ({
      key: dType.id,
      text: dType.name,
      value: dType.id,
    }));
  }, [deviceTypes]);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      const submit = async () => {
        if (deviceReference) updateDeviceReference(values).then(onClose);
        else createDeviceReference(values).then(onClose);
      };

      submit();
    },
    [deviceReference, updateDeviceReference, createDeviceReference, onClose],
  );

  useEffect(() => {
    if (deviceReference) {
      formRef.current.setFieldValue('name', deviceReference.name);
      formRef.current.setFieldValue('deviceTypeId', deviceReference.deviceType?.id);
      setUserAvatarUrl(deviceReference.imageUrl ?? DefaultUserAvatar);
    } else {
      setUserAvatarUrl(DefaultUserAvatar);
    }
  }, [deviceReference]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">{deviceReference?.name ?? 'Crear Referencia'}</div>
      {deviceReference && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingDeviceReference}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar el suministro seleccionado?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteDeviceReference().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit} innerRef={formRef}>
        {({ handleSubmit, values, isSubmitting, setFieldValue }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <div className={styles.profileImageSelector}>
                <div className={styles.avatarContainer}>
                  <img src={userAvatarUrl} alt="" className={styles.avatar} />
                </div>
                <Button
                  icon="camera"
                  circular
                  size="small"
                  className={styles.cameraButton}
                  onClick={() => fileRef.current.click()}
                  type="button"
                />
              </div>
              <div hidden>
                <input
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/png, image/jpeg"
                  ref={fileRef}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('imageFile', event.target.files[0]);
                    setUserAvatarUrl(URL.createObjectURL(event.target.files[0]));
                  }}
                />
              </div>
              <FastField
                name="name"
                label="Nombre"
                component={InputField}
                value={values.name}
                placeholder="Nombre de la referencia"
              />
              <FastField
                name="deviceTypeId"
                label="Tipo"
                component={SelectorField}
                value={values.deviceTypeId}
                placeholder="Seleccione un tipo"
                options={deviceTypeOptions}
              />
              <div className="buttons-container">
                <Button size="medium" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="medium" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
