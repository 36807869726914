import React, { FC, useEffect, useState } from 'react';
import { Modal, Button, Checkbox, Icon } from 'semantic-ui-react';
import styles from './modal-selector.module.scss';

export type SelectorItem = {
  label: string;
  value: any;
  selected?: boolean;
};

export type ComponentProps = {
  trigger: React.ReactElement;
  title: string;
  items?: SelectorItem[];
  onSave: (selectedValues: any[]) => void;
};

export const ModalSelector: FC<ComponentProps> = ({ trigger, title, items, onSave }) => {
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  useEffect(() => {
    setSelectedItems(
      items.reduce((acc, item) => {
        if (item.selected) acc.push(item.value);
        return acc;
      }, []),
    );
  }, [items]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      className={`${styles.modalSelectorContainer} modal-selector-container`}
      size="small"
    >
      <Modal.Header className={styles.modalTitle}>{title}</Modal.Header>
      <Modal.Content scrolling>
        {items.map((item) => (
          <div className={styles.selectorItem} key={item.value}>
            <div className={styles.labelSection}>{item.label}</div>
            <div className={styles.toggleSection}>
              <Checkbox
                toggle
                checked={selectedItems.includes(item.value)}
                onChange={(event, data) => {
                  if (data.checked) {
                    setSelectedItems([...selectedItems, item.value]);
                  } else {
                    setSelectedItems(selectedItems.filter((id) => id !== item.value));
                  }
                }}
              />
            </div>
          </div>
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Button size="small" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
        <Button
          size="small"
          onClick={() => {
            onSave(selectedItems);
            setOpen(false);
          }}
          icon
          primary
        >
          Guardar
          <Icon name="save" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalSelector.defaultProps = {
  items: [],
};
