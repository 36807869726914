import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  rolesQueryGQL,
  roleUpdatedSubGQL,
  roleCreatedSubGQL,
  roleDeletedSubGQL,
} from 'graphql/role';
import { usePagination } from 'utils/hooks/pagination';

import { RolesPage as Component } from './roles.page';

export const RolesPage: FC = () => {
  const { data, loading, refetch } = useQuery<
    { roles: PaginatedEntityList<Role> },
    PaginatedVariables
  >(rolesQueryGQL, { variables: { pageSize: parseFloat(process.env.REACT_APP_TABLE_PAGE_SIZE) } });
  const { search, changePage } = usePagination(refetch);

  useSubscription(roleUpdatedSubGQL);
  useSubscription(roleCreatedSubGQL, { onSubscriptionData: () => refetch() });
  useSubscription(roleDeletedSubGQL, { onSubscriptionData: () => refetch() });

  return (
    <Component
      roles={data?.roles.data || []}
      pagination={
        loading ? null : { pageNumber: data?.roles.pageNumber, totalPages: data?.roles.totalPages }
      }
      onSearch={search}
      onPageChange={changePage}
    />
  );
};
