import React, { FC, useCallback, useState } from 'react';
import pageStyles from 'assets/styles/page.module.scss';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import './supply-types.scss';
import { Button, Table } from 'semantic-ui-react';
import { SupplyTypePanel } from './supply-type-panel';

export type ComponentProps = {
  supplyTypes: SupplyType[];
};

export const SupplyTypesPage: FC<ComponentProps> = ({ supplyTypes }) => {
  const [showSupplyTypePanel, setShowSupplyTypePanel] = useState(false);
  const [selectedSupplyType, setSelectedSupplyType] = useState<string>(null);
  const openSupplyTypeDetails = useCallback((supplyTypeId: string) => {
    setSelectedSupplyType(supplyTypeId);
    setShowSupplyTypePanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Tipos de Suministro</div>
        <div className={pageStyles.tableHeader}>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedSupplyType(null);
              setShowSupplyTypePanel(true);
            }}
          >
            Crear Tipo <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {supplyTypes.map((supplytype) => (
                <Table.Row key={supplytype.id}>
                  <Table.Cell>{supplytype.name}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openSupplyTypeDetails(supplytype.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <SupplyTypePanel
        supplyTypeId={selectedSupplyType}
        visible={showSupplyTypePanel}
        onClose={() => {
          setSelectedSupplyType(null);
          setShowSupplyTypePanel(false);
        }}
      />
    </div>
  );
};
