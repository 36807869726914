import React, { FC, useCallback, useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import pageStyles from 'assets/styles/page.module.scss';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import { DemoTutorialPanel } from './demo-tutorial-panel';

export type ComponentProps = {
  demos: DemoTutorial[];
};

export const DemoTutorials: FC<ComponentProps> = ({ demos }) => {
  const [showDemoPanel, setShowDemoPanel] = useState(false);
  const [selectedDemo, setSelectedDemo] = useState<string>(null);

  const openeDemoPanel = useCallback((demoId: string) => {
    setSelectedDemo(demoId);
    setShowDemoPanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Demo Tutoriales</div>
        <div className={pageStyles.tableHeader}>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedDemo(null);
              setShowDemoPanel(true);
            }}
          >
            Crear Tutorial <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Número</Table.HeaderCell>
                <Table.HeaderCell>Tipo</Table.HeaderCell>
                <Table.HeaderCell>Referencia de Dispositivo</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {demos.map((demo) => (
                <Table.Row key={demo.id}>
                  <Table.Cell className={pageStyles.boldText}>{`D${demo.id.padStart(
                    6,
                    '0',
                  )}`}</Table.Cell>
                  <Table.Cell className={pageStyles.boldText}>{demo.demoType?.name}</Table.Cell>
                  <Table.Cell className={pageStyles.boldText}>
                    {demo.deviceReference?.name}
                  </Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openeDemoPanel(demo.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <DemoTutorialPanel
        visible={showDemoPanel}
        onClose={() => {
          setSelectedDemo(null);
          setShowDemoPanel(false);
        }}
        demoId={selectedDemo}
      />
    </div>
  );
};
