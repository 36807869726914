import { gql } from '@apollo/client';

export const allServiceNoveltiesGQL = gql`
  query allServiceNovelties {
    serviceNovelties: allServiceNovelties {
      id
      name
      description
    }
  }
`;

export const serviceNoveltyGQL = gql`
  query serviceNovelty($serviceNoveltyId: String!) {
    serviceNovelty(serviceNoveltyId: $serviceNoveltyId) {
      id
      name
      description
    }
  }
`;

export const createServiceNoveltyGQL = gql`
  mutation createServiceNovelty($serviceNoveltyData: ServiceNoveltyInputType!) {
    createServiceNovelty(serviceNoveltyData: $serviceNoveltyData) {
      id
      name
      description
    }
  }
`;

export const updateServiceNoveltyGQL = gql`
  mutation updateServiceNovelty(
    $serviceNoveltyId: String!
    $serviceNoveltyData: ServiceNoveltyInputType!
  ) {
    updateServiceNovelty(
      serviceNoveltyId: $serviceNoveltyId
      serviceNoveltyData: $serviceNoveltyData
    ) {
      message
      entityId
    }
  }
`;

export const deleteServiceNoveltyGQL = gql`
  mutation deleteServiceNovelty($serviceNoveltyId: String!) {
    deleteServiceNovelty(serviceNoveltyId: $serviceNoveltyId) {
      message
      entityId
    }
  }
`;

export const serviceNoveltyCreatedGQL = gql`
  subscription serviceNoveltyCreated {
    serviceNoveltyCreated {
      id
      name
      description
    }
  }
`;

export const serviceNoveltyUpdatedGQL = gql`
  subscription serviceNoveltyUpdated {
    serviceNoveltyUpdated {
      id
      name
      description
    }
  }
`;
export const serviceNoveltyDeletedGQL = gql`
  subscription serviceNoveltyDeleted {
    serviceNoveltyDeleted {
      message
      entityId
    }
  }
`;
