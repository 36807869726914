import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { SelectorField } from 'components/selector-field';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, DropdownItemProps, Form, Icon } from 'semantic-ui-react';
import { ValidationMessages } from 'utils/validation-messages';
import * as Yup from 'yup';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  device: Device;
  updateDevice: (device: Device) => Promise<void>;
  createDevice: (device: Device) => Promise<void>;
  deleteDevice: () => Promise<void>;
  deletingDevice: boolean;
  deviceReferences: DeviceReference[];
  franchises: Franchise[];
};

type FormValues = {
  pinPadCode: string;
  plateNumber: string;
  securityCode: string;
  serial: string;
  deviceReferenceId: number;
  franchiseId: number;
};

const initialValues: FormValues = {
  pinPadCode: '',
  plateNumber: '',
  securityCode: '',
  serial: '',
  deviceReferenceId: null,
  franchiseId: null,
};

const validatinSchema = Yup.object().shape<FormValues>({
  pinPadCode: Yup.string().required(ValidationMessages.required),
  plateNumber: Yup.string().required(ValidationMessages.required),
  securityCode: Yup.string().required(ValidationMessages.required),
  serial: Yup.string().required(ValidationMessages.required),
  deviceReferenceId: Yup.number().nullable(),
  franchiseId: Yup.number().nullable(),
});

export const DevicePanel: FC<ComponentProps> = ({
  visible,
  onClose,
  device,
  updateDevice,
  createDevice,
  deleteDevice,
  deletingDevice,
  deviceReferences,
  franchises,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);

  const deviceReferenceOptions = useMemo<DropdownItemProps[]>(() => {
    return deviceReferences.map((reference) => ({
      key: reference.id,
      text: reference.name,
      value: reference.id,
    }));
  }, [deviceReferences]);

  const franchiseOptions = useMemo<DropdownItemProps[]>(() => {
    return franchises.map((franchise) => ({
      key: franchise.id,
      text: franchise.name,
      value: franchise.id,
    }));
  }, [franchises]);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      if (device) updateDevice(values).then(onClose);
      else createDevice(values).then(onClose);
    },
    [device, updateDevice, createDevice, onClose],
  );

  useEffect(() => {
    if (device) {
      formRef.current.setFieldValue('pinPadCode', device.pinPadCode);
      formRef.current.setFieldValue('plateNumber', device.plateNumber);
      formRef.current.setFieldValue('securityCode', device.securityCode);
      formRef.current.setFieldValue('serial', device.serial);
      formRef.current.setFieldValue('deviceReferenceId', device.deviceReference?.id);
      formRef.current.setFieldValue('franchiseId', device.franchise?.id);
    }
  }, [device]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">{device?.serial ?? 'Crear Dispositivo'}</div>
      {device && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingDevice}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar el dispositivo seleccionado?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteDevice().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        validationSchema={validatinSchema}
      >
        {({ handleSubmit, values, isSubmitting }: FormikProps<FormValues>) => (
          <Form onSubmit={handleSubmit} className="panel-form">
            <FastField
              name="pinPadCode"
              label="Código PinPad"
              component={InputField}
              value={values.pinPadCode}
              placeholder="Código PinPad"
            />
            <FastField
              name="plateNumber"
              label="Número de placa"
              component={InputField}
              value={values.plateNumber}
              placeholder="Número de placa"
            />
            <FastField
              name="securityCode"
              label="Código de seguridad"
              component={InputField}
              value={values.securityCode}
              placeholder="Código de seguridad"
            />
            <FastField
              name="serial"
              label="Serial"
              component={InputField}
              value={values.serial}
              placeholder="Serial"
            />
            <FastField
              name="deviceReferenceId"
              label="Referencia"
              component={SelectorField}
              value={values.deviceReferenceId}
              placeholder="Seleccione una Referencia"
              options={deviceReferenceOptions}
            />
            <FastField
              name="franchiseId"
              label="Sede"
              component={SelectorField}
              value={values.franchiseId}
              placeholder="Seleccione una Sede"
              options={franchiseOptions}
            />
            <div className="buttons-container">
              <Button size="medium" icon onClick={onClose} type="button">
                Cancelar &nbsp;
                <Icon name="times" />
              </Button>
              <Button primary size="medium" icon loading={isSubmitting}>
                Guardar &nbsp;
                <Icon name="save" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </SidePanel>
  );
};
