import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { ValidationMessages } from 'utils/validation-messages';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, DropdownItemProps, Form, Icon } from 'semantic-ui-react';
import { SidePanel } from 'components/side-panel';
import VideoImage from 'assets/images/video.png';
import { SelectorField } from 'components/selector-field';
import styles from './demo-tutorial.module.scss';
import { Checkbox } from '../../../../components/checkbox';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  demo: DemoTutorial;
  updateDemo: (demo: DemoTutorial) => Promise<void>;
  createDemo: (demo: DemoTutorial) => Promise<void>;
  deleteDemo: () => Promise<void>;
  deletingDemo: boolean;
  demoTypes: DemoType[];
  deviceReferences: DeviceReference[];
};

type FormValues = {
  demoTypeId: string;
  deviceReferenceId: string;
  videoFile: File;
};

const initialValues: FormValues = {
  demoTypeId: null,
  deviceReferenceId: null,
  videoFile: null,
};

export const DemoTutorialPanel: FC<ComponentProps> = ({
  demo,
  demoTypes,
  deleteDemo,
  deletingDemo,
  createDemo,
  updateDemo,
  deviceReferences,
  visible,
  onClose,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const fileRef = useRef<HTMLInputElement>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);
  const [videoFileUrl, setVideoFileUrl] = useState(demo?.videoFileUrl ?? null);

  const validationSchema = useMemo(() => {
    return Yup.object().shape<FormValues>({
      demoTypeId: Yup.string().nullable().required(ValidationMessages.required),
      deviceReferenceId: Yup.string().nullable().required(ValidationMessages.required),
      videoFile: demo ? Yup.mixed<File>() : Yup.mixed<File>().required(ValidationMessages.required),
    });
  }, [demo]);

  const demoTypeOptions = useMemo<DropdownItemProps[]>(
    () =>
      demoTypes.map((demoType) => ({
        key: demoType.id,
        text: demoType.name,
        value: demoType.id,
      })),
    [demoTypes],
  );

  const deviceReferenceOptions = useMemo<DropdownItemProps[]>(
    () =>
      deviceReferences.map((reference) => ({
        key: reference.id,
        text: reference.name,
        value: reference.id,
      })),
    [deviceReferences],
  );

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      console.log(values);
      helpers.setSubmitting(true);
      if (demo) {
        updateDemo(values).then(onClose);
      } else {
        createDemo(values).then(onClose);
      }
    },
    [demo, createDemo, updateDemo, onClose],
  );

  useEffect(() => {
    if (demo) {
      formRef.current.setValues({
        videoFile: null,
        demoTypeId: demo.demoType?.id,
        deviceReferenceId: demo.deviceReference?.id,
      });
      setVideoFileUrl(demo.videoFileUrl);
    } else {
      setVideoFileUrl(null);
    }
  }, [demo]);

  return (
    <SidePanel onClose={onClose} visible={visible}>
      <div className="panel-title">{demo ? `D${demo.id.padStart(6, '0')}` : 'Crear Tutorial'}</div>
      {demo && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingDemo}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar el demo seleccionado?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteDemo().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          values,
          isSubmitting,
          setFieldValue,
          errors,
          touched,
        }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <div>
                <div className={`field ${styles.inlineLabel}`}>
                  <label>Archivo de Video</label>
                  <Button
                    icon
                    size="tiny"
                    primary
                    onClick={() => fileRef.current.click()}
                    type="button"
                  >
                    Seleccionar
                    <Icon name="attach" />
                  </Button>
                </div>
                {errors.videoFile && touched.videoFile && (
                  <div className={styles.errorMessage}>{errors.videoFile}</div>
                )}
                {videoFileUrl && <video src={videoFileUrl} playsInline width={'100%'} controls />}
                <div hidden>
                  <input
                    type="file"
                    id="avatar"
                    name="avatar"
                    accept="video/mp4,video/x-m4v,video/*"
                    ref={fileRef}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('videoFile', event.target.files[0]);
                      setVideoFileUrl(URL.createObjectURL(event.target.files[0]));
                    }}
                  />
                </div>
              </div>
              <FastField
                name="demoTypeId"
                label="Tipo de Demo"
                component={SelectorField}
                options={demoTypeOptions}
                placeholder="Seleccione un Tipo"
                value={values.demoTypeId}
              />
              <FastField
                name="deviceReferenceId"
                label="Referencia"
                component={SelectorField}
                options={deviceReferenceOptions}
                placeholder="Seleccione una Referencia"
                value={values.deviceReferenceId}
              />
              <div className="buttons-container">
                <Button size="medium" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="medium" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
