import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './users.page.scss';
import { Persons } from './persons';
import { RolesPage } from './roles';
import { PermissionsPage } from './permissions';

export const UsersPage: FC = () => {
  return (
    <div className="users-page">
      <div className="users-content-section">
        <Switch>
          <Route path="/users" exact>
            <Redirect to="/users/persons" />
          </Route>
          <Route path="/users/persons">
            <Persons />
          </Route>
          <Route path="/users/persons">
            <Persons />
          </Route>
          <Route path="/users/roles">
            <RolesPage />
          </Route>
          <Route path="/users/permissions">
            <PermissionsPage />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
