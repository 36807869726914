import React, { FC, useCallback } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { createChatMessageGQL, chatMessagesGQl, chatMessagesSubGQL } from 'graphql/chat';
import { ActiveChat as Component } from './active-chat';

type ComponentProps = {
  chat: Chat;
  finishChat: (chatId: string) => void;
};

export const ActiveChat: FC<ComponentProps> = ({ chat, finishChat }) => {
  const { data, refetch } = useQuery<{ chatMessages: ChatMessage[] }>(chatMessagesGQl, {
    variables: { chatId: chat.id },
  });

  const [createChatMessageMutation] = useMutation(createChatMessageGQL);
  const createChatMessage = useCallback(
    async (message: string) => {
      await createChatMessageMutation({
        variables: { chatMessageData: { message, userId: chat.serviceUser.id, chatId: chat.id } },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: chatMessagesGQl, variables: { chatId: chat.id } }],
      });
    },
    [chat, createChatMessageMutation],
  );

  useSubscription(chatMessagesSubGQL, {
    variables: { chatId: chat.id },
    onSubscriptionData: () => refetch(),
  });

  return (
    <Component
      chat={chat}
      createChatMessage={createChatMessage}
      chatMessages={data?.chatMessages ?? []}
      finishChat={finishChat}
    />
  );
};
