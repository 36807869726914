import { gql } from '@apollo/client';

export const franchisesGQL = gql`
  query franchises($pageSize: Float, $pageNumber: Float, $searchTerm: String) {
    franchises(pageSize: $pageSize, pageNumber: $pageNumber, searchTerm: $searchTerm) {
      data {
        id
        address
        customer {
          id
          name
        }
        name
        phoneNumber
        terminalNumber
        uniqueCode
        franchiseType {
          id
          name
        }
      }
      pageSize
      pageNumber
      totalPages
    }
  }
`;

export const allFranchisesGQL = gql`
  query allFranchises {
    franchises: allFranchises {
      id
      address
      name
      phoneNumber
      customer {
        id
        name
      }
      terminalNumber
      uniqueCode
      franchiseType {
        id
        name
      }
    }
  }
`;

export const franchiseGQL = gql`
  query franchise($franchiseId: String!) {
    franchise(franchiseId: $franchiseId) {
      id
      address
      customer {
        id
        name
      }
      name
      phoneNumber
      terminalNumber
      uniqueCode
      franchiseType {
        id
        name
      }
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export const createFranchiseGQL = gql`
  mutation createFranchise($franchiseData: FranchiseInputType!) {
    createFranchise(franchiseData: $franchiseData) {
      id
      address
      customer {
        id
        name
      }
      name
      phoneNumber
      terminalNumber
      uniqueCode
      franchiseType {
        id
        name
      }
    }
  }
`;

export const updateFranchiseGQL = gql`
  mutation updateFranchise($franchiseData: FranchiseInputType!, $franchiseId: String!) {
    updateFranchise(franchiseData: $franchiseData, franchiseId: $franchiseId) {
      message
      entityId
    }
  }
`;

export const deleteFranchiseGQL = gql`
  mutation deleteFranchise($franchiseId: String!) {
    deleteFranchise(franchiseId: $franchiseId) {
      message
      entityId
    }
  }
`;

export const franchiseCreatedGQL = gql`
  subscription franchiseCreated {
    franchiseCreated {
      id
      address
      customer {
        id
        name
      }
      name
      phoneNumber
      terminalNumber
      uniqueCode
      franchiseType {
        id
        name
      }
    }
  }
`;

export const franchiseUpdatedGQL = gql`
  subscription franchiseUpdated {
    franchiseUpdated {
      id
      address
      customer {
        id
        name
      }
      name
      phoneNumber
      terminalNumber
      uniqueCode
      franchiseType {
        id
        name
      }
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export const franchiseDeletedGQL = gql`
  subscription franchiseDeleted {
    franchiseDeleted {
      message
      entityId
    }
  }
`;
