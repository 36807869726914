import { gql } from '@apollo/client';

export const servicesGQL = gql`
  query services($pageSize: Float, $pageNumber: Float, $searchTerm: String) {
    services(pageSize: $pageSize, pageNumber: $pageNumber, searchTerm: $searchTerm) {
      data {
        id
        caseNumber
        description
        maintenanceReason
        scheduledTime
        franchise {
          id
          name
        }
        issue {
          id
          idLabel
        }
        issueId
        reason {
          id
          name
        }
        state {
          id
          name
        }
        supplyRequest {
          id
          idLabel
        }
        supplyRequestId
        technician {
          id
          firstName
          lastName
        }
        assignedUser {
          id
          firstName
          lastName
        }
      }
      pageNumber
      pageSize
      totalPages
    }
  }
`;

export const serviceGQL = gql`
  query service($serviceId: String!) {
    service(serviceId: $serviceId) {
      id
      caseNumber
      description
      maintenanceReason
      scheduledTime
      franchise {
        id
        name
      }
      issue {
        id
        idLabel
      }
      issueId
      reason {
        id
        name
      }
      state {
        id
        name
      }
      supplyRequestId
      supplyRequest {
        id
        idLabel
      }
      technician {
        id
        firstName
        lastName
      }
      assignedUser {
        id
        firstName
        lastName
      }
    }
  }
`;

export const createServiceGQL = gql`
  mutation createService($serviceData: ServiceInputType!) {
    createService(serviceData: $serviceData) {
      id
      caseNumber
      description
      maintenanceReason
      scheduledTime
      franchise {
        id
        name
      }
      issue {
        id
        idLabel
      }
      issueId
      reason {
        id
        name
      }
      state {
        id
        name
      }
      supplyRequestId
      supplyRequest {
        id
        idLabel
      }
      technician {
        id
        firstName
        lastName
      }
      assignedUser {
        id
        firstName
        lastName
      }
    }
  }
`;

export const updateServiceGQL = gql`
  mutation updateService($serviceId: String!, $serviceData: ServiceInputType!) {
    updateService(serviceId: $serviceId, serviceData: $serviceData) {
      message
      entityId
    }
  }
`;

export const deleteServiceGQL = gql`
  mutation deleteService($serviceId: String!) {
    deleteService(serviceId: $serviceId) {
      message
      entityId
    }
  }
`;

export const serviceCreatedGQL = gql`
  subscription serviceCreated {
    serviceCreated {
      id
      caseNumber
      description
      maintenanceReason
      scheduledTime
      franchise {
        id
        name
      }
      issue {
        id
        idLabel
      }
      issueId
      reason {
        id
        name
      }
      state {
        id
        name
      }
      supplyRequestId
      supplyRequest {
        id
        idLabel
      }
      technician {
        id
        firstName
        lastName
      }
      assignedUser {
        id
        firstName
        lastName
      }
    }
  }
`;

export const serviceUpdatedGQL = gql`
  subscription serviceUpdated {
    serviceUpdated {
      id
      caseNumber
      description
      maintenanceReason
      scheduledTime
      franchise {
        id
        name
      }
      issue {
        id
        idLabel
      }
      issueId
      reason {
        id
        name
      }
      state {
        id
        name
      }
      supplyRequestId
      supplyRequest {
        id
        idLabel
      }
      technician {
        id
        firstName
        lastName
      }
      assignedUser {
        id
        firstName
        lastName
      }
    }
  }
`;

export const serviceDeletedGQL = gql`
  subscription serviceDeleted {
    serviceDeleted {
      message
      entityId
    }
  }
`;
