import { gql } from '@apollo/client';

export const loginMutation = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      user {
        username
        firstName
      }
      token
    }
  }
`;

export const logoutGQL = gql`
  mutation logout {
    logout
  }
`;

export const restorePasswordCodeMutation = gql`
  mutation restorePasswordCode($username: String!) {
    restorePasswordCode(username: $username)
  }
`;

export const resetPasswordMutation = gql`
  mutation resetPassword($username: String!, $password: String!, $resetCode: String!) {
    restorePassword(username: $username, password: $password, resetCode: $resetCode)
  }
`;

export const checkEmailAvailavilityGQL = gql`
  mutation checkEmailAvailavility($email: String!) {
    emailAvailability: checkEmailAvailavility(email: $email) {
      available
    }
  }
`;

export const refreshTokenGQL = gql`
  mutation refreshToken {
    refreshToken
  }
`;

export const sessionUserGQL = gql`
  query sessionUser {
    sessionUser {
      id
      username
      profileImageUrl
      firstName
      lastName
      email
      phoneNumber
      roles {
        id
        name
        permissions {
          id
          key
          name
        }
      }
    }
  }
`;

export const changeUserPasswordGQL = gql`
  mutation changeUserPassword($userId: String!, $password: String!) {
    changeUserPassword(userId: $userId, password: $password)
  }
`;
