import { gql } from '@apollo/client';

export const allRolesQuery = gql`
  query allRoles {
    roles: allRoles {
      id
      name
    }
  }
`;

export const rolesQueryGQL = gql`
  query roles($pageSize: Float, $pageNumber: Float, $searchTerm: String) {
    roles(pageSize: $pageSize, pageNumber: $pageNumber, searchTerm: $searchTerm) {
      data {
        id
        name
      }
      pageSize
      pageNumber
      totalPages
    }
  }
`;

export const roleQueryGQL = gql`
  query role($roleId: Float!) {
    role(roleId: $roleId) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

export const createRoleMutationGQL = gql`
  mutation CreateRoleMutation($roleData: RoleInputType!) {
    createRole(roleData: $roleData) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

export const updateRoleMutationGQL = gql`
  mutation UpdateRoleMutation($roleId: Float!, $roleData: RoleInputType!) {
    updateRole(roleId: $roleId, roleData: $roleData) {
      message
      entityId
    }
  }
`;

export const deleteRoleMutationGQL = gql`
  mutation DeleteRoleMutation($roleId: Float!) {
    deleteRole(roleId: $roleId)
  }
`;

export const roleCreatedSubGQL = gql`
  subscription roleCreated {
    roleCreated {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

export const roleUpdatedSubGQL = gql`
  subscription roleUpdated {
    roleUpdated {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

export const roleDeletedSubGQL = gql`
  subscription roleDeleted {
    roleDeleted {
      entityId
      message
    }
  }
`;
