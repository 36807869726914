import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TechnicalTutorials } from './technical-tutorials';
import { DemoTypes } from './demo-types';
import { DemoTutorials } from './demo-tutorials';

import './tutorials.scss';

export const TutorialsPage: FC = () => {
  return (
    <div className="tutorials-page">
      <div className="tutorials-content-section">
        <Switch>
          <Route path="/tutorials" exact>
            <Redirect to="/tutorials/technical-tutorials" />
          </Route>
          <Route path="/tutorials/technical-tutorials">
            <TechnicalTutorials />
          </Route>
          <Route path="/tutorials/demo-types">
            <DemoTypes />
          </Route>
          <Route path="/tutorials/demo-tutorials">
            <DemoTutorials />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
