import React, { FC } from 'react';
import { Button } from 'semantic-ui-react';
import styles from './pending-chat.module.scss';

export type ComponentProps = {
  chat: Chat;
  onInit: () => void;
  canInit: boolean;
};

export const PendingChat: FC<ComponentProps> = ({ chat, onInit, canInit }) => {
  return (
    <div className={styles.pendingChatContainer}>
      <div className={styles.chatHeader}>
        <div>{chat.user.franchise?.name}</div>
        <div className={styles.franchiseType}>{chat.user.franchise?.franchiseType?.name}</div>
      </div>
      <div className={styles.chatContent}>
        <div className={styles.avatarContainer}>
          <img src={chat.user.profileImageUrl} alt="profile" className={styles.avatar} />
        </div>
        <div>
          <div>{chat.user.firstName}</div>
          <div>{chat.user.lastName}</div>
        </div>
      </div>
      <div className={styles.chatButtons}>
        <Button primary size="mini" onClick={onInit} disabled={!canInit}>
          Iniciar
        </Button>
      </div>
    </div>
  );
};
