import React, { FC, useCallback, useState } from 'react';
import pageStyles from 'assets/styles/page.module.scss';
import { Button, Input, Pagination, Table } from 'semantic-ui-react';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import { DevicePanel } from './device-panel';

export type ComponentProps = {
  devices: Device[];
  pagination: PaginationProps;
  onSearch: (value: string) => void;
  onPageChange: (page: number) => void;
};

export const DeviceUnitsPage: FC<ComponentProps> = ({
  devices,
  pagination,
  onPageChange,
  onSearch,
}) => {
  const [showDevicePanel, setShowDevicePanel] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<string>(null);

  const openDeviceDetails = useCallback((deviceId: string) => {
    setSelectedDevice(deviceId);
    setShowDevicePanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Dispositivos</div>
        <div className={pageStyles.tableHeader}>
          <div className={pageStyles.controls}>
            <Input
              icon="search"
              placeholder="Buscar"
              className={pageStyles.searchInput}
              onChange={(_, data) => onSearch(data.value)}
            />
          </div>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedDevice(null);
              setShowDevicePanel(true);
            }}
          >
            Crear Dispositivo <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>

      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Serial</Table.HeaderCell>
                <Table.HeaderCell>Número de placa</Table.HeaderCell>
                <Table.HeaderCell>Tipo</Table.HeaderCell>
                <Table.HeaderCell>Referencia</Table.HeaderCell>
                <Table.HeaderCell>Comercio / Sede</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {devices.map((device) => (
                <Table.Row key={device.id}>
                  <Table.Cell width={2} className={pageStyles.boldText}>
                    {device.serial}
                  </Table.Cell>
                  <Table.Cell width={2}>{device.plateNumber}</Table.Cell>
                  <Table.Cell width={2}>{device.deviceReference?.deviceType?.name}</Table.Cell>
                  <Table.Cell width={2}>{device.deviceReference?.name}</Table.Cell>
                  <Table.Cell>
                    {device.franchise
                      ? `${device.franchise.customer?.name} / ${device.franchise.name}`
                      : null}
                  </Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openDeviceDetails(device.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        {pagination && (
          <div className={pageStyles.paginationContainer}>
            <Pagination
              totalPages={pagination.totalPages}
              activePage={pagination.pageNumber}
              firstItem={null}
              lastItem={null}
              onPageChange={(event, data) => onPageChange(data.activePage as number)}
            />
          </div>
        )}
      </div>
      <DevicePanel
        visible={showDevicePanel}
        onClose={() => {
          setSelectedDevice(null);
          setShowDevicePanel(false);
        }}
        deviceId={selectedDevice}
      />
    </div>
  );
};
