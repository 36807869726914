import React, { FC, useCallback } from 'react';
import { allRolesQuery } from 'graphql/role';
import {
  userQuery,
  updateUserMutationGQL,
  createUserMutationGQL,
  deleteUserMutationGQL,
} from 'graphql/user';
import { useMutation, useQuery } from '@apollo/client';
import { checkEmailAvailavilityGQL } from 'graphql/auth';
import { PersonPanel as Component } from './person-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  userId: number;
};

export const PersonPanel: FC<ComponentProps> = ({ visible, onClose, userId }) => {
  const { data: rolesData } = useQuery<{ roles: Role[] }>(allRolesQuery);
  const { data: userData } = useQuery<{ user: User }>(userQuery, {
    variables: { userId: parseFloat(userId as any) },
    skip: userId === null,
  });

  const [createUserMutation] = useMutation(createUserMutationGQL);
  const [updateUserMutation] = useMutation(updateUserMutationGQL);
  const [deleteUserMutation, { loading }] = useMutation(deleteUserMutationGQL);
  const [checkEmailAvailabilityMutation] = useMutation<{ emailAvailability: EmailAvailability }>(
    checkEmailAvailavilityGQL,
  );

  const createUser = useCallback(
    async (user: User) => {
      await createUserMutation({
        variables: { userData: user },
      });
    },
    [createUserMutation],
  );

  const updateUser = useCallback(
    async (user: User) => {
      await updateUserMutation({
        variables: { userId: parseFloat(userId as any), userData: user },
      });
    },
    [userId, updateUserMutation],
  );

  const deleteUser = useCallback(async () => {
    await deleteUserMutation({ variables: { userId: parseFloat(userId as any) } });
  }, [deleteUserMutation, userId]);

  const checkEmailAvailability = useCallback(
    (email: string) => {
      return checkEmailAvailabilityMutation({ variables: { email } }).then(
        (response) => response.data.emailAvailability,
      );
    },
    [checkEmailAvailabilityMutation],
  );

  return (
    <Component
      visible={visible}
      onClose={onClose}
      user={userData?.user ?? null}
      updateUser={updateUser}
      createUser={createUser}
      deleteUser={deleteUser}
      deletingUser={loading}
      checkEmailAvailavility={checkEmailAvailability}
      roles={rolesData?.roles || []}
    />
  );
};
