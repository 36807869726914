import React, { FC, useCallback } from 'react';

import {
  createPermissionMutationGQL,
  deletePermissionMutationGQL,
  permissionQueryGQL,
  updatePermissionMutationGQL,
} from 'graphql/permission';
import { useMutation, useQuery } from '@apollo/client';
import { PermissionPanel as Component } from './permission-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  permissionId: string;
};

export const PermissionPanel: FC<ComponentProps> = ({ permissionId, onClose, visible }) => {
  const { data: permissionData } = useQuery<{ permission: Permission }>(permissionQueryGQL, {
    variables: { permissionId: parseFloat(permissionId as any) },
    skip: permissionId === null,
  });

  const [createPermissionMutation] = useMutation(createPermissionMutationGQL);
  const [updatePermissionMutation] = useMutation(updatePermissionMutationGQL);
  const [deletePermissionMutation, { loading }] = useMutation(deletePermissionMutationGQL);

  const createPermission = useCallback(
    async (permission: Permission) => {
      await createPermissionMutation({
        variables: { permissionData: permission },
      });
    },
    [createPermissionMutation],
  );

  const updatePermission = useCallback(
    async (permission: Permission) => {
      await updatePermissionMutation({
        variables: { permissionId: parseFloat(permissionId as any), permissionData: permission },
      });
    },
    [permissionId, updatePermissionMutation],
  );

  const deletePermission = useCallback(async () => {
    await deletePermissionMutation({
      variables: { permissionId: parseFloat(permissionId as any) },
    });
  }, [permissionId, deletePermissionMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      permission={permissionData?.permission ?? null}
      updatePermission={updatePermission}
      createPermission={createPermission}
      deletePermission={deletePermission}
      deletingPermission={loading}
    />
  );
};
