import { useCallback, useEffect, useRef, useState } from 'react';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export const usePagination = (fetchList: (variables?: PaginatedVariables) => void) => {
  const searchSubject = useRef(new Subject<string>()).current;
  const [searchQueryValue, setSearchQueryValue] = useState<string>(null);

  useEffect(() => {
    const searchSub = searchSubject
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        fetchList({
          searchTerm: value.trim() === '' ? null : value,
        });
        setSearchQueryValue(value);
      });

    return () => searchSub.unsubscribe();
  }, [searchSubject, fetchList]);

  const changePage = useCallback(
    (page: number) => {
      const requestOptions: PaginatedVariables = { pageNumber: page };
      if (searchQueryValue) requestOptions.searchTerm = searchQueryValue;
      fetchList(requestOptions);
    },
    [fetchList, searchQueryValue],
  );

  const search = useCallback(
    (value: string) => {
      searchSubject.next(value);
    },
    [searchSubject],
  );

  return {
    search,
    changePage,
  };
};
