import React, { FC, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import styles from './checkbox.module.scss';

export type ComponentProps = {
  selected: boolean;
  onSelect?: () => void;
  onDeselect?: () => void;
};

export const Checkbox: FC<ComponentProps> = ({ selected, onSelect, onDeselect }) => {
  const handlePress = useCallback(() => {
    if (selected) onDeselect();
    else onSelect();
  }, [onSelect, onDeselect, selected]);

  return (
    <div className={styles.container} aria-hidden onClick={handlePress} id="checkbox-selector">
      {selected ? (
        <div className={styles['selected-icon']}>
          <FontAwesomeIcon icon={faCheckSquare} size="lg" />
        </div>
      ) : (
        <div className={styles['square-box']} />
      )}
    </div>
  );
};

Checkbox.defaultProps = {
  onSelect: () => {},
  onDeselect: () => {},
};
