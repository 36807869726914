import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './services.scss';

import { ServiceStates } from './service-states';
import { ServiceNoveltyTypes } from './service-novelty-types';
import { ServiceReasons } from './service-reasons';
import { ServicesList } from './services-list';

export const ServicesPage: FC = () => {
  return (
    <div className="services-page">
      <div className="services-content-section">
        <Switch>
          <Route path="/services" exact>
            <Redirect to="/services/units" />
          </Route>
          <Route path="/services/units">
            <ServicesList />
          </Route>
          <Route path="/services/reasons">
            <ServiceReasons />
          </Route>
          <Route path="/services/novelty-types">
            <ServiceNoveltyTypes />
          </Route>
          <Route path="/services/states">
            <ServiceStates />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
