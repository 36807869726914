import React, { FC, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { allDeviceReferencesGQL } from 'graphql/device-reference';
import { allDemoTypesGQL } from 'graphql/demo-type';
import { demoGQL, createDemoGQL, deleteDemoGQL, updateDemoGQL } from 'graphql/demo-tutorial';
import { DemoTutorialPanel as Component } from './demo-tutorial-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  demoId: string;
};

export const DemoTutorialPanel: FC<ComponentProps> = ({ demoId, visible, onClose }) => {
  const { data: demoResponse } = useQuery<{ demo: DemoTutorial }>(demoGQL, {
    variables: { demoId },
    skip: demoId === null,
  });

  const { data: demoTypesResponse } = useQuery<{ demoTypes: DemoType[] }>(allDemoTypesGQL);
  const { data: referencesResponse } = useQuery<{ deviceReferences: DeviceReference[] }>(
    allDeviceReferencesGQL,
  );

  const [createDemoMutation] = useMutation(createDemoGQL);
  const [updateDemoMutation] = useMutation(updateDemoGQL);
  const [deleteDemoMutation, { loading: deletingDemo }] = useMutation(deleteDemoGQL);

  const createDemo = useCallback(
    async (demoData: DemoTutorial) => {
      await createDemoMutation({ variables: { demoData } });
    },
    [createDemoMutation],
  );
  const updateDemo = useCallback(
    async (demoData: DemoTutorial) => {
      await updateDemoMutation({ variables: { demoId, demoData } });
    },
    [demoId, updateDemoMutation],
  );
  const deleteDemo = useCallback(async () => {
    await deleteDemoMutation({ variables: { demoId } });
  }, [demoId, deleteDemoMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      demo={demoResponse?.demo ?? null}
      updateDemo={updateDemo}
      createDemo={createDemo}
      deleteDemo={deleteDemo}
      deletingDemo={deletingDemo}
      demoTypes={demoTypesResponse?.demoTypes ?? []}
      deviceReferences={referencesResponse?.deviceReferences ?? []}
    />
  );
};
