import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './supplies.scss';
import { SupplyTypesPage } from './supply-types';
import { SupplyUnitsPage } from './supply-units';
import { SupplyRequestsPage } from './supply-requests';

export const SuppliesPage: FC = () => {
  return (
    <div className="supplies-page">
      <div className="supplies-content-section">
        <Switch>
          <Route path="/supplies" exact>
            <Redirect to="/supplies/units" />
          </Route>
          <Route path="/supplies/units">
            <SupplyUnitsPage />
          </Route>
          <Route path="/supplies/types">
            <SupplyTypesPage />
          </Route>
          <Route path="/supplies/requests">
            <SupplyRequestsPage />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
