import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  allFranchiseTypesGQL,
  franchiseTypeCreatedGQL,
  franchiseTypeDeletedGQL,
  franchiseTypeUpdatedGQL,
} from 'graphql/franchise-type';

import { FranchiseTypePage as Component } from './franchise-types.page';

export const FranchiseTypePage: FC = () => {
  const { data, refetch } = useQuery<{ franchiseTypes: FranchiseType[] }>(allFranchiseTypesGQL);

  useSubscription(franchiseTypeUpdatedGQL);
  useSubscription(franchiseTypeCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(franchiseTypeDeletedGQL, { onSubscriptionData: () => refetch() });

  return <Component franchiseTypes={data?.franchiseTypes ?? []} />;
};
