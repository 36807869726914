import { gql } from '@apollo/client';

export const allTechnicalTutorialsGQL = gql`
  query allTechnicalTutorials {
    technicalTutorials: allTechnicalTutorials {
      id
      title
      description
    }
  }
`;

export const technicalTutorialGQL = gql`
  query technicalTutorial($technicalTutorialId: String!) {
    technicalTutorial(technicalTutorialId: $technicalTutorialId) {
      id
      title
      description
    }
  }
`;

export const createTechnicalTutorialGQL = gql`
  mutation createTechnicalTutorial($technicalTutorialData: TechnicalTutorialInputType!) {
    createTechnicalTutorial(technicalTutorialData: $technicalTutorialData) {
      id
      title
      description
    }
  }
`;

export const updateTechnicalTutorialGQL = gql`
  mutation updateTechnicalTutorial(
    $technicalTutorialId: String!
    $technicalTutorialData: TechnicalTutorialInputType!
  ) {
    updateTechnicalTutorial(
      technicalTutorialId: $technicalTutorialId
      technicalTutorialData: $technicalTutorialData
    ) {
      message
      entityId
    }
  }
`;

export const deleteTechnicalTutorialGQL = gql`
  mutation deleteTechnicalTutorial($technicalTutorialId: String!) {
    deleteTechnicalTutorial(technicalTutorialId: $technicalTutorialId) {
      message
      entityId
    }
  }
`;

export const technicalTutorialCreatedGQL = gql`
  subscription technicalTutorialCreated {
    technicalTutorialCreated {
      id
      title
      description
    }
  }
`;

export const technicalTutorialUpdatedGQL = gql`
  subscription technicalTutorialUpdated {
    technicalTutorialUpdated {
      id
      title
      description
    }
  }
`;

export const technicalTutorialDeletedGQL = gql`
  subscription technicalTutorialDeleted {
    technicalTutorialDeleted {
      message
      entityId
    }
  }
`;
