import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  allTechnicalTutorialsGQL,
  technicalTutorialCreatedGQL,
  technicalTutorialUpdatedGQL,
  technicalTutorialDeletedGQL,
} from 'graphql/technical-tutorial';

import { TechnicalTutorials as Component } from './technical-tutorials';

export const TechnicalTutorials: FC = () => {
  const { data, refetch } = useQuery<{ technicalTutorials: TechnicalTutorial[] }>(
    allTechnicalTutorialsGQL,
  );

  useSubscription(technicalTutorialUpdatedGQL);
  useSubscription(technicalTutorialCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(technicalTutorialDeletedGQL, { onSubscriptionData: () => refetch() });

  return <Component technicalTutorials={data?.technicalTutorials ?? []} />;
};
