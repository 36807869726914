import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, Form, Icon } from 'semantic-ui-react';
import { ModalSelector, SelectorItem } from 'components/modal-selector';
import * as Yup from 'yup';
import { PanelItem } from 'components/panel-item';
import styles from './roles-panel.module.scss';
import { ValidationMessages } from '../../../../utils/validation-messages';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  role: Role;
  updateRole: (role: Role) => Promise<void>;
  createRole: (role: Role) => Promise<void>;
  deleteRole: () => Promise<void>;
  deletingRole: boolean;
  permissions: Permission[];
};

type FormValues = {
  name: string;
  permissions: number[];
};

const initialValues: FormValues = {
  name: '',
  permissions: [],
};

const validationSchema = Yup.object().shape<FormValues>({
  name: Yup.string().nullable().required(ValidationMessages.required),
  permissions: null,
});

export const RolePanel: FC<ComponentProps> = ({
  visible,
  onClose,
  role,
  createRole,
  updateRole,
  deleteRole,
  deletingRole,
  permissions,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);
  const [showSubPanel, setShowSubPanel] = useState(false);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      setShowSubPanel(false);
      helpers.setSubmitting(true);
      if (role) updateRole(values as any).then(onClose);
      else createRole(values as any).then(onClose);
    },
    [role, updateRole, createRole, onClose],
  );

  const permissionItems = useMemo<SelectorItem[]>(() => {
    return permissions.map((permission) => ({
      label: permission.name,
      value: parseFloat(permission.id),
      selected: role ? role.permissions.some(({ id }) => id === permission.id) : false,
    }));
  }, [permissions, role]);

  useEffect(() => {
    if (role) {
      formRef.current.setFieldValue('name', role.name);
      formRef.current.setFieldValue(
        'permissions',
        role.permissions.map(({ id }) => parseFloat(id)),
      );
    }
  }, [role]);

  return (
    <SidePanel
      onClose={onClose}
      visible={visible}
      subPanel={
        <div>
          <div className="panel-title">Permisos</div>
          <div>
            {permissions.map((permission) => (
              <PanelItem
                key={permission.id}
                onClick={() => {
                  if (!formRef.current.values.permissions.includes(parseFloat(permission.id)))
                    formRef.current.setFieldValue('permissions', [
                      ...formRef.current.values.permissions,
                      parseFloat(permission.id as any),
                    ]);
                }}
                text={permission.name}
                icon={<Icon name="angle right" size="large" />}
              />
            ))}
          </div>
        </div>
      }
      onCloseSubPanel={() => setShowSubPanel(false)}
      showSubPanel={showSubPanel}
    >
      <div className="panel-title">{role?.name ?? 'Crear Rol'}</div>
      {role && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingRole}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content={
              <div className="content">
                Desea eliminar el Rol <span className={styles.deletingRole}>{role.name}</span>?
              </div>
            }
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteRole().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, isSubmitting, setFieldValue }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <FastField
                name="name"
                label="Nombre"
                component={InputField}
                value={values.name}
                placeholder="Nombre del Rol"
              />

              <div className="field">
                <div className={styles.inlineLabel}>
                  <label>Permisos</label>
                  <ModalSelector
                    trigger={
                      <Button size="tiny" icon type="button">
                        Editar &nbsp;
                        <Icon name="pencil" />
                      </Button>
                    }
                    title="Permisos"
                    items={permissionItems}
                    onSave={(rolePermissions) => setFieldValue('permissions', rolePermissions)}
                  />
                </div>
              </div>
              <div className="buttons-container">
                <Button size="small" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="small" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
