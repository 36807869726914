import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, Form, Icon } from 'semantic-ui-react';

interface ComponentProps {
  visible: boolean;
  onClose: () => void;
  deviceType: DeviceType;
  updateDeviceType: (deviceType: DeviceType) => Promise<void>;
  createDeviceType: (deviceType: DeviceType) => Promise<void>;
  deleteDeviceType: () => Promise<void>;
  deletingDeviceType: boolean;
}

interface FormValues {
  name: string;
}

const initialValues: FormValues = {
  name: '',
};

export const DeviceTypePanel: FC<ComponentProps> = ({
  visible,
  onClose,
  deviceType,
  updateDeviceType,
  createDeviceType,
  deleteDeviceType,
  deletingDeviceType,
}) => {
  console.log(deviceType);
  const formRef = useRef<FormikProps<FormValues>>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      if (deviceType) updateDeviceType(values).then(onClose);
      else createDeviceType(values).then(onClose);
    },
    [deviceType, updateDeviceType, createDeviceType, onClose],
  );

  useEffect(() => {
    if (deviceType) {
      formRef.current.setFieldValue('name', deviceType.name);
    }
  }, [deviceType]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">{deviceType?.name ?? 'Crear Tipo de Dispositivo'}</div>
      {deviceType && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingDeviceType}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar el tipo de dispositivo seleccionado?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteDeviceType().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit} innerRef={formRef}>
        {({ handleSubmit, values, isSubmitting }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <FastField
                name="name"
                label="Descripción"
                component={InputField}
                value={values.name}
                placeholder="Descripción"
              />
              <div className="buttons-container">
                <Button size="medium" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="medium" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
