import React, { FC, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  demoTypeGQL,
  createDemoTypeGQL,
  deleteDemoTypeGQL,
  updateDemoTypeGQL,
} from 'graphql/demo-type';

import { DemoTypePanel as Component } from './demo-type-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  demoTypeId: string;
};

export const DemoTypePanel: FC<ComponentProps> = ({ demoTypeId, visible, onClose }) => {
  const { data } = useQuery<{ demoType: DemoType }>(demoTypeGQL, {
    variables: { demoTypeId },
    skip: demoTypeId === null,
  });

  const [createDemoTypeMutation] = useMutation(createDemoTypeGQL);
  const [updateDemoTypeMutation] = useMutation(updateDemoTypeGQL);
  const [deleteDemoTypeMutation, { loading }] = useMutation(deleteDemoTypeGQL);

  const createDemoType = useCallback(
    async (demoTypeData: DemoType) => {
      await createDemoTypeMutation({ variables: { demoTypeData } });
    },
    [createDemoTypeMutation],
  );
  const updateDemoType = useCallback(
    async (demoTypeData: DemoType) => {
      await updateDemoTypeMutation({ variables: { demoTypeId, demoTypeData } });
    },
    [demoTypeId, updateDemoTypeMutation],
  );
  const deleteDemoType = useCallback(async () => {
    await deleteDemoTypeMutation({ variables: { demoTypeId } });
  }, [demoTypeId, deleteDemoTypeMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      demoType={data?.demoType ?? null}
      updateDemoType={updateDemoType}
      createDemoType={createDemoType}
      deleteDemoType={deleteDemoType}
      deletingDemoType={loading}
    />
  );
};
