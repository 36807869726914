import React, { FC } from 'react';
import { Button } from 'semantic-ui-react';
import './device-card.scss';

interface ComponentProps {
  onReport: () => void;
}

export const DeviceCard: FC<ComponentProps> = ({ onReport }) => {
  return (
    <div className="device-card">
      <div className="image-section">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/ruteo-tc-13329.appspot.com/o/datafonos%2FiCT_Series-250.png?alt=media&token=cd78e6b8-eed9-45be-9a21-02fd73803f56"
          alt="device"
        />
      </div>
      <div className="name-section">
        <div className="section-label">Nombre</div>
        <div className="section-desc">Movil Device</div>
      </div>
      <div className="serial-section">
        <div className="section-label">Serial</div>
        <div className="section-desc">AFG-677-SSSD</div>
      </div>
      <div className="type-section">
        <div className="section-label">Tipo</div>
        <div className="section-desc">Movil</div>
      </div>
      <div className="reference-section">
        <div className="section-label">Referencia</div>
        <div className="section-desc">iCT-250</div>
      </div>
      <div className="security-code-section">
        <div className="section-label">Codigo de seguridad</div>
        <div className="section-desc">987456</div>
      </div>
      <div className="pinpad-code-section">
        <div className="section-label">Codigo PinPad</div>
        <div className="section-desc">18973</div>
      </div>
      <div className="report-section">
        <Button fluid primary className="report-button" onClick={onReport}>
          Reportar Falla
        </Button>
      </div>
      {/*<div className="history-section">*/}
      {/*  <Button fluid>Historial</Button>*/}
      {/*</div>*/}
      {/*<div className="cissues-section">*/}
      {/*  <Button fluid>Fallas Comunes</Button>*/}
      {/*</div>*/}
    </div>
  );
};
