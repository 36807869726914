import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  allServiceNoveltiesGQL,
  serviceNoveltyCreatedGQL,
  serviceNoveltyDeletedGQL,
  serviceNoveltyUpdatedGQL,
} from 'graphql/service-novelties';

import { ServiceNoveltyTypes as Component } from './service-novelty-types';

export const ServiceNoveltyTypes: FC = () => {
  const { data, loading, refetch } = useQuery<{ serviceNovelties: ServiceNoveltyType[] }>(
    allServiceNoveltiesGQL,
  );

  useSubscription(serviceNoveltyUpdatedGQL);
  useSubscription(serviceNoveltyCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(serviceNoveltyDeletedGQL, { onSubscriptionData: () => refetch() });

  return <Component serviceNovelTyTypes={data?.serviceNovelties ?? []} loading={loading} />;
};
