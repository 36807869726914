import React, { FC, useCallback, useState } from 'react';
import './roles.page.scss';
import { Button, Input, Table } from 'semantic-ui-react';
import pageStyles from 'assets/styles/page.module.scss';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import { RolePanel } from './roles-panel';

export type ComponentProps = {
  roles: Role[];
  pagination: PaginationProps;
  onSearch: (value: string) => void;
  onPageChange: (page: number) => void;
};

export const RolesPage: FC<ComponentProps> = ({ roles, pagination, onSearch, onPageChange }) => {
  const [showRolePanel, setShowRolePanel] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string>(null);

  const openRoleDetails = useCallback((roleId: string) => {
    setSelectedRole(roleId);
    setShowRolePanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Roles</div>
        <div className={pageStyles.tableHeader}>
          <div className={pageStyles.controls}>
            <Input
              icon="search"
              placeholder="Buscar"
              className={pageStyles.searchInput}
              onChange={(_, data) => onSearch(data.value)}
            />
          </div>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedRole(null);
              setShowRolePanel(true);
            }}
          >
            Crear Rol <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {roles.map((role) => (
                <Table.Row key={role.id}>
                  <Table.Cell>{role.name}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openRoleDetails(role.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <RolePanel
        visible={showRolePanel}
        onClose={() => {
          setSelectedRole(null);
          setShowRolePanel(false);
        }}
        roleId={selectedRole}
      />
    </div>
  );
};
