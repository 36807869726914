import React, { FC } from 'react';
import { usePagination } from 'utils/hooks/pagination';
import { userCreatedSubGQL, userDeletedSubGQL, usersQuery, userUpdatedSubGQL } from 'graphql/user';
import { useQuery, useSubscription } from '@apollo/client';

import { Persons as Component } from './persons';

export const Persons: FC = () => {
  const { data, loading, refetch } = useQuery<
    { users: PaginatedEntityList<User> },
    PaginatedVariables
  >(usersQuery, { variables: { pageSize: parseFloat(process.env.REACT_APP_TABLE_PAGE_SIZE) } });
  const { search, changePage } = usePagination(refetch);

  useSubscription(userUpdatedSubGQL);
  useSubscription(userCreatedSubGQL, { onSubscriptionData: () => refetch() });
  useSubscription(userDeletedSubGQL, { onSubscriptionData: () => refetch() });

  return (
    <Component
      users={data?.users.data || []}
      onPageChange={changePage}
      loading={loading}
      pagination={
        loading ? null : { pageNumber: data?.users.pageNumber, totalPages: data?.users.totalPages }
      }
      onSearch={search}
    />
  );
};
