import React, { FC, useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { AddressModal } from 'components/address-modal';
import { SelectorField } from 'components/selector-field';
import { Formik, FormikProps, FastField, FormikHelpers } from 'formik';
import { Button, Form, Icon, Confirm, DropdownItemProps } from 'semantic-ui-react';
import pageStyles from 'assets/styles/page.module.scss';
import { ValidationMessages } from 'utils/validation-messages';
import * as Yup from 'yup';
import { EditLocationOutlined } from '@material-ui/icons';

import styles from './franchise-panel.module.scss';
import { ModalSelector, SelectorItem } from '../../../../components/modal-selector';

interface ComponentProps {
  visible: boolean;
  onClose: () => void;
  franchise: Franchise;
  updateFranchise: (franchise: Franchise) => Promise<void>;
  createFranchise: (franchise: Franchise) => Promise<void>;
  deleteFranchise: () => Promise<void>;
  deletingFranchise: boolean;
  customers: Customer[];
  franchiseTypes: FranchiseType[];
  users: User[];
}

interface FormValues {
  name: string;
  address: string;
  customerId: number;
  phoneNumber: string;
  terminalNumber: string;
  uniqueCode: string;
  franchiseTypeId: number;
  geoLocation: GeoLocation;
  userIds: string[];
}

const initialValues: FormValues = {
  name: '',
  address: '',
  customerId: null,
  phoneNumber: '',
  terminalNumber: '',
  uniqueCode: '',
  franchiseTypeId: null,
  geoLocation: null,
  userIds: null,
};

const validationSchema = Yup.object().shape<FormValues>({
  name: Yup.string().required(ValidationMessages.required),
  address: Yup.string().required(ValidationMessages.required),
  customerId: Yup.number().nullable(),
  phoneNumber: Yup.string().required(ValidationMessages.required),
  terminalNumber: Yup.string().required(ValidationMessages.required),
  uniqueCode: Yup.string().required(ValidationMessages.required),
  franchiseTypeId: Yup.number().nullable(),
  geoLocation: null,
  userIds: null,
});

export const FranchisePanel: FC<ComponentProps> = ({
  visible,
  onClose,
  franchise,
  updateFranchise,
  createFranchise,
  deleteFranchise,
  deletingFranchise,
  customers,
  franchiseTypes,
  users,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);

  const custCommercesOptions = useMemo<DropdownItemProps[]>(() => {
    return customers.map((cCommerce) => ({
      key: cCommerce.id,
      text: cCommerce.name,
      value: cCommerce.id,
    }));
  }, [customers]);

  const franchiseTypeOptions = useMemo<DropdownItemProps[]>(() => {
    return franchiseTypes.map((type) => ({
      key: type.id,
      text: type.name,
      value: type.id,
    }));
  }, [franchiseTypes]);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      if (franchise) updateFranchise(values).then(onClose);
      else createFranchise(values).then(onClose);
    },
    [franchise, updateFranchise, createFranchise, onClose],
  );

  useEffect(() => {
    if (franchise) {
      formRef.current.setFieldValue('name', franchise.name);
      formRef.current.setFieldValue('address', franchise.address);
      formRef.current.setFieldValue('customerId', franchise.customer?.id ?? null);
      formRef.current.setFieldValue('franchiseTypeId', franchise.franchiseType?.id ?? null);
      formRef.current.setFieldValue('terminalNumber', franchise.terminalNumber);
      formRef.current.setFieldValue('phoneNumber', franchise.phoneNumber);
      formRef.current.setFieldValue('uniqueCode', franchise.uniqueCode);
    }
  }, [franchise]);

  const userItems = useMemo<SelectorItem[]>(() => {
    return users.map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id.toString(),
      selected: franchise ? franchise.users.some(({ id }) => id === user.id) : false,
    }));
  }, [users, franchise]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">{franchise?.name ?? 'Crear Sede'}</div>
      {franchise && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingFranchise}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar la franquicia seleccionada?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteFranchise().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, isSubmitting, setFieldValue }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <FastField
                name="name"
                label="Nombre"
                component={InputField}
                value={values.name}
                placeholder="Nombre franquicia"
              />
              <div className={styles.addressSelector}>
                <FastField
                  name="address"
                  label="Dirección"
                  component={InputField}
                  value={values.address}
                  placeholder="Dirección franquicia"
                  disabled
                />
                <div className={styles.iconContainer}>
                  <AddressModal
                    trigger={<EditLocationOutlined className={styles.icon} />}
                    title="Dirección de Sede"
                    address={values.address}
                    onSave={({ address, geoLocation }) => {
                      setFieldValue('address', address);
                      setFieldValue('geoLocation', geoLocation);
                    }}
                  />
                </div>
              </div>
              <div className="field">
                <div className={styles.inlineLabel}>
                  <label>Usuarios</label>
                  <ModalSelector
                    trigger={
                      <Button size="mini" icon type="button" primary>
                        Editar &nbsp;
                        <Icon name="pencil" />
                      </Button>
                    }
                    title="Usuarios de Sede"
                    items={userItems}
                    onSave={(franchiseUsers) => setFieldValue('userIds', franchiseUsers)}
                  />
                </div>
              </div>
              <FastField
                name="terminalNumber"
                label="Número de Terminal"
                component={InputField}
                value={values.terminalNumber}
                placeholder="Número"
              />
              <FastField
                name="phoneNumber"
                label="Número de Teléfono"
                component={InputField}
                value={values.phoneNumber}
                placeholder="Número de Teléfono"
              />
              <FastField
                name="uniqueCode"
                label="Código Único"
                component={InputField}
                value={values.uniqueCode}
                placeholder="Código Único"
              />
              <FastField
                name="customerId"
                label="Comercio"
                component={SelectorField}
                value={values.customerId}
                placeholder="Seleccione un comercio"
                options={custCommercesOptions}
              />
              <FastField
                name="franchiseTypeId"
                label="Tipo de Sede"
                component={SelectorField}
                value={values.franchiseTypeId}
                placeholder="Seleccione un tipo"
                options={franchiseTypeOptions}
              />
              <div className="buttons-container">
                <Button size="medium" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="medium" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
