import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, Form, Icon } from 'semantic-ui-react';

interface ComponentProps {
  visible: boolean;
  onClose: () => void;
  permission: Permission;
  updatePermission: (permission: Permission) => Promise<void>;
  createPermission: (permission: Permission) => Promise<void>;
  deletePermission: () => Promise<void>;
  deletingPermission: boolean;
}

interface FormValues {
  name: string;
  key: string;
  description: string;
}

const initialValues: FormValues = {
  name: '',
  key: '',
  description: '',
};

export const PermissionPanel: FC<ComponentProps> = ({
  visible,
  onClose,
  permission,
  updatePermission,
  createPermission,
  deletePermission,
  deletingPermission,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      if (permission) updatePermission({ ...values }).then(onClose);
      else
        createPermission({
          name: values.name,
          description: values.description,
          key: values.key,
        }).then(onClose);
    },
    [updatePermission, createPermission, onClose, permission],
  );

  useEffect(() => {
    if (permission) {
      formRef.current.setFieldValue('description', permission.description);
      formRef.current.setFieldValue('name', permission.name);
      formRef.current.setFieldValue('key', permission.key);
    }
  }, [permission]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">{permission?.name ?? 'Crear Permiso'}</div>
      {permission && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingPermission}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar el permiso seleccionado?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deletePermission().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit} innerRef={formRef}>
        {({ handleSubmit, values, isSubmitting }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <FastField
                name="name"
                label="Nombre"
                component={InputField}
                value={values.name}
                placeholder="Nombre del permiso"
              />
              <FastField
                name="key"
                label="Llave"
                component={InputField}
                value={values.key}
                placeholder="Llave"
              />
              <FastField
                name="description"
                label="Descripción"
                component={InputField}
                value={values.description}
                placeholder="Descripción del permiso"
              />
              <div className="buttons-container">
                <Button size="medium" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="medium" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
