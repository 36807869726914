import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  franchiseCreatedGQL,
  franchiseDeletedGQL,
  franchisesGQL,
  franchiseUpdatedGQL,
} from 'graphql/franchise';

import { usePagination } from 'utils/hooks/pagination';
import { FranchisesPage as Component } from './franchises';

export const FranchisesPage: FC = () => {
  const { data, loading, refetch } = useQuery<
    { franchises: PaginatedEntityList<Franchise> },
    PaginatedVariables
  >(franchisesGQL, {
    variables: { pageSize: parseFloat(process.env.REACT_APP_TABLE_PAGE_SIZE) },
  });

  const { search, changePage } = usePagination(refetch);

  useSubscription(franchiseUpdatedGQL);
  useSubscription(franchiseCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(franchiseDeletedGQL, { onSubscriptionData: () => refetch() });

  return (
    <Component
      franchises={data?.franchises.data ?? []}
      pagination={
        loading
          ? null
          : { pageNumber: data?.franchises.pageNumber, totalPages: data?.franchises.totalPages }
      }
      onSearch={search}
      onPageChange={changePage}
    />
  );
};
