import React, { FC, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { checkEmailAvailavilityGQL, sessionUserGQL } from 'graphql/auth';
import { UserDetailsForm as Component } from './user-details.form';
import { updateUserMutationGQL } from '../../../graphql/user';

export const UserDetailsForm: FC = () => {
  const { data } = useQuery<{ sessionUser: User }>(sessionUserGQL);
  const [updateUserMutation, { loading }] = useMutation(updateUserMutationGQL);
  const [checkEmailAvailabilityMutation] = useMutation<{ emailAvailability: EmailAvailability }>(
    checkEmailAvailavilityGQL,
  );

  const updateUser = useCallback(
    async (user: User) => {
      await updateUserMutation({
        variables: { userId: parseFloat(data?.sessionUser.id as any), userData: user },
      });
    },
    [data.sessionUser, updateUserMutation],
  );

  const checkEmailAvailability = useCallback(
    (email: string) => {
      return checkEmailAvailabilityMutation({ variables: { email } }).then(
        (response) => response.data.emailAvailability,
      );
    },
    [checkEmailAvailabilityMutation],
  );

  return (
    <Component
      user={data?.sessionUser ?? null}
      checkEmailAvailability={checkEmailAvailability}
      updateUser={updateUser}
      updatingUser={loading}
    />
  );
};
