import React, { FC, useCallback, useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import pageStyles from 'assets/styles/page.module.scss';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import { TechnicalTutorialPanel } from './technical-tutorial-panel';

export type ComponentProps = {
  technicalTutorials: TechnicalTutorial[];
};

export const TechnicalTutorials: FC<ComponentProps> = ({ technicalTutorials }) => {
  const [showTutorialPanel, setShowTutorialPanel] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState<string>(null);

  const openTutorialPanel = useCallback((tutorialId: string) => {
    setSelectedTutorial(tutorialId);
    setShowTutorialPanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Tutoriales Técnicos</div>
        <div className={pageStyles.tableHeader}>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedTutorial(null);
              setShowTutorialPanel(true);
            }}
          >
            Crear Tutorial Técnico <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>

      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Titulo</Table.HeaderCell>
                {/*<Table.HeaderCell width={9}>Descripción</Table.HeaderCell>*/}
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {technicalTutorials.map((tutorial) => (
                <Table.Row key={tutorial.id}>
                  <Table.Cell className={pageStyles.boldText}>{tutorial.title}</Table.Cell>
                  {/*<Table.Cell className={pageStyles.longText}>{tutorial.description}</Table.Cell>*/}
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openTutorialPanel(tutorial.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <TechnicalTutorialPanel
        visible={showTutorialPanel}
        onClose={() => {
          setSelectedTutorial(null);
          setShowTutorialPanel(false);
        }}
        technicalTutorialId={selectedTutorial}
      />
    </div>
  );
};
