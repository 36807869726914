import React, { FC } from 'react';
import { ErrorMessage, FastFieldProps } from 'formik';
import { Form, TextArea } from 'semantic-ui-react';

interface ComponentProps extends FastFieldProps {
  label?: string;
  placeholder: string;
}

export const TextAreaField: FC<ComponentProps> = ({ field, label, placeholder, form }) => {
  return (
    <div className="text-area-field">
      <Form.Field>
        {label && <label>{label}</label>}
        <TextArea
          rows={5}
          value={field.value}
          placeholder={placeholder}
          onChange={(_, data) => form.setFieldValue(field.name, data.value)}
        />
      </Form.Field>
    </div>
  );
};

TextAreaField.defaultProps = {
  label: null,
  placeholder: '',
};
