import { gql } from '@apollo/client';

export const allDemoTypesGQL = gql`
  query allDemoTypes {
    demoTypes: allDemoTypes {
      id
      name
      description
    }
  }
`;

export const demoTypeGQL = gql`
  query demoType($demoTypeId: String!) {
    demoType(demoTypeId: $demoTypeId) {
      id
      name
      description
    }
  }
`;

export const createDemoTypeGQL = gql`
  mutation createDemoType($demoTypeData: DemoTypeInputType!) {
    createDemoType(demoTypeData: $demoTypeData) {
      id
      name
      description
    }
  }
`;

export const updateDemoTypeGQL = gql`
  mutation updateDemoType($demoTypeId: String!, $demoTypeData: DemoTypeInputType!) {
    updateDemoType(demoTypeId: $demoTypeId, demoTypeData: $demoTypeData) {
      message
      entityId
    }
  }
`;

export const deleteDemoTypeGQL = gql`
  mutation deleteDemoType($demoTypeId: String!) {
    deleteDemoType(demoTypeId: $demoTypeId) {
      message
      entityId
    }
  }
`;

export const demoTypeCreatedGQL = gql`
  subscription demoTypeCreated {
    demoTypeCreated {
      id
      name
      description
    }
  }
`;

export const demoTypeUpdatedGQL = gql`
  subscription demoTypeUpdated {
    demoTypeUpdated {
      id
      name
      description
    }
  }
`;

export const demoTypeDeletedGQL = gql`
  subscription demoTypeDeleted {
    demoTypeDeleted {
      message
      entityId
    }
  }
`;
