import React, { FC, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { allDeviceReferencesGQL } from 'graphql/device-reference';
import { allFranchisesGQL } from 'graphql/franchise';
import { deviceGQL, createDeviceGQL, updateDeviceGQL, deleteDeviceGQL } from 'graphql/device';
import { DevicePanel as Component } from './device-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  deviceId: string;
};

export const DevicePanel: FC<ComponentProps> = ({ visible, onClose, deviceId }) => {
  const { data: deviceReferencesResponse } = useQuery<{ deviceReferences: DeviceReference[] }>(
    allDeviceReferencesGQL,
    {
      fetchPolicy: 'network-only',
    },
  );

  const { data: franchiesResponse } = useQuery<{ franchises: Franchise[] }>(allFranchisesGQL, {
    fetchPolicy: 'network-only',
  });

  const { data: deviceResponse } = useQuery<{ device: Device }>(deviceGQL, {
    variables: { deviceId },
    skip: deviceId === null,
  });

  const [createDeviceMutation] = useMutation(createDeviceGQL);
  const [updateDeviceMutation] = useMutation(updateDeviceGQL);
  const [deleteDeviceMutation, { loading }] = useMutation(deleteDeviceGQL);

  const createDevice = useCallback(
    async (deviceData: Device) => {
      await createDeviceMutation({ variables: { deviceData } });
    },
    [createDeviceMutation],
  );
  const updateDevice = useCallback(
    async (deviceData: Device) => {
      await updateDeviceMutation({ variables: { deviceId, deviceData } });
    },
    [deviceId, updateDeviceMutation],
  );
  const deleteDevice = useCallback(async () => {
    await deleteDeviceMutation({ variables: { deviceId } });
  }, [deviceId, deleteDeviceMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      device={deviceResponse?.device ?? null}
      updateDevice={updateDevice}
      createDevice={createDevice}
      deleteDevice={deleteDevice}
      deletingDevice={loading}
      deviceReferences={deviceReferencesResponse?.deviceReferences ?? []}
      franchises={franchiesResponse?.franchises ?? []}
    />
  );
};
