import React, { FC, useCallback } from 'react';
import { FastField, Formik } from 'formik';
import { Button, Form, Icon } from 'semantic-ui-react';
import { InputField } from 'components/input-field';
import AppLogo from 'assets/images/app-logo.svg';
import * as Yup from 'yup';

import styles from './login.module.scss';

export interface ComponentProps {
  isLogingUser: boolean;
  openRestorePassword: () => void;
  loginUser: (username: string, password: string) => void;
  error: string;
}

interface LoginFormValues {
  username: string;
  password: string;
}

const initialValues: LoginFormValues = {
  username: '',
  password: '',
};

const validationSchema = Yup.object().shape<LoginFormValues>({
  username: Yup.string().nullable().required('Este campo es requerido'),
  password: Yup.string().nullable().required('Este campo es requerido'),
});

export const Login: FC<ComponentProps> = ({
  loginUser,
  isLogingUser,
  openRestorePassword,
  error,
}) => {
  const handleUserLogin = useCallback(
    (values: LoginFormValues) => {
      const { username, password } = values;
      loginUser(username, password);
    },
    [loginUser],
  );

  return (
    <div className={styles.container}>
      <img src={AppLogo} alt="logo" className={styles.appLogo} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUserLogin}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit} className={styles.loginForm}>
            <FastField label="Nombre de usuario" name="username" component={InputField} />
            <FastField label="Contraseña" name="password" type="password" component={InputField} />
            {error && <div className={styles.messagesSection}>{error}</div>}
            <Button
              icon
              labelPosition="right"
              primary
              size="large"
              loading={isLogingUser}
              className={styles.loginButton}
            >
              Iniciar Sesión <Icon name="sign in" />
            </Button>
            <div className={styles.link} aria-hidden onClick={openRestorePassword}>
              Recuperar Contraseña
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
