import React, { FC, useCallback, useState } from 'react';
import pageStyles from 'assets/styles/page.module.scss';
import { Button, Table } from 'semantic-ui-react';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';

import { FranchiseTypePanel } from './franchise-type-panel';

export type ComponentProps = {
  franchiseTypes: FranchiseType[];
};

export const FranchiseTypePage: FC<ComponentProps> = ({ franchiseTypes }) => {
  const [showFranchiseTypePanel, setShowFranchiseTypePanel] = useState(false);
  const [selectedFranchiseType, setSelectedFranchiseType] = useState<string>(null);

  const openFranchiseTypePanel = useCallback((franchiseTypeId: string) => {
    setSelectedFranchiseType(franchiseTypeId);
    setShowFranchiseTypePanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Tipos de Sede</div>
        <div className={pageStyles.tableHeader}>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedFranchiseType(null);
              setShowFranchiseTypePanel(true);
            }}
          >
            Crear Tipo <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Prioridad</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {franchiseTypes.map((type) => (
                <Table.Row key={type.id}>
                  <Table.Cell className={pageStyles.boldText}>{type.name}</Table.Cell>
                  <Table.Cell>{type.priority}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openFranchiseTypePanel(type.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <FranchiseTypePanel
        visible={showFranchiseTypePanel}
        onClose={() => {
          setSelectedFranchiseType(null);
          setShowFranchiseTypePanel(false);
        }}
        franchiseTypeId={selectedFranchiseType}
      />
    </div>
  );
};
