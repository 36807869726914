import React, { FC, useCallback } from 'react';
import {
  createTechnicianGQL,
  deleteTechnicianGQL,
  technicianGQL,
  updateTechnicianGQL,
} from 'graphql/technician';
import { useMutation, useQuery } from '@apollo/client';
import { checkEmailAvailavilityGQL } from 'graphql/auth';
import { TechnicianPanel as Component } from './technician-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  technicianId: string;
};

export const TechnicianPanel: FC<ComponentProps> = ({ visible, onClose, technicianId }) => {
  const { data } = useQuery<{ technician: Technician }>(technicianGQL, {
    variables: { technicianId },
    skip: technicianId === null,
  });

  const [createTechnicianMutation] = useMutation(createTechnicianGQL);
  const [updateTechnicianMutation] = useMutation(updateTechnicianGQL);
  const [deleteTechnicianMutation, { loading }] = useMutation(deleteTechnicianGQL);
  const [checkEmailAvailabilityMutation] = useMutation<{ emailAvailability: EmailAvailability }>(
    checkEmailAvailavilityGQL,
  );

  const createTechnician = useCallback(
    async (technicianData: Technician) => {
      await createTechnicianMutation({ variables: { technicianData } });
    },
    [createTechnicianMutation],
  );
  const updateTechnician = useCallback(
    async (technicianData: Technician) => {
      await updateTechnicianMutation({ variables: { technicianId, technicianData } });
    },
    [technicianId, updateTechnicianMutation],
  );
  const deleteTechnician = useCallback(async () => {
    await deleteTechnicianMutation({ variables: { technicianId } });
  }, [technicianId, deleteTechnicianMutation]);

  const checkEmailAvailability = useCallback(
    (email: string) => {
      return checkEmailAvailabilityMutation({ variables: { email } }).then(
        (response) => response.data.emailAvailability,
      );
    },
    [checkEmailAvailabilityMutation],
  );

  return (
    <Component
      visible={visible}
      onClose={onClose}
      technician={data?.technician ?? null}
      updateTechnician={updateTechnician}
      createTechnician={createTechnician}
      deleteTechnician={deleteTechnician}
      deletingTechnician={loading}
      checkEmailAvailavility={checkEmailAvailability}
    />
  );
};
