import React, { FC, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { loginMutation } from 'graphql/auth';
import { useMutation } from '@apollo/client';

import { Login as Component } from './login';

export const LoginPage: FC<RouteComponentProps> = ({ history }) => {
  const [login, { loading: logingUser, error }] = useMutation(loginMutation);

  const loginUser = useCallback(
    (username: string, password: string) => {
      login({ variables: { username, password } })
        .then(() => {
          history.push('/');
        })
        .catch((e) => console.log(e));
    },
    [login, history],
  );

  const openRestorePassword = useCallback(() => {
    history.push('/restorepassword');
  }, [history]);

  console.log('Error', error?.message);

  return (
    <Component
      loginUser={loginUser}
      isLogingUser={logingUser}
      openRestorePassword={openRestorePassword}
      error={error?.message}
    />
  );
};
