import React, { FC, useMemo } from 'react';
import { Form } from 'semantic-ui-react';
import { TodayRounded } from '@material-ui/icons';
import DatePicker from 'react-datepicker';
import './date-picker-field.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { ErrorMessage, FastFieldProps } from 'formik';
import moment from 'moment';
import styles from '../input-field/input-field.module.scss';

type ComponentProps = {
  name: string;
  label?: string;
  placeholder?: string;
} & FastFieldProps;

export const DatePickerField: FC<ComponentProps> = ({ field, form, label, placeholder }) => {
  const formatedDate = useMemo(() => {
    return moment(field.value).format('DD/MM/YYYY');
  }, [field]);

  return (
    <div className="date-picker-field">
      <Form.Input
        className="date-input"
        label={label}
        placeholder={placeholder}
        value={formatedDate}
        disabled
      />
      <DatePicker
        selected={field.value}
        onChange={(date) => form.setFieldValue(field.name, date)}
        popperPlacement="bottom-end"
        minDate={moment().toDate()}
        customInput={
          <TodayRounded
            fontSize="default"
            className="calendar-icon"
            style={{ marginTop: label ? 31 : 0, cursor: 'pointer' }}
          />
        }
      />
      {form.errors[field.name] && form.touched[field.name] && (
        <span className={styles.errorMessage}>
          <ErrorMessage name={field.name} />
        </span>
      )}
    </div>
  );
};

DatePickerField.defaultProps = {
  label: null,
  placeholder: '',
};
