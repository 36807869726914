import React, { FC } from 'react';
import {
  customersGQL,
  customerCreatedGQL,
  customerUpdatedGQL,
  customerDeletedGQL,
} from 'graphql/customer';

import { useQuery, useSubscription } from '@apollo/client';
import { usePagination } from 'utils/hooks/pagination';
import { CommerceUnitsPage as Component } from './commerces';

export const CommerceUnitsPage: FC = () => {
  const { data, loading, refetch } = useQuery<
    { customers: PaginatedEntityList<Customer> },
    PaginatedVariables
  >(customersGQL, { variables: { pageSize: parseFloat(process.env.REACT_APP_TABLE_PAGE_SIZE) } });

  const { search, changePage } = usePagination(refetch);

  useSubscription(customerUpdatedGQL);
  useSubscription(customerCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(customerDeletedGQL, { onSubscriptionData: () => refetch() });

  return (
    <Component
      commerces={data?.customers.data ?? []}
      pagination={
        loading
          ? null
          : { pageNumber: data?.customers.pageNumber, totalPages: data?.customers.totalPages }
      }
      onSearch={search}
      onPageChange={changePage}
    />
  );
};
