import React, { FC, useCallback, useState } from 'react';
import pageStyles from 'assets/styles/page.module.scss';
import { Button, Input, Pagination, Table } from 'semantic-ui-react';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import { ServicePanel } from './service-panel';
import moment from 'moment';

export type ComponentProps = {
  services: Service[];
  pagination: PaginationProps;
  onPageChange: (page: number) => void;
  onSearch: (value: string) => void;
};

export const ServicesList: FC<ComponentProps> = ({
  services,
  pagination,
  onPageChange,
  onSearch,
}) => {
  const [showServicePanel, setShowServicePanel] = useState(false);
  const [selectedService, setSelectedService] = useState<string>(null);

  const openeServicePanel = useCallback((serviceId: string) => {
    setSelectedService(serviceId);
    setShowServicePanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Servicios</div>
        <div className={pageStyles.tableHeader}>
          <div className={pageStyles.controls}>
            <Input
              icon="search"
              placeholder="Buscar"
              className={pageStyles.searchInput}
              onChange={(_, data) => onSearch(data.value)}
            />
          </div>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedService(null);
              setShowServicePanel(true);
            }}
          >
            Crear Servicio <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Número de caso</Table.HeaderCell>
                <Table.HeaderCell>Usuario que recibe</Table.HeaderCell>
                <Table.HeaderCell>Técnico Asignado</Table.HeaderCell>
                <Table.HeaderCell>Estado</Table.HeaderCell>
                <Table.HeaderCell>Fecha de atención</Table.HeaderCell>
                <Table.HeaderCell>Comercio</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {services.map((service) => (
                <Table.Row key={service.id}>
                  <Table.Cell>{service.caseNumber ?? `S${service.id.padStart(6, '0')}`}</Table.Cell>
                  <Table.Cell className={pageStyles.tableColumn}>
                    {service.assignedUser &&
                      `${service.assignedUser?.firstName} ${service.assignedUser?.lastName}`}
                  </Table.Cell>
                  <Table.Cell>
                    {service.technician &&
                      `${service.technician?.firstName} ${service.technician?.lastName}`}
                  </Table.Cell>
                  <Table.Cell>{service.state?.name}</Table.Cell>
                  <Table.Cell>
                    {moment(service.scheduledTime).format('YYYY-MM-DD HH:ss:mm')}
                  </Table.Cell>
                  <Table.Cell>{service.franchise?.name}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openeServicePanel(service.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        {pagination && (
          <div className={pageStyles.paginationContainer}>
            <Pagination
              totalPages={pagination.totalPages}
              activePage={pagination.pageNumber}
              firstItem={null}
              lastItem={null}
              onPageChange={(event, data) => onPageChange(data.activePage as number)}
            />
          </div>
        )}
      </div>

      <ServicePanel
        serviceId={selectedService}
        visible={showServicePanel}
        onClose={() => {
          setSelectedService(null);
          setShowServicePanel(false);
        }}
      />
    </div>
  );
};
