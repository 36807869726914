import { gql } from '@apollo/client';

export const allServiceReasonsGQL = gql`
  query allServiceReasons {
    serviceReasons: allServiceReasons {
      id
      name
      maintenance
    }
  }
`;

export const serviceReasonGQL = gql`
  query serviceReason($serviceReasonId: String!) {
    serviceReason(serviceReasonId: $serviceReasonId) {
      id
      name
      maintenance
    }
  }
`;

export const createServiceReasonGQL = gql`
  mutation createServiceReason($serviceReasonData: ServiceReasonInputType!) {
    createServiceReason(serviceReasonData: $serviceReasonData) {
      id
      name
      maintenance
    }
  }
`;

export const updateServiceReasonGQL = gql`
  mutation updateServiceReason(
    $serviceReasonId: String!
    $serviceReasonData: ServiceReasonInputType!
  ) {
    updateServiceReason(serviceReasonId: $serviceReasonId, serviceReasonData: $serviceReasonData) {
      message
      entityId
    }
  }
`;

export const deleteServiceReasonGQL = gql`
  mutation deleteServiceReason($serviceReasonId: String!) {
    deleteServiceReason(serviceReasonId: $serviceReasonId) {
      message
      entityId
    }
  }
`;

export const serviceReasonCreatedGQL = gql`
  subscription serviceReasonCreated {
    serviceReasonCreated {
      id
      name
      maintenance
    }
  }
`;

export const serviceReasonUpdatedGQL = gql`
  subscription serviceReasonUpdated {
    serviceReasonUpdated {
      id
      name
      maintenance
    }
  }
`;

export const serviceReasonDeletedGQL = gql`
  subscription serviceReasonDeleted {
    serviceReasonDeleted {
      message
      entityId
    }
  }
`;
