import { gql } from '@apollo/client';

export const allDemosGQL = gql`
  query allDemos {
    demos: allDemos {
      id
      videoFileUrl
      demoType {
        id
        name
      }
      deviceReference {
        id
        name
      }
    }
  }
`;

export const demoGQL = gql`
  query demo($demoId: String!) {
    demo(demoId: $demoId) {
      id
      videoFileUrl
      demoType {
        id
        name
      }
      deviceReference {
        id
        name
      }
    }
  }
`;

export const createDemoGQL = gql`
  mutation createDemo($demoData: DemoInputType!) {
    createDemo(demoData: $demoData) {
      id
      videoFileUrl
      demoType {
        id
        name
      }
      deviceReference {
        id
        name
      }
    }
  }
`;

export const updateDemoGQL = gql`
  mutation updateDemo($demoId: String!, $demoData: DemoInputType!) {
    updateDemo(demoId: $demoId, demoData: $demoData) {
      message
      entityId
    }
  }
`;

export const deleteDemoGQL = gql`
  mutation deleteDemo($demoId: String!) {
    deleteDemo(demoId: $demoId) {
      message
      entityId
    }
  }
`;

export const demoCreatedGQL = gql`
  subscription demoCreated {
    demoCreated {
      id
      videoFileUrl
      demoType {
        id
        name
      }
      deviceReference {
        id
        name
      }
    }
  }
`;

export const demoUpdatedGQL = gql`
  subscription demoUpdated {
    demoUpdated {
      id
      videoFileUrl
      demoType {
        id
        name
      }
      deviceReference {
        id
        name
      }
    }
  }
`;

export const demoDeletedGQL = gql`
  subscription demoDeleted {
    demoDeleted {
      message
      entityId
    }
  }
`;
