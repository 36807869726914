import React, { FC, useCallback } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
  pendingChatsGQL,
  chatCreatedGQL,
  serviceUserActiveChatGQL,
  initChatGQl,
  finishChatGQl,
  chatEnabledGQL,
  chatFinishedGQL,
} from 'graphql/chat';
import { ServiceChat as Component } from './service-chat';
import { sessionUserGQL } from '../../../graphql/auth';

export const ServiceChat: FC = () => {
  const { data: sessionUserResponse, loading } = useQuery<{ sessionUser: User }>(sessionUserGQL);
  const { data: chatResponse, refetch: refetchActiveChat } = useQuery<{ chat: Chat }>(
    serviceUserActiveChatGQL,
    {
      variables: { serviceUserId: sessionUserResponse?.sessionUser.id },
      skip: loading,
    },
  );
  const { data, refetch } = useQuery<{ pendingChats: Chat[] }>(pendingChatsGQL);

  useSubscription(chatEnabledGQL, {
    onSubscriptionData: () => {
      refetch();
      refetchActiveChat();
    },
  });

  useSubscription(chatFinishedGQL, {
    onSubscriptionData: () => {
      refetch();
      refetchActiveChat();
    },
  });

  const [initChatMutation] = useMutation(initChatGQl);
  const [finishChatMutation] = useMutation(finishChatGQl);

  const initChat = useCallback(
    async (chatId: string) => {
      await initChatMutation({
        variables: { chatId, serviceUserId: sessionUserResponse.sessionUser.id },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: pendingChatsGQL }],
      });

      await refetchActiveChat();
    },
    [initChatMutation, sessionUserResponse, refetchActiveChat],
  );

  const finishChat = useCallback(
    async (chatId: string) => {
      await finishChatMutation({
        variables: { chatId },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: pendingChatsGQL }],
      });

      await refetchActiveChat();
    },
    [finishChatMutation, refetchActiveChat],
  );

  useSubscription(chatCreatedGQL, { onSubscriptionData: () => refetch() });

  return (
    <Component
      pendingChats={data?.pendingChats ?? []}
      activeChat={chatResponse?.chat ?? null}
      canInit={chatResponse?.chat === undefined || chatResponse?.chat === null}
      initChat={initChat}
      finishChat={finishChat}
    />
  );
};
