import React, { FC, useCallback, useMemo } from 'react';
import { allServiceStatesGQL } from 'graphql/service-state';
import { allServiceReasonsGQL } from 'graphql/service-reason';
import { allIssuesGQL, issueCreatedGQL, issueDeletedGQL } from 'graphql/issue';
import { allUsersGQL } from 'graphql/user';
import { allTechniciansGQL } from 'graphql/technician';
import { allFranchisesGQL } from 'graphql/franchise';
import { createServiceGQL, deleteServiceGQL, serviceGQL, updateServiceGQL } from 'graphql/services';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
  allSupplyRequestGQL,
  supplyRequestCreatedGQL,
  supplyRequestDeletedGQL,
} from 'graphql/supply-request';
import { ServicePanel as Component } from './service-panel';

export type ComponentProps = {
  serviceId: string;
} & PanelProps;

export const ServicePanel: FC<ComponentProps> = ({ serviceId, visible, onClose }) => {
  const { data: serviceResponse } = useQuery<{ service: Service }>(serviceGQL, {
    variables: { serviceId },
    skip: serviceId === null,
  });

  const { data: statesResponse } = useQuery<{ serviceStates: ServiceState[] }>(
    allServiceStatesGQL,
    { fetchPolicy: 'network-only' },
  );
  const { data: reasonsResponse } = useQuery<{ serviceReasons: ServiceReason[] }>(
    allServiceReasonsGQL,
    { fetchPolicy: 'network-only' },
  );
  const { data: issuesResponse, refetch: issuesRefetch, loading: loadingIssues } = useQuery<{
    issues: Issue[];
  }>(allIssuesGQL, {
    fetchPolicy: 'network-only',
  });

  const availableIssues = useMemo(() => {
    if (loadingIssues) return [];
    return issuesResponse.issues.filter(({ solved }) => !solved);
  }, [issuesResponse, loadingIssues]);

  useSubscription(issueCreatedGQL, { onSubscriptionData: () => issuesRefetch() });
  useSubscription(issueDeletedGQL, { onSubscriptionData: () => issuesRefetch() });

  const { data: usersResponse } = useQuery<{ users: User[] }>(allUsersGQL);
  const { data: techniciansResponse } = useQuery<{ technicians: Technician[] }>(allTechniciansGQL);
  const { data: franchisesResponse } = useQuery<{ franchises: Franchise[] }>(allFranchisesGQL);
  const { data: srResponse, refetch: srRefetch, loading: loadingsRequests } = useQuery<{
    supplyRequests: SupplyRequest[];
  }>(allSupplyRequestGQL, {
    fetchPolicy: 'network-only',
  });

  const availableSRequests = useMemo(() => {
    if (loadingsRequests) return [];
    return srResponse.supplyRequests.filter(({ delivered }) => !delivered);
  }, [srResponse, loadingsRequests]);

  useSubscription(supplyRequestCreatedGQL, { onSubscriptionData: () => srRefetch() });
  useSubscription(supplyRequestDeletedGQL, { onSubscriptionData: () => srRefetch() });

  const [createServiceMutation] = useMutation(createServiceGQL);
  const [updateServiceMutation] = useMutation(updateServiceGQL);
  const [deleteServiceMutation, { loading }] = useMutation(deleteServiceGQL);

  const createService = useCallback(
    async (serviceData: Service) => {
      await createServiceMutation({ variables: { serviceData } });
    },
    [createServiceMutation],
  );
  const updateService = useCallback(
    async (serviceData: Service) => {
      await updateServiceMutation({ variables: { serviceId, serviceData } });
    },
    [serviceId, updateServiceMutation],
  );
  const deleteService = useCallback(async () => {
    await deleteServiceMutation({ variables: { serviceId } });
  }, [serviceId, deleteServiceMutation]);

  return (
    <Component
      service={serviceResponse?.service ?? null}
      issues={serviceId === null ? availableIssues : issuesResponse?.issues ?? []}
      users={usersResponse?.users ?? []}
      technicians={techniciansResponse?.technicians ?? []}
      serviceStates={statesResponse?.serviceStates ?? []}
      visible={visible}
      onClose={onClose}
      createService={createService}
      updateService={updateService}
      deleteService={deleteService}
      deletingService={loading}
      supplyRequests={serviceId === null ? availableSRequests : srResponse?.supplyRequests ?? []}
      serviceReasons={reasonsResponse?.serviceReasons ?? []}
      franchises={franchisesResponse?.franchises ?? []}
    />
  );
};
