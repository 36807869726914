import { gql } from '@apollo/client';

export const customersGQL = gql`
  query customers($pageSize: Float, $pageNumber: Float, $searchTerm: String) {
    customers(pageSize: $pageSize, pageNumber: $pageNumber, searchTerm: $searchTerm) {
      data {
        id
        name
        uniqueCode
        imageUrl
      }
      pageNumber
      pageSize
      totalPages
    }
  }
`;

export const allCustomersGQL = gql`
  query allCustomers {
    customers: allCustomers {
      id
      name
      uniqueCode
      imageUrl
    }
  }
`;

export const customerGQL = gql`
  query customer($customerId: String!) {
    customer(customerId: $customerId) {
      id
      name
      uniqueCode
      imageUrl
    }
  }
`;

export const createCustomerGQL = gql`
  mutation createCustomer($customerData: CustomerInputType!) {
    createCustomer(customerData: $customerData) {
      id
      name
      uniqueCode
      imageUrl
    }
  }
`;

export const updateCustomerGQL = gql`
  mutation updateCustomer($customerData: CustomerInputType!, $customerId: String!) {
    updateCustomer(customerData: $customerData, customerId: $customerId) {
      message
      entityId
    }
  }
`;

export const deleteCustomerGQL = gql`
  mutation deleteCustomer($customerId: String!) {
    deleteCustomer(customerId: $customerId) {
      message
      entityId
    }
  }
`;

export const customerCreatedGQL = gql`
  subscription customerCreated {
    customerCreated {
      id
      name
      uniqueCode
      imageUrl
    }
  }
`;

export const customerUpdatedGQL = gql`
  subscription customerUpdated {
    customerUpdated {
      id
      name
      uniqueCode
      imageUrl
    }
  }
`;

export const customerDeletedGQL = gql`
  subscription customerDeleted {
    customerDeleted {
      message
      entityId
    }
  }
`;
