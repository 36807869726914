import React, { FC } from 'react';
import { ErrorMessage, FastFieldProps } from 'formik';
import { Form } from 'semantic-ui-react';

import styles from './input-field.module.scss';

export interface ComponentProps extends FastFieldProps {
  label: string;
  type?: string;
  placeholder?: string;
  className?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export const InputField: FC<ComponentProps> = ({
  field,
  form,
  label,
  type,
  placeholder,
  className,
  onChange,
  disabled,
}) => {
  const fieldMeta = form.getFieldMeta(field.name);
  return (
    <div className={`${styles.container} ${className || ''}`}>
      <Form.Input
        {...field}
        label={label}
        type={type}
        placeholder={placeholder}
        fluid
        onChange={(event, data) => {
          field.onChange(event);
          onChange(data.value);
        }}
        disabled={disabled}
      />
      {fieldMeta.error && fieldMeta.touched && (
        <span className={styles.errorMessage}>
          <ErrorMessage name={field.name} />
        </span>
      )}
    </div>
  );
};

InputField.defaultProps = {
  type: 'text',
  placeholder: '',
  className: null,
  onChange: () => {},
  disabled: false,
};
