import { gql } from '@apollo/client';

export const allServiceStatesGQL = gql`
  query allServiceStates {
    serviceStates: allServiceStates {
      id
      name
      key
    }
  }
`;

export const serviceStateGQL = gql`
  query serviceState($serviceStateId: String!) {
    serviceState(serviceStateId: $serviceStateId) {
      id
      name
      key
    }
  }
`;

export const createServiceStateGQL = gql`
  mutation createServiceState($serviceStateData: ServiceStateInputType!) {
    createServiceState(serviceStateData: $serviceStateData) {
      id
      name
      key
    }
  }
`;

export const updateServiceStateGQL = gql`
  mutation updateServiceState($serviceStateId: String!, $serviceStateData: ServiceStateInputType!) {
    updateServiceState(serviceStateId: $serviceStateId, serviceStateData: $serviceStateData) {
      message
      entityId
    }
  }
`;

export const deleteServiceStateGQL = gql`
  mutation deleteServiceState($serviceStateId: String!) {
    deleteServiceState(serviceStateId: $serviceStateId) {
      message
      entityId
    }
  }
`;

export const serviceStateCreatedGQL = gql`
  subscription serviceStateCreated {
    serviceStateCreated {
      id
      name
      key
    }
  }
`;

export const serviceStateUpdatedGQL = gql`
  subscription serviceStateUpdated {
    serviceStateUpdated {
      id
      name
      key
    }
  }
`;
export const serviceStateDeletedGQL = gql`
  subscription serviceStateDeleted {
    serviceStateDeleted {
      message
      entityId
    }
  }
`;
