import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { suppliesGQL, supplyCreatedGQL, supplyDeletedGQL, supplyUpdatedGQL } from 'graphql/supply';

import { SupplyUnitsPage as Component } from './supply-units';
import { usePagination } from '../../../utils/hooks/pagination';
import { serviceUpdatedGQL } from '../../../graphql/services';

export const SupplyUnitsPage: FC = () => {
  const { data, loading, refetch } = useQuery<
    { supplies: PaginatedEntityList<Supply> },
    PaginatedVariables
  >(suppliesGQL, {
    variables: { pageSize: parseFloat(process.env.REACT_APP_TABLE_PAGE_SIZE) },
  });

  const { search, changePage } = usePagination(refetch);

  useSubscription(supplyUpdatedGQL);
  useSubscription(supplyDeletedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(supplyCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(serviceUpdatedGQL, { onSubscriptionData: () => refetch() });

  return (
    <Component
      supplies={data?.supplies.data ?? []}
      pagination={
        loading
          ? null
          : { pageNumber: data?.supplies.pageNumber, totalPages: data?.supplies.totalPages }
      }
      onPageChange={changePage}
      onSearch={search}
    />
  );
};
