import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  allDeviceReferencesGQL,
  deviceReferenceCreatedGQL,
  deviceReferenceUpdatedGQL,
  deviceReferenceDeletedGQL,
} from 'graphql/device-reference';
import { DeviceReferencesPage as Component } from './device-references';

export const DeviceReferencesPage: FC = () => {
  const { data, refetch } = useQuery<{ deviceReferences: DeviceReference[] }>(
    allDeviceReferencesGQL,
  );
  useSubscription(deviceReferenceUpdatedGQL);
  useSubscription(deviceReferenceCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(deviceReferenceDeletedGQL, { onSubscriptionData: () => refetch() });

  return <Component deviceReferences={data?.deviceReferences ?? []} />;
};
