import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { allDemosGQL, demoDeletedGQL, demoCreatedGQL, demoUpdatedGQL } from 'graphql/demo-tutorial';
import { DemoTutorials as Component } from './demo-tutorials';

export const DemoTutorials: FC = () => {
  const { data, refetch } = useQuery<{ demos: DemoTutorial[] }>(allDemosGQL);
  useSubscription(demoUpdatedGQL);
  useSubscription(demoCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(demoDeletedGQL, { onSubscriptionData: () => refetch() });

  return <Component demos={data?.demos ?? []} />;
};
