import { gql } from '@apollo/client';

export const allDeviceTypesGQL = gql`
  query allDeviceTypes {
    deviceTypes: allDeviceTypes {
      id
      name
    }
  }
`;

export const deviceTypeGQL = gql`
  query Query($deviceTypeId: Float!) {
    deviceType(deviceTypeId: $deviceTypeId) {
      id
      name
    }
  }
`;

export const createDeviceTypeGQL = gql`
  mutation CreateDeviceTypeMutation($deviceType: DeviceTypeInputType!) {
    createDeviceType(deviceType: $deviceType) {
      id
      name
    }
  }
`;

export const updateDeviceTypeGQL = gql`
  mutation UpdateDeviceTypeMutation($deviceType: DeviceTypeInputType!, $deviceTypeId: String!) {
    updateDeviceType(deviceType: $deviceType, deviceTypeId: $deviceTypeId) {
      message
      entityId
    }
  }
`;

export const deleteDeviceTypeGQL = gql`
  mutation DeleteDeviceTypeMutation($deviceTypeId: Float!) {
    deleteDeviceType(deviceTypeId: $deviceTypeId)
  }
`;

export const deviceTypeCreatedGQL = gql`
  subscription deviceTypeCreated {
    deviceTypeCreated {
      id
      name
    }
  }
`;

export const deviceTypeUpdatedGQL = gql`
  subscription deviceTypeUpdated {
    deviceTypeUpdated {
      id
      name
    }
  }
`;

export const deviceTypeDeletedGQL = gql`
  subscription deviceTypeDeleted {
    deviceTypeDeleted {
      message
      entityId
    }
  }
`;
