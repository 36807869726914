import React, { ReactElement, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { LoginPage } from 'pages/login';
import './App.scss';
import 'assets/styles/semantic-theme.scss';
import { Header } from 'layout/header';
import { MainMenu } from 'layout/main-menu';

import { UsersPage } from 'pages/users';
import { TechniciansPage } from 'pages/technicians';
import { RequestsPage } from 'pages/requests';
import { CommercesPage } from 'pages/commerces';
import { DevicesPage } from 'pages/devices';
import { SuppliesPage } from 'pages/supplies';
import { ServicesPage } from 'pages/services';
import { createUploadLink } from 'apollo-upload-client';
import { DevicesCommercePage } from 'pages/device-commerce';
import { TutorialsPage } from 'pages/tutorials';
import { ChatPage } from 'pages/chat';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  split,
  fromPromise,
} from '@apollo/client';

import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { Cookies } from 'react-cookie';
import { onError } from '@apollo/client/link/error';
import { refreshTokenGQL } from 'graphql/auth';
import { PrivateRoute } from './auth/private-route';

const RestorePasswordPage = React.lazy(() => import('pages/restore-password'));
const ResetPasswordPage = React.lazy(() => import('pages/reset-password'));
const IssuesPage = React.lazy(() => import('pages/issues'));

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
  credentials: 'include',
});

const createWsLink = () => {
  const wsLink = new WebSocketLink(
    new SubscriptionClient(process.env.REACT_APP_GRAPHQL_SOCKET, {
      reconnect: true,
    }),
  );

  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );
};

const authMiddleware = new ApolloLink((operation, forward) => {
  const cookies = new Cookies();
  const userToken = cookies.get('rtcsession');

  operation.setContext({
    headers: {
      authorization: userToken ? `Bearer ${userToken}` : null,
    },
  });

  return forward(operation);
});

const errorlink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors)
    for (const err of graphQLErrors) {
      console.log(err);
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED': {
          if (err.path[0] !== 'login')
            return fromPromise(
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              client.mutate<{ refreshToken: string }>({ mutation: refreshTokenGQL }),
            ).flatMap(({ data }) => {
              if (data.refreshToken === '') {
                const cookies = new Cookies();
                cookies.remove('rtcsession', { path: '/' });
                window.location.replace('/login');
              } else {
                const oldHeaders = operation.getContext().headers;
                const cookies = new Cookies();

                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${cookies.get('rtcsession')}`,
                  },
                });
              }
              return forward(operation);
            });
          break;
        }
        default:
          // eslint-disable-next-line no-console
          console.error(err);
      }
    }

  if (networkError) console.log(`[Network error]: ${networkError}`);

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([authMiddleware, errorlink, createWsLink()]),
});

function App(): ReactElement {
  return (
    <div className="app">
      <ApolloProvider client={client}>
        <Suspense fallback={<div />}>
          <Router>
            <Switch>
              <Route path="/login" exact component={LoginPage} />
              <Route path="/restorepassword" exact component={RestorePasswordPage} />
              <Route path="/resetpassword" exact component={ResetPasswordPage} />
              <PrivateRoute path="/">
                <div className="layout">
                  <div className="header-section">
                    <Header />
                  </div>
                  <div className="menu-section">
                    <MainMenu />
                  </div>
                  <div className="content-section">
                    <Switch>
                      <Route path="/" exact>
                        <Redirect to="/users" />
                      </Route>
                      <Route path="/users">
                        <UsersPage />
                      </Route>
                      <Route path="/requests">
                        <RequestsPage />
                      </Route>
                      <Route path="/services">
                        <ServicesPage />
                      </Route>
                      <Route path="/issues">
                        <IssuesPage />
                      </Route>
                      <Route path="/commerces">
                        <CommercesPage />
                      </Route>
                      <Route path="/devices">
                        <DevicesPage />
                      </Route>
                      <Route path="/tutorials">
                        <TutorialsPage />
                      </Route>
                      <Route path="/supplies">
                        <SuppliesPage />
                      </Route>
                      <Route path="/technicians">
                        <TechniciansPage />
                      </Route>
                      <Route path="/device-commerce">
                        <DevicesCommercePage />
                      </Route>
                      <Route path="/chat">
                        <ChatPage />
                      </Route>
                    </Switch>
                  </div>
                </div>
              </PrivateRoute>
            </Switch>
          </Router>
        </Suspense>
      </ApolloProvider>
    </div>
  );
}

export default App;
