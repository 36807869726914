import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  allSupplyTypesGQL,
  supplyTypeCreatedGQL,
  supplyTypeDeletedGQL,
  supplyTypeUpdatedGQL,
} from 'graphql/supply-type';

import { SupplyTypesPage as Component } from './supply-types';

export const SupplyTypesPage: FC = () => {
  const { data, refetch } = useQuery<{ supplyTypes: SupplyType[] }>(allSupplyTypesGQL);

  useSubscription(supplyTypeUpdatedGQL);
  useSubscription(supplyTypeCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(supplyTypeDeletedGQL, { onSubscriptionData: () => refetch() });

  return <Component supplyTypes={data?.supplyTypes ?? []} />;
};
