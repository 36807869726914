import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Confirm, Form, Icon } from 'semantic-ui-react';
import * as Yup from 'yup';
import { ValidationMessages } from 'utils/validation-messages';
import { FormikHelpers, FormikProps, Formik, FastField } from 'formik';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  franchiseType: FranchiseType;
  updateFranchiseType: (franchiseData: FranchiseType) => Promise<void>;
  createFranchiseType: (franchiseData: FranchiseType) => Promise<void>;
  deleteFranchiseType: () => Promise<void>;
  deletingFranchiseType: boolean;
};

type FormValues = {
  name: string;
  priority: number;
};

const initialValues: FormValues = {
  name: '',
  priority: null,
};

const validationSchema = Yup.object().shape<FormValues>({
  name: Yup.string().required(ValidationMessages.required),
  priority: Yup.number().nullable().required(ValidationMessages.required),
});

export const FranchiseTypePanel: FC<ComponentProps> = ({
  visible,
  onClose,
  franchiseType,
  createFranchiseType,
  deleteFranchiseType,
  updateFranchiseType,
  deletingFranchiseType,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      values.priority = parseFloat(values.priority as any);
      if (franchiseType) updateFranchiseType(values).then(onClose);
      else createFranchiseType(values).then(onClose);
    },
    [updateFranchiseType, createFranchiseType, franchiseType, onClose],
  );

  useEffect(() => {
    if (franchiseType) {
      formRef.current.setFieldValue('name', franchiseType.name);
      formRef.current.setFieldValue('priority', franchiseType.priority);
    }
  }, [franchiseType]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">{franchiseType?.name ?? 'Crear Tipo'}</div>
      {franchiseType && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingFranchiseType}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar el permiso seleccionado?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteFranchiseType().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, isSubmitting }: FormikProps<FormValues>) => (
          <Form onSubmit={handleSubmit} className="panel-form">
            <FastField
              name="name"
              label="Nombre"
              component={InputField}
              value={values.name}
              placeholder="Nombre del permiso"
            />
            <FastField
              name="priority"
              label="Prioridad"
              component={InputField}
              value={values.priority}
              placeholder="Prioridad"
            />
            <div className="buttons-container">
              <Button size="medium" icon onClick={onClose} type="button">
                Cancelar &nbsp;
                <Icon name="times" />
              </Button>
              <Button primary size="medium" icon loading={isSubmitting}>
                Guardar &nbsp;
                <Icon name="save" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </SidePanel>
  );
};
