import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, Form, Icon } from 'semantic-ui-react';

interface ComponentProps {
  visible: boolean;
  onClose: () => void;
  serviceNoveltyType: ServiceNoveltyType;
  updateServiceNoveltyType: (ServiceNoveltyType: ServiceNoveltyType) => Promise<void>;
  createServiceNoveltyType: (ServiceNoveltyType: ServiceNoveltyType) => Promise<void>;
  deleteServiceNoveltyType: () => Promise<void>;
  deletingServiceNoveltyType: boolean;
}

interface FormValues {
  name: string;
  description: string;
}

const initialValues: FormValues = {
  name: '',
  description: '',
};

export const ServiceNoveltyTypePanel: FC<ComponentProps> = ({
  visible,
  onClose,
  serviceNoveltyType,
  updateServiceNoveltyType,
  createServiceNoveltyType,
  deleteServiceNoveltyType,
  deletingServiceNoveltyType,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      if (serviceNoveltyType) updateServiceNoveltyType(values).then(onClose);
      else createServiceNoveltyType(values).then(onClose);
    },
    [serviceNoveltyType, updateServiceNoveltyType, createServiceNoveltyType],
  );

  useEffect(() => {
    if (serviceNoveltyType) {
      formRef.current.setValues({
        name: serviceNoveltyType.name,
        description: serviceNoveltyType.description,
      });
    }
  }, [serviceNoveltyType]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">{serviceNoveltyType?.name ?? 'Crear Tipo de Novedad'}</div>
      {serviceNoveltyType && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingServiceNoveltyType}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar el tipo de novedad seleccionado?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteServiceNoveltyType().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit} innerRef={formRef}>
        {({ handleSubmit, values, isSubmitting }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <FastField
                name="name"
                label="Nombre"
                component={InputField}
                value={values.name}
                placeholder="Nombre"
              />
              <FastField
                name="description"
                label="Descripción"
                component={InputField}
                value={values.description}
                placeholder="Descripción tipo de novedad"
              />
              <div className="buttons-container">
                <Button size="large" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="large" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
