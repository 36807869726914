import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { SelectorField } from 'components/selector-field';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, DropdownItemProps, Form, Icon } from 'semantic-ui-react';
import * as Yup from 'yup';
import { ValidationMessages } from 'utils/validation-messages';

interface ComponentProps {
  visible: boolean;
  onClose: () => void;
  supplyUnits: Supply;
  updateSupplyUnits: (supplyUnits: Supply) => Promise<void>;
  createSupplyUnits: (supplyUnits: Supply) => Promise<void>;
  deleteSupplyUnits: () => Promise<void>;
  deletingSupplyUnits: boolean;
  supplyTypes: SupplyType[];
}

interface FormValues {
  name: string;
  stockAmount: number;
  supplyTypeId: number;
}

const initialValues: FormValues = {
  name: '',
  stockAmount: null,
  supplyTypeId: null,
};

const validationSchema = Yup.object().shape<FormValues>({
  name: Yup.string().required(ValidationMessages.required),
  stockAmount: Yup.number().nullable().required(ValidationMessages.required),
  supplyTypeId: Yup.number().nullable(),
});

export const SupplyUnitsPanel: FC<ComponentProps> = ({
  visible,
  onClose,
  supplyUnits,
  updateSupplyUnits,
  createSupplyUnits,
  deleteSupplyUnits,
  deletingSupplyUnits,
  supplyTypes,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);

  const supplyTypeOptions = useMemo<DropdownItemProps[]>(() => {
    return supplyTypes.map((sType) => ({
      key: sType.id,
      text: sType.name,
      value: sType.id,
    }));
  }, [supplyTypes]);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      values.stockAmount = parseFloat(values.stockAmount as any);
      if (supplyUnits) updateSupplyUnits(values).then(onClose);
      else createSupplyUnits(values).then(onClose);
    },
    [supplyUnits, updateSupplyUnits, createSupplyUnits, onClose],
  );

  useEffect(() => {
    if (supplyUnits) {
      formRef.current.setFieldValue('name', supplyUnits.name);
      formRef.current.setFieldValue('stockAmount', supplyUnits.stockAmount);
      formRef.current.setFieldValue('supplyTypeId', supplyUnits.supplyType?.id ?? null);
    }
  }, [supplyUnits]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">{supplyUnits?.name ?? 'Crear Suministro'}</div>
      {supplyUnits && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingSupplyUnits}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar el suministro seleccionado?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteSupplyUnits().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, isSubmitting }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <FastField
                name="name"
                label="Nombre"
                component={InputField}
                value={values.name}
                placeholder="Nombre del suministro"
              />
              <FastField
                name="stockAmount"
                label="Cantidad"
                component={InputField}
                value={values.stockAmount}
                placeholder="Cantidad en inventario"
              />
              <FastField
                name="supplyTypeId"
                label="Tipo"
                component={SelectorField}
                value={values.supplyTypeId}
                placeholder="Seleccione un tipo"
                options={supplyTypeOptions}
              />
              <div className="buttons-container">
                <Button size="medium" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="medium" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
