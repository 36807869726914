import React, { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';

import styles from './menu-item.module.scss';

export type SubItem = {
  path: string;
  name: string;
  icon: React.ReactElement;
  hidden?: boolean;
};

export type ComponentProps = {
  active: boolean;
  icon: React.ReactElement;
  title: string;
  onSelect: () => void;
  subItems: SubItem[];
};

export const MenuItem: FC<ComponentProps> = ({ active, title, icon, onSelect, subItems }) => {
  const showItem = useMemo(() => subItems.some(({ hidden }) => !hidden), [subItems]);

  return showItem ? (
    <div className={styles.container}>
      <div
        className={`${styles.item} ${active ? styles.active : ''}`}
        onClick={onSelect}
        aria-hidden
      >
        <div className={styles.itemIcon}>{icon}</div>
        <div className={styles.itenName}>{title}</div>
        <div className={styles.ecIcon}>
          {active ? <KeyboardArrowDownOutlinedIcon /> : <ChevronRightOutlinedIcon />}
        </div>
      </div>
      <div className={styles.submenu} hidden={!active}>
        {subItems.map((item) =>
          item.hidden ? null : (
            <NavLink
              to={item.path}
              className={styles.subItem}
              activeClassName={styles.selected}
              key={item.path}
            >
              <div className={styles.subItemIcon}>{item.icon}</div>
              <div className={styles.subItenName}>{item.name}</div>
            </NavLink>
          ),
        )}
      </div>
    </div>
  ) : null;
};
