import React, { FC, useCallback, useState } from 'react';
import pageStyles from 'assets/styles/page.module.scss';
import { Button, Input, Pagination, Table } from 'semantic-ui-react';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import { FranchisePanel } from './franchises-panel';
import { AddressModal } from '../../../components/address-modal';

export type ComponentProps = {
  franchises: Franchise[];
  pagination: PaginationProps;
  onSearch: (value: string) => void;
  onPageChange: (page: number) => void;
};

export const FranchisesPage: FC<ComponentProps> = ({
  franchises,
  pagination,
  onSearch,
  onPageChange,
}) => {
  const [showFranchisenPanel, setShowFranchisePanel] = useState(false);
  const [selectedFranchise, setSelectedFranchise] = useState<string>(null);

  const openFranchisePanel = useCallback((FranchiseId: string) => {
    setSelectedFranchise(FranchiseId);
    setShowFranchisePanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Sedes</div>
        <div className={pageStyles.tableHeader}>
          <div className={pageStyles.controls}>
            <Input
              icon="search"
              placeholder="Buscar"
              className={pageStyles.searchInput}
              onChange={(_, data) => onSearch(data.value)}
            />
          </div>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedFranchise(null);
              setShowFranchisePanel(true);
            }}
          >
            Crear Sede <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Dirección</Table.HeaderCell>
                <Table.HeaderCell>Código Único</Table.HeaderCell>
                <Table.HeaderCell>Tipo de Sede</Table.HeaderCell>
                <Table.HeaderCell>Comercio</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {franchises.map((franchise) => (
                <Table.Row key={franchise.id}>
                  <Table.Cell width={2} className={pageStyles.boldText}>
                    {franchise.name}
                  </Table.Cell>
                  <Table.Cell width={2}>{franchise.address}</Table.Cell>
                  <Table.Cell width={2}>{franchise.uniqueCode}</Table.Cell>
                  <Table.Cell width={2}>{franchise.franchiseType?.name}</Table.Cell>
                  <Table.Cell width={2}>{franchise.customer?.name}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openFranchisePanel(franchise.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <FranchisePanel
        visible={showFranchisenPanel}
        onClose={() => {
          setSelectedFranchise(null);
          setShowFranchisePanel(false);
        }}
        franchiseId={selectedFranchise}
      />
      {pagination && (
        <div className={pageStyles.paginationContainer}>
          <Pagination
            totalPages={pagination.totalPages}
            activePage={pagination.pageNumber}
            firstItem={null}
            lastItem={null}
            onPageChange={(event, data) => onPageChange(data.activePage as number)}
          />
        </div>
      )}
    </div>
  );
};
