import React, { FC, useCallback, useState, useEffect } from 'react';
import pageStyles from 'assets/styles/page.module.scss';
import './supply-requests.scss';
import { Button, Input, Pagination, Table } from 'semantic-ui-react';
import { PlusCircle } from 'react-feather';
import { SupplyRequestPanel } from './supply-request-panel';
import { Subject } from 'rxjs';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import moment from 'moment';

export type ComponentProps = {
  supplyRequests: SupplyRequest[];
  pagination: PaginationProps;
  onPageChange: (page: number) => void;
  onSearch: (value: string) => void;
};

export const SupplyRequestsPage: FC<ComponentProps> = ({
  supplyRequests,
  pagination,
  onPageChange,
  onSearch,
}) => {
  const [showSupplyRequestPanel, setShowSupplyRequestPanel] = useState(false);
  const [selectedSupplyRequest, setSelectedSupplyRequest] = useState<string>(null);

  const openSupplyRequestPanel = useCallback((supplyRequestId: string) => {
    setSelectedSupplyRequest(supplyRequestId);
    setShowSupplyRequestPanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Solicitudes</div>
        <div className={pageStyles.tableHeader}>
          <div className={pageStyles.controls}>
            <Input
              icon="search"
              placeholder="Buscar"
              className={pageStyles.searchInput}
              onChange={(_, data) => onSearch(data.value)}
            />
          </div>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedSupplyRequest(null);
              setShowSupplyRequestPanel(true);
            }}
          >
            Crear Solicitud <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Fecha de Creación</Table.HeaderCell>
                <Table.HeaderCell>Franquicia</Table.HeaderCell>
                <Table.HeaderCell>Entregado</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {supplyRequests.map((supplyrequest) => (
                <Table.Row key={supplyrequest.id}>
                  <Table.Cell>
                    {supplyrequest.idLabel ?? `SR${supplyrequest.id.padStart(6, '0')}`}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(supplyrequest.creationDate).format('DD/MM/YYYY HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>{supplyrequest.franchise?.name ?? ''}</Table.Cell>
                  <Table.Cell>{supplyrequest.delivered ? 'Si' : 'No'}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openSupplyRequestPanel(supplyrequest.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        {pagination && (
          <div className={pageStyles.paginationContainer}>
            <Pagination
              totalPages={pagination.totalPages}
              activePage={pagination.pageNumber}
              firstItem={null}
              lastItem={null}
              onPageChange={(event, data) => onPageChange(data.activePage as number)}
            />
          </div>
        )}
      </div>

      <SupplyRequestPanel
        visible={showSupplyRequestPanel}
        onClose={() => {
          setSelectedSupplyRequest(null);
          setShowSupplyRequestPanel(false);
        }}
        supplyRequestId={selectedSupplyRequest}
      />
    </div>
  );
};
