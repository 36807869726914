import React, { FC } from 'react';
import {
  serviceCreatedGQL,
  serviceDeletedGQL,
  servicesGQL,
  serviceUpdatedGQL,
} from 'graphql/services';
import { useQuery, useSubscription } from '@apollo/client';
import { ServicesList as Component } from './services-list';
import { usePagination } from '../../../utils/hooks/pagination';

export const ServicesList: FC = () => {
  const { data, loading, refetch } = useQuery<
    { services: PaginatedEntityList<Service> },
    PaginatedVariables
  >(servicesGQL, {
    variables: { pageSize: parseFloat(process.env.REACT_APP_TABLE_PAGE_SIZE) },
  });

  const { search, changePage } = usePagination(refetch);
  useSubscription(serviceUpdatedGQL);
  useSubscription(serviceCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(serviceDeletedGQL, { onSubscriptionData: () => refetch() });

  return (
    <Component
      services={data?.services.data ?? []}
      pagination={
        loading
          ? null
          : { pageNumber: data?.services.pageNumber, totalPages: data?.services.totalPages }
      }
      onPageChange={changePage}
      onSearch={search}
    />
  );
};
