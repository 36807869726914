import { gql } from '@apollo/client';

export const devicesGQL = gql`
  query devices($pageSize: Float, $pageNumber: Float, $searchTerm: String) {
    devices(pageSize: $pageSize, pageNumber: $pageNumber, searchTerm: $searchTerm) {
      data {
        id
        pinPadCode
        plateNumber
        securityCode
        serial
        deviceReference {
          id
          name
          imageUrl
          deviceType {
            id
            name
          }
        }
        franchise {
          id
          name
          customer {
            id
            name
          }
        }
      }
      pageSize
      pageNumber
      totalPages
    }
  }
`;

export const allDevicesGQL = gql`
  query allDevices {
    devices: allDevices {
      id
      pinPadCode
      plateNumber
      securityCode
      serial
      deviceReference {
        id
        name
        imageUrl
      }
      franchise {
        id
        name
        customer {
          id
          name
        }
      }
    }
  }
`;

export const deviceGQL = gql`
  query device($deviceId: String!) {
    device(deviceId: $deviceId) {
      id
      pinPadCode
      plateNumber
      securityCode
      serial
      deviceReference {
        id
        name
        imageUrl
      }
      franchise {
        id
        name
      }
    }
  }
`;

export const createDeviceGQL = gql`
  mutation createDevice($deviceData: DeviceInputType!) {
    createDevice(deviceData: $deviceData) {
      id
      pinPadCode
      plateNumber
      securityCode
      serial
      deviceReference {
        id
        name
        imageUrl
      }
      franchise {
        id
        name
        customer {
          id
          name
        }
      }
    }
  }
`;

export const updateDeviceGQL = gql`
  mutation updateDevice($deviceData: DeviceInputType!, $deviceId: String!) {
    updateDevice(deviceData: $deviceData, deviceId: $deviceId) {
      message
      entityId
    }
  }
`;

export const deleteDeviceGQL = gql`
  mutation deleteDevice($deviceId: String!) {
    deleteDevice(deviceId: $deviceId) {
      message
      entityId
    }
  }
`;

export const deviceCreatedGQL = gql`
  subscription deviceCreated {
    deviceCreated {
      id
      pinPadCode
      plateNumber
      securityCode
      serial
      deviceReference {
        id
        name
        imageUrl
      }
      franchise {
        id
        name
        customer {
          id
          name
        }
      }
    }
  }
`;

export const deviceUpdatedGQL = gql`
  subscription deviceUpdated {
    deviceUpdated {
      id
      pinPadCode
      plateNumber
      serial
      securityCode
      deviceReference {
        id
        name
        imageUrl
      }
      franchise {
        id
        name
        customer {
          id
          name
        }
      }
    }
  }
`;

export const deviceDeletedGQL = gql`
  subscription deviceDeleted {
    deviceDeleted {
      message
      entityId
    }
  }
`;
