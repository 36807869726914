import React, { FC, useCallback } from 'react';
import { allCustomersGQL } from 'graphql/customer';
import { allFranchiseTypesGQL } from 'graphql/franchise-type';
import { useMutation, useQuery } from '@apollo/client';
import {
  franchiseGQL,
  createFranchiseGQL,
  updateFranchiseGQL,
  deleteFranchiseGQL,
} from 'graphql/franchise';

import { franchiseAvailableUsersGQL } from 'graphql/user';

import { FranchisePanel as Component } from './franchises-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  franchiseId: string;
};

export const FranchisePanel: FC<ComponentProps> = ({ franchiseId, onClose, visible }) => {
  const { data: customersResponse } = useQuery<{ customers: Customer[] }>(allCustomersGQL, {
    fetchPolicy: 'network-only',
  });

  const { data: fTypesResponse } = useQuery<{ franchiseTypes: FranchiseType[] }>(
    allFranchiseTypesGQL,
  );
  const { data: franchiseResponse } = useQuery<{ franchise: Franchise }>(franchiseGQL, {
    variables: { franchiseId },
    skip: franchiseId === null,
  });

  const { data: usersResponse } = useQuery<{ users: User[] }>(franchiseAvailableUsersGQL, {
    variables: { franchiseId },
    skip: franchiseId === null,
  });

  const [createFranchiseMutation] = useMutation(createFranchiseGQL);
  const [updateFranchiseMutation] = useMutation(updateFranchiseGQL);
  const [deleteFranchiseMutation, { loading }] = useMutation(deleteFranchiseGQL);

  const createFranchise = useCallback(
    async (franchiseData: Franchise) => {
      await createFranchiseMutation({ variables: { franchiseData } });
    },
    [createFranchiseMutation],
  );
  const updateFranchise = useCallback(
    async (franchiseData: Franchise) => {
      await updateFranchiseMutation({ variables: { franchiseId, franchiseData } });
    },
    [franchiseId, updateFranchiseMutation],
  );
  const deleteFranchise = useCallback(async () => {
    await deleteFranchiseMutation({ variables: { franchiseId } });
  }, [franchiseId, deleteFranchiseMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      franchise={franchiseResponse?.franchise ?? null}
      updateFranchise={updateFranchise}
      createFranchise={createFranchise}
      deleteFranchise={deleteFranchise}
      deletingFranchise={loading}
      customers={customersResponse?.customers ?? []}
      franchiseTypes={fTypesResponse?.franchiseTypes ?? []}
      users={usersResponse?.users ?? []}
    />
  );
};
