import { gql } from '@apollo/client';

export const permissionsQueryGQL = gql`
  query permissions($pageSize: Float, $pageNumber: Float, $searchTerm: String) {
    permissions(pageSize: $pageSize, pageNumber: $pageNumber, searchTerm: $searchTerm) {
      data {
        id
        name
        key
        description
      }
      pageSize
      pageNumber
      totalPages
    }
  }
`;

export const allPermissionsQueryGQL = gql`
  query allPermissions {
    permissions: allPermissions {
      id
      name
      key
      description
    }
  }
`;

export const permissionQueryGQL = gql`
  query permission($permissionId: Float!) {
    permission(permissionId: $permissionId) {
      id
      name
      key
      description
    }
  }
`;

export const createPermissionMutationGQL = gql`
  mutation createPermission($permissionData: PermissionInputType!) {
    createPermission(permissionData: $permissionData) {
      id
      name
      key
      description
    }
  }
`;

export const updatePermissionMutationGQL = gql`
  mutation updatePermission($permissionId: Float!, $permissionData: PermissionInputType!) {
    updatePermission(permissionId: $permissionId, permissionData: $permissionData) {
      entityId
      message
    }
  }
`;

export const deletePermissionMutationGQL = gql`
  mutation deletePermission($permissionId: Float!) {
    deletePermission(permissionId: $permissionId)
  }
`;

export const permissionCreatedSubGQL = gql`
  subscription permissionCreated {
    permissionCreated {
      id
      name
      key
      description
    }
  }
`;

export const permissionUpdatedSubGQL = gql`
  subscription permissionUpdated {
    permissionUpdated {
      id
      name
      key
      description
    }
  }
`;

export const permissionDeletedSubGQL = gql`
  subscription permissionDeleted {
    permissionDeleted {
      entityId
      message
    }
  }
`;
