import React, { FC, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  technicalTutorialGQL,
  createTechnicalTutorialGQL,
  deleteTechnicalTutorialGQL,
  updateTechnicalTutorialGQL,
} from 'graphql/technical-tutorial';

import { TechnicalTutorialPanel as Component } from './technical-tutorial-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  technicalTutorialId: string;
};

export const TechnicalTutorialPanel: FC<ComponentProps> = ({
  technicalTutorialId,
  visible,
  onClose,
}) => {
  const { data } = useQuery<{ technicalTutorial: TechnicalTutorial }>(technicalTutorialGQL, {
    variables: { technicalTutorialId },
    skip: technicalTutorialId === null,
  });

  const [createTechnicalTutorialMutation] = useMutation(createTechnicalTutorialGQL);
  const [updateTechnicalTutorialMutation] = useMutation(updateTechnicalTutorialGQL);
  const [deleteTechnicalTutorialMutation, { loading }] = useMutation(deleteTechnicalTutorialGQL);

  const createTechnicalTutorial = useCallback(
    async (technicalTutorialData: TechnicalTutorial) => {
      await createTechnicalTutorialMutation({ variables: { technicalTutorialData } });
    },
    [createTechnicalTutorialMutation],
  );
  const updateTechnicalTutorial = useCallback(
    async (technicalTutorialData: TechnicalTutorial) => {
      await updateTechnicalTutorialMutation({
        variables: { technicalTutorialId, technicalTutorialData },
      });
    },
    [technicalTutorialId, updateTechnicalTutorialMutation],
  );

  const deleteTechnicalTutorial = useCallback(async () => {
    await deleteTechnicalTutorialMutation({ variables: { technicalTutorialId } });
  }, [technicalTutorialId, deleteTechnicalTutorialMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      technicalTutorial={data?.technicalTutorial ?? null}
      updateTechnicalTutorial={updateTechnicalTutorial}
      createTechnicalTutorial={createTechnicalTutorial}
      deleteTechnicalTutorial={deleteTechnicalTutorial}
      deletingTechnicalTutorial={loading}
    />
  );
};
