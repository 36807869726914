import React, { FC, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { allSuppliesGQL } from 'graphql/supply';
import { allFranchisesGQL } from 'graphql/franchise';
import {
  createSupplyRequestGQl,
  deleteSupplyRequestGQL,
  supplyRequestGQL,
  updateSupplyRequestGQL,
} from 'graphql/supply-request';

import { SupplyRequestPanel as Component } from './supply-request-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  supplyRequestId: string;
};

export const SupplyRequestPanel: FC<ComponentProps> = ({ supplyRequestId, onClose, visible }) => {
  const { data: suppliesResponse } = useQuery<{ supplies: Supply[] }>(allSuppliesGQL);
  const { data: franchisesResponse } = useQuery<{ franchises: Franchise[] }>(allFranchisesGQL);
  const { data: supplyRequestResponse } = useQuery<{ supplyRequest: SupplyRequest }>(
    supplyRequestGQL,
    {
      variables: { supplyRequestId },
      skip: supplyRequestId === null,
    },
  );

  const [createSupplyRequestMutation] = useMutation(createSupplyRequestGQl);
  const [updateSupplyRequestMutation] = useMutation(updateSupplyRequestGQL);
  const [deleteSupplyRequestMutation, { loading }] = useMutation(deleteSupplyRequestGQL);

  const createSupplyRequest = useCallback(
    async (supplyRequestData: SupplyRequest) => {
      await createSupplyRequestMutation({ variables: { supplyRequestData } });
    },
    [createSupplyRequestMutation],
  );
  const updateSupplyRequest = useCallback(
    async (supplyRequestData: SupplyRequest) => {
      await updateSupplyRequestMutation({ variables: { supplyRequestId, supplyRequestData } });
    },
    [supplyRequestId, updateSupplyRequestMutation],
  );
  const deleteSupplyRequest = useCallback(async () => {
    await deleteSupplyRequestMutation({ variables: { supplyRequestId } });
  }, [supplyRequestId, deleteSupplyRequestMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      supplyRequest={supplyRequestResponse?.supplyRequest ?? null}
      updateSupplyRequest={updateSupplyRequest}
      createSupplyRequest={createSupplyRequest}
      deleteSupplyRequest={deleteSupplyRequest}
      deletingSupplyRequest={loading}
      supplies={suppliesResponse?.supplies ?? []}
      franchises={franchisesResponse?.franchises ?? []}
    />
  );
};
