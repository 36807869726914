import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './device-commerce.scss';

 import { DeviceCommercePage } from './devices';


export const DevicesCommercePage: FC = () => {
  return (
    <div>
      <div>
        <DeviceCommercePage />
      </div>
    </div>
  );
};
