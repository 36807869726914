import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import randomstring from 'randomstring';
import { Button, Confirm, Form, Icon } from 'semantic-ui-react';
import DefaultUserAvatar from 'assets/images/user.jpg';
import * as Yup from 'yup';
import { ValidationMessages } from 'utils/validation-messages';
import { Checkbox } from 'components/checkbox';
import styles from './technician-panel.module.scss';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  technician: Technician;
  updateTechnician: (technician: Technician) => Promise<void>;
  createTechnician: (technician: Technician) => Promise<void>;
  deleteTechnician: () => Promise<void>;
  checkEmailAvailavility: (email: string) => Promise<EmailAvailability>;
  deletingTechnician: boolean;
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  enabled: boolean;
  phoneNumber: string;
  profileImage: File;
  documentNumber: string;
  jobTitle: string;
};

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  enabled: true,
  phoneNumber: '',
  profileImage: null,
  documentNumber: '',
  jobTitle: '',
};

export const TechnicianPanel: FC<ComponentProps> = ({
  visible,
  onClose,
  technician,
  createTechnician,
  deleteTechnician,
  deletingTechnician,
  updateTechnician,
  checkEmailAvailavility,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const fileRef = useRef<HTMLInputElement>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);
  const [userAvatarUrl, setUserAvatarUrl] = useState(
    technician ? technician.profileImageUrl : DefaultUserAvatar,
  );

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      const submit = async () => {
        const { profileImage, ...rest } = values;
        helpers.setSubmitting(true);
        if (technician) {
          updateTechnician({ ...rest, profileImage }).then(onClose);
        } else {
          createTechnician({
            ...rest,
            password: randomstring.generate(7),
            profileImage,
          }).then(onClose);
        }
      };

      submit();
    },
    [technician, updateTechnician, createTechnician, onClose],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape<FormValues>({
        firstName: Yup.string().required(ValidationMessages.required),
        lastName: Yup.string().required(ValidationMessages.required),
        email: Yup.string()
          .required(ValidationMessages.required)
          .email(ValidationMessages.email)
          .test('isAvailable', 'El email no esta disponible', (value) =>
            technician === null && value !== undefined
              ? checkEmailAvailavility(value).then((result) => result.available)
              : true,
          ),
        username: Yup.string().required(ValidationMessages.required),
        enabled: Yup.boolean(),
        phoneNumber: Yup.string().required(ValidationMessages.required),
        profileImage: null,
        documentNumber: Yup.string().required(ValidationMessages.required),
        jobTitle: Yup.string().required(ValidationMessages.required),
      }),
    [checkEmailAvailavility, technician],
  );

  useEffect(() => {
    if (technician) {
      formRef.current.setValues({
        firstName: technician.firstName,
        lastName: technician.lastName,
        email: technician.email,
        username: technician.username,
        enabled: technician.enabled,
        phoneNumber: technician.phoneNumber,
        profileImage: null,
        documentNumber: technician.documentNumber,
        jobTitle: technician.jobTitle,
      });

      setUserAvatarUrl(technician.profileImageUrl ?? DefaultUserAvatar);
    } else {
      setUserAvatarUrl(DefaultUserAvatar);
    }
  }, [technician]);

  return (
    <SidePanel onClose={onClose} visible={visible}>
      <div className="panel-title">
        {technician ? `${technician.firstName} ${technician.lastName}` : 'Crear Técnico'}
      </div>
      {technician && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingTechnician}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content={
              <div className="content">
                Desea eliminar el Usuario{' '}
                <span
                  className={styles.deletingRole}
                >{`${technician.firstName} ${technician.lastName}`}</span>
                ?
              </div>
            }
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteTechnician().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, isSubmitting, setFieldValue }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <div>
                <div className={styles.profileImageSelector}>
                  <div className={styles.avatarContainer}>
                    <img src={userAvatarUrl} alt="" className={styles.avatar} />
                  </div>
                  <Button
                    icon="camera"
                    circular
                    size="small"
                    className={styles.cameraButton}
                    onClick={() => fileRef.current.click()}
                    type="button"
                  />
                </div>
                <div hidden>
                  <input
                    type="file"
                    id="avatar"
                    name="avatar"
                    accept="image/png, image/jpeg"
                    ref={fileRef}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('profileImage', event.target.files[0]);
                      setUserAvatarUrl(URL.createObjectURL(event.target.files[0]));
                    }}
                  />
                </div>
                <FastField
                  name="firstName"
                  label="Nombre"
                  component={InputField}
                  value={values.firstName}
                  placeholder="Nombre"
                />
                <FastField
                  name="lastName"
                  label="Apellido"
                  component={InputField}
                  value={values.lastName}
                  placeholder="Apellido"
                />
                <FastField
                  name="email"
                  label="Email"
                  component={InputField}
                  value={values.email}
                  placeholder="Correo Electronico"
                />
                <FastField
                  name="username"
                  label="Username"
                  component={InputField}
                  value={values.username}
                  placeholder="Username"
                />
                <FastField
                  name="phoneNumber"
                  label="Número de Telefono"
                  component={InputField}
                  value={values.phoneNumber}
                  placeholder="Número de telefono"
                />
                <FastField
                  name="documentNumber"
                  label="Documento"
                  component={InputField}
                  value={values.documentNumber}
                  placeholder="Número de documento"
                />
                <FastField
                  name="jobTitle"
                  label="Cargo"
                  component={InputField}
                  value={values.jobTitle}
                  placeholder="Cargo del Tecnico"
                />
                <div className={`field ${styles.inlineLabel}`}>
                  <label>Habilitado</label>
                  <Checkbox
                    selected={values.enabled}
                    onSelect={() => setFieldValue('enabled', true)}
                    onDeselect={() => setFieldValue('enabled', false)}
                  />
                </div>
              </div>
              <div className="buttons-container">
                <Button size="medium" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="medium" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
