import React, { FC, useCallback, useState } from 'react';
import pageStyles from 'assets/styles/page.module.scss';
import { Button, Table } from 'semantic-ui-react';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import { ServiceStatePanel } from './service-state-panel';

export type ComponentProps = {
  serviceStates: ServiceState[];
};

const fixedStates = ['service_pending', 'service_active', 'service_finished'];

export const ServiceStates: FC<ComponentProps> = ({ serviceStates }) => {
  const [showServiceStatePanel, setShowServiceStatePanel] = useState(false);
  const [selectedServiceState, setSelectedServiceState] = useState<string>(null);
  const openServiceStateDetails = useCallback((serviceStateId: string) => {
    setSelectedServiceState(serviceStateId);
    setShowServiceStatePanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Estados de Servicio</div>
        <div className={pageStyles.tableHeader}>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedServiceState(null);
              setShowServiceStatePanel(true);
            }}
          >
            Crear Estado <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {serviceStates.map((serviceState) => (
                <Table.Row key={serviceState.id}>
                  <Table.Cell>{serviceState.name}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    {!fixedStates.includes(serviceState.key) && (
                      <div
                        onClick={() => openServiceStateDetails(serviceState.id)}
                        aria-hidden
                        className="details-link"
                      >
                        Ver Detalles
                      </div>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <ServiceStatePanel
        serviceStateId={selectedServiceState}
        visible={showServiceStatePanel}
        onClose={() => {
          setSelectedServiceState(null);
          setShowServiceStatePanel(false);
        }}
      />
    </div>
  );
};
