import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';

import { PersonsPage as Component } from './persons';
import { usePagination } from '../../../utils/hooks/pagination';
import {
  technicianCreatedGQL,
  technicianDeletedGQL,
  techniciansGQL,
  technicianUpdatedGQL,
} from '../../../graphql/technician';

export const PersonsPage: FC = () => {
  const { data, loading, refetch } = useQuery<
    { technicians: PaginatedEntityList<Technician> },
    PaginatedVariables
  >(techniciansGQL, { variables: { pageSize: parseFloat(process.env.REACT_APP_TABLE_PAGE_SIZE) } });
  const { search, changePage } = usePagination(refetch);

  useSubscription(technicianUpdatedGQL);
  useSubscription(technicianCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(technicianDeletedGQL, { onSubscriptionData: () => refetch() });

  return (
    <Component
      technicians={data?.technicians.data ?? []}
      pagination={
        loading
          ? null
          : { pageNumber: data?.technicians.pageNumber, totalPages: data?.technicians.totalPages }
      }
      onPageChange={changePage}
      onSearch={search}
      loading={loading}
    />
  );
};
