import React, { FC, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  franchiseTypeGQL,
  createFranchiseTypeGQL,
  deleteFranchiseTypeGQL,
  updateFranchiseTypeGQL,
} from 'graphql/franchise-type';

import { FranchiseTypePanel as Component } from './franchise-type-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  franchiseTypeId: string;
};

export const FranchiseTypePanel: FC<ComponentProps> = ({ visible, onClose, franchiseTypeId }) => {
  const { data } = useQuery<{ franchiseType: FranchiseType }>(franchiseTypeGQL, {
    variables: { franchiseTypeId },
    skip: franchiseTypeId === null,
  });

  const [createFranchisteTypeMutation] = useMutation(createFranchiseTypeGQL);
  const [updateFranchisteTypeMutation] = useMutation(updateFranchiseTypeGQL);
  const [deleteFranchisteTypeMutation, { loading }] = useMutation(deleteFranchiseTypeGQL);

  const createFranchise = useCallback(
    async (franchiseTypeData: FranchiseType) => {
      await createFranchisteTypeMutation({ variables: { franchiseTypeData } });
    },
    [createFranchisteTypeMutation],
  );
  const updateFranchise = useCallback(
    async (franchiseTypeData: FranchiseType) => {
      await updateFranchisteTypeMutation({ variables: { franchiseTypeId, franchiseTypeData } });
    },
    [franchiseTypeId, updateFranchisteTypeMutation],
  );
  const deleteFranchise = useCallback(async () => {
    await deleteFranchisteTypeMutation({ variables: { franchiseTypeId } });
  }, [franchiseTypeId, deleteFranchisteTypeMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      franchiseType={data?.franchiseType ?? null}
      updateFranchiseType={updateFranchise}
      createFranchiseType={createFranchise}
      deleteFranchiseType={deleteFranchise}
      deletingFranchiseType={loading}
    />
  );
};
