import React, { FC, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  createServiceStateGQL,
  deleteServiceStateGQL,
  serviceStateGQL,
  updateServiceStateGQL,
} from 'graphql/service-state';

import { ServiceStatePanel as Component } from './service-state-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  serviceStateId: string;
};

export const ServiceStatePanel: FC<ComponentProps> = ({ serviceStateId, onClose, visible }) => {
  const { data } = useQuery<{ serviceState: ServiceState }>(serviceStateGQL, {
    variables: { serviceStateId },
    skip: serviceStateId === null,
  });

  const [createServiceStateMutation] = useMutation(createServiceStateGQL);
  const [updateServiceStateMutation] = useMutation(updateServiceStateGQL);
  const [deleteServiceStateMutation, { loading }] = useMutation(deleteServiceStateGQL);

  const createServiceState = useCallback(
    async (serviceStateData: ServiceState) => {
      await createServiceStateMutation({ variables: { serviceStateData } });
    },
    [createServiceStateMutation],
  );
  const updateServiceState = useCallback(
    async (serviceStateData: ServiceState) => {
      await updateServiceStateMutation({ variables: { serviceStateId, serviceStateData } });
    },
    [serviceStateId, updateServiceStateMutation],
  );
  const deleteServiceState = useCallback(async () => {
    await deleteServiceStateMutation({ variables: { serviceStateId } });
  }, [serviceStateId, deleteServiceStateMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      serviceState={data?.serviceState ?? null}
      updateServiceState={updateServiceState}
      createServiceState={createServiceState}
      deleteServiceState={deleteServiceState}
      deletingServiceState={loading}
    />
  );
};
