import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { deviceCreatedGQL, deviceDeletedGQL, devicesGQL, deviceUpdatedGQL } from 'graphql/device';
import { usePagination } from 'utils/hooks/pagination';
import { DeviceUnitsPage as Component } from './device-units';
import { serviceUpdatedGQL } from '../../../graphql/services';

export const DeviceUnitsPage: FC = () => {
  const { data, loading, refetch } = useQuery<
    { devices: PaginatedEntityList<Device> },
    PaginatedVariables
  >(devicesGQL, {
    variables: { pageSize: parseFloat(process.env.REACT_APP_TABLE_PAGE_SIZE) },
  });

  const { search, changePage } = usePagination(refetch);

  useSubscription(deviceUpdatedGQL);
  useSubscription(deviceCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(deviceDeletedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(serviceUpdatedGQL, { onSubscriptionData: () => refetch() });

  return (
    <Component
      devices={data?.devices.data ?? []}
      pagination={
        loading
          ? null
          : { pageNumber: data?.devices.pageNumber, totalPages: data?.devices.totalPages }
      }
      onSearch={search}
      onPageChange={changePage}
    />
  );
};
