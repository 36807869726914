import React, { FC } from 'react';
import './menu.scss';
import { Calendar, Box } from 'react-feather';
import { NavLink } from 'react-router-dom';

export const UsersMenu: FC = () => {
  return (
    <div className="requests-menu">
      <NavLink to="/requests/services" className="menu-item" activeClassName="active">
        <div className="item-icon">
          <Calendar size={26} />
        </div>
        <div className="item-name">Servicios</div>
      </NavLink>
      {/*<NavLink to="/requests/supplies" className="menu-item" activeClassName="active">*/}
      {/*  <div className="item-icon">*/}
      {/*    <Box size={26} />*/}
      {/*  </div>*/}
      {/*  <div className="item-name">Insumos</div>*/}
      {/*</NavLink>*/}
    </div>
  );
};
