import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './technicians.page.scss';

import { PersonsPage } from './persons';

export const TechniciansPage: FC = () => {
  return (
    <div className="technicians-page">
      <div className="technicians-content-section">
        <Switch>
          <Route path="/technicians" exact>
            <Redirect to="/technicians/persons" />
          </Route>
          <Route path="/technicians/persons">
            <PersonsPage />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
