import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  allDeviceTypesGQL,
  deviceTypeCreatedGQL,
  deviceTypeDeletedGQL,
  deviceTypeUpdatedGQL,
} from 'graphql/device-type';
import { DeviceTypesPage as Component } from './device-types';

export const DeviceTypesPage: FC = () => {
  const { data, refetch } = useQuery<{ deviceTypes: DeviceType[] }>(allDeviceTypesGQL);
  useSubscription(deviceTypeUpdatedGQL);
  useSubscription(deviceTypeCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(deviceTypeDeletedGQL, { onSubscriptionData: () => refetch() });

  return <Component deviceTypes={data?.deviceTypes ?? []} />;
};
