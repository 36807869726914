import React, { FC, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  customerGQL,
  createCustomerGQL,
  updateCustomerGQL,
  deleteCustomerGQL,
} from 'graphql/customer';

import { CustCommercePanel as Component } from './commerce-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  customerId: string;
};

export const CustCommercePanel: FC<ComponentProps> = ({ customerId, onClose, visible }) => {
  const { data } = useQuery<{ customer: Customer }>(customerGQL, {
    variables: { customerId },
    skip: customerId === null,
  });

  const [createCustomerMutation] = useMutation(createCustomerGQL);
  const [updateCustomerMutation] = useMutation(updateCustomerGQL);
  const [deleteCustomerMutation, { loading }] = useMutation(deleteCustomerGQL);

  const createCustomer = useCallback(
    async (customerData: Customer) => {
      await createCustomerMutation({ variables: { customerData } });
    },
    [createCustomerMutation],
  );
  const updateCustomer = useCallback(
    async (customerData: Customer) => {
      await updateCustomerMutation({ variables: { customerId, customerData } });
    },
    [customerId, updateCustomerMutation],
  );
  const deleteCustomer = useCallback(async () => {
    await deleteCustomerMutation({ variables: { customerId } });
  }, [customerId, deleteCustomerMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      custCommerce={data?.customer ?? null}
      updateCustCommerce={updateCustomer}
      createCustCommerce={createCustomer}
      deleteCustCommerce={deleteCustomer}
      deletingCustCommerce={loading}
    />
  );
};
