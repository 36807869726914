import React, { FC, useCallback, useState } from 'react';
import pageStyles from 'assets/styles/page.module.scss';
import './persons.scss';
import { Button, Input, Pagination, Table } from 'semantic-ui-react';

import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { TechnicianPanel } from './technician-panel';
import DefaultUserAvatar from '../../../assets/images/user.jpg';

export type ComponentProps = {
  technicians: Technician[];
  pagination: PaginationProps;
  loading: boolean;
  onSearch: (value: string) => void;
  onPageChange: (page: number) => void;
};

export const PersonsPage: FC<ComponentProps> = ({
  technicians,
  pagination,
  onPageChange,
  onSearch,
}) => {
  const [showPersonPanel, setShowPersonPanel] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<string>(null);

  const openPersonPanel = useCallback((technicianId: string) => {
    setSelectedPerson(technicianId);
    setShowPersonPanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Técnicos</div>
        <div className={pageStyles.tableHeader}>
          <div className={pageStyles.controls}>
            <Input
              icon="search"
              placeholder="Buscar"
              className={pageStyles.searchInput}
              onChange={(_, data) => onSearch(data.value)}
            />
          </div>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedPerson(null);
              setShowPersonPanel(true);
            }}
          >
            Crear Técnico <PersonAddOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} textAlign="center" />
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>No. Documento</Table.HeaderCell>
                <Table.HeaderCell>Cargo</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {technicians.map((technician) => (
                <Table.Row key={technician.id}>
                  <Table.Cell className={pageStyles.userAvatar} textAlign="center">
                    <div className={pageStyles.avatarContainer}>
                      <img
                        src={technician.profileImageUrl ?? DefaultUserAvatar}
                        alt="avatar"
                        className={pageStyles.avatar}
                      />
                    </div>
                  </Table.Cell>
                  <Table.Cell
                    className={pageStyles.boldText}
                  >{`${technician.firstName} ${technician.lastName}`}</Table.Cell>
                  <Table.Cell>{technician.documentNumber}</Table.Cell>
                  <Table.Cell>{technician.jobTitle}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openPersonPanel(technician.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        {pagination && (
          <div className={pageStyles.paginationContainer}>
            <Pagination
              totalPages={pagination.totalPages}
              activePage={pagination.pageNumber}
              firstItem={null}
              lastItem={null}
              onPageChange={(event, data) => onPageChange(data.activePage as number)}
            />
          </div>
        )}
      </div>

      <TechnicianPanel
        visible={showPersonPanel}
        onClose={() => {
          setSelectedPerson(null);
          setShowPersonPanel(false);
        }}
        technicianId={selectedPerson}
      />
    </div>
  );
};
