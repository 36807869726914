import React, { FC, useCallback, useState } from 'react';
import { Button, Input, Label, Pagination, Table } from 'semantic-ui-react';
import DefaultUserAvatar from 'assets/images/user.jpg';

import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import pageStyles from 'assets/styles/page.module.scss';
import { PersonPanel } from './person-panel';
import 'assets/styles/globals.scss';

interface ComponentProps {
  users: User[];
  pagination: PaginationProps;
  loading: boolean;
  onSearch: (value: string) => void;
  onPageChange: (page: number) => void;
}

export const Persons: FC<ComponentProps> = ({ users, onPageChange, pagination, onSearch }) => {
  const [showPersonPanel, setShowPersonPanel] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<number>(null);

  const openPersonPanel = useCallback((userId: number) => {
    setSelectedPerson(userId);
    setShowPersonPanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Personas</div>
        <div className={pageStyles.tableHeader}>
          <div className={pageStyles.controls}>
            <Input
              icon="search"
              placeholder="Buscar"
              className={pageStyles.searchInput}
              onChange={(_, data) => onSearch(data.value)}
            />
          </div>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedPerson(null);
              setShowPersonPanel(true);
            }}
          >
            Crear usuario <PersonAddOutlinedIcon />
          </Button>
        </div>
      </div>

      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} textAlign="center" />
                <Table.HeaderCell>Nombres</Table.HeaderCell>
                <Table.HeaderCell>Username</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Estado</Table.HeaderCell>
                <Table.HeaderCell>Telefono</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {users.length ? (
                users.map((user) => (
                  <Table.Row key={user.id}>
                    <Table.Cell className={pageStyles.userAvatar} textAlign="center">
                      <div className={pageStyles.avatarContainer}>
                        <img
                          src={user.profileImageUrl ?? DefaultUserAvatar}
                          alt="avatar"
                          className={pageStyles.avatar}
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell
                      className={pageStyles.boldText}
                    >{`${user.firstName} ${user.lastName}`}</Table.Cell>
                    <Table.Cell>{user.username}</Table.Cell>
                    <Table.Cell>{user.email}</Table.Cell>
                    <Table.Cell>
                      {user.enabled ? (
                        <Label className={pageStyles.activeLabel}>Activo</Label>
                      ) : (
                        <Label>Inactivo</Label>
                      )}
                    </Table.Cell>
                    <Table.Cell>{user.phoneNumber}</Table.Cell>
                    <Table.Cell className={pageStyles.detailsAction}>
                      <div
                        onClick={() => openPersonPanel(user.id)}
                        aria-hidden
                        className="details-link"
                      >
                        Ver Detalles
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell collapsing colSpan={7} textAlign="center">
                    No se encontraron datos.
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
        {pagination && (
          <div className={pageStyles.paginationContainer}>
            <Pagination
              totalPages={pagination.totalPages}
              activePage={pagination.pageNumber}
              firstItem={null}
              lastItem={null}
              onPageChange={(event, data) => onPageChange(data.activePage as number)}
            />
          </div>
        )}
      </div>

      <PersonPanel
        visible={showPersonPanel}
        onClose={() => {
          setSelectedPerson(null);
          setShowPersonPanel(false);
        }}
        userId={selectedPerson}
      />
    </div>
  );
};
