import React, { FC } from 'react';
import { RouteProps } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { Cookies } from 'react-cookie';

import { PrivateRoute as Component } from './private-route';

export const PrivateRoute: FC<RouteProps> = (props) => {
  const cookies = new Cookies();
  const userToken = cookies.get('rtcsession');
  return useObserver(() => <Component {...props} isAuthenticated={userToken !== undefined} />);
};
