import React, { FC, useCallback } from 'react';
import { ArrowRight } from 'react-feather';
import { Button } from 'semantic-ui-react';
import './side-panel.scss';

interface ComponentProps {
  visible: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  subPanel?: React.ReactElement;
  showSubPanel?: boolean;
  onCloseSubPanel?: () => void;
}

export const SidePanel: FC<ComponentProps> = ({
  children,
  visible,
  onClose,
  showCloseButton,
  subPanel,
  showSubPanel,
  onCloseSubPanel,
}) => {
  return visible ? (
    <div className="side-panel">
      {showSubPanel && (
        <div className="panel subpanel">
          <div className="delete-button-container" onClick={onCloseSubPanel} aria-hidden>
            <Button circular icon="times" size="tiny" />
          </div>
          {subPanel}
        </div>
      )}
      <div className="panel panelAppear">
        {showCloseButton && (
          <div className="close-button" onClick={onClose} aria-hidden>
            <p className="button-text">Cerrar</p>
            <ArrowRight />
          </div>
        )}
        {children}
      </div>
    </div>
  ) : null;
};

SidePanel.defaultProps = {
  showCloseButton: false,
  showSubPanel: false,
};
