import React, { FC, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  serviceNoveltyGQL,
  updateServiceNoveltyGQL,
  createServiceNoveltyGQL,
  deleteServiceNoveltyGQL,
} from 'graphql/service-novelties';
import { ServiceNoveltyTypePanel as Component } from './service-novelty-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  serviceNoveltyId: string;
};

export const ServiceNoveltyTypePanel: FC<ComponentProps> = ({
  serviceNoveltyId,
  onClose,
  visible,
}) => {
  const { data } = useQuery<{ serviceNovelty: ServiceNoveltyType }>(serviceNoveltyGQL, {
    variables: { serviceNoveltyId },
    skip: serviceNoveltyId === null,
  });

  const [createServiceNoveltyMutation] = useMutation(createServiceNoveltyGQL);
  const [updateServiceNoveltyMutation] = useMutation(updateServiceNoveltyGQL);
  const [deleteServiceNoveltyMutation, { loading }] = useMutation(deleteServiceNoveltyGQL);

  const createServiceNovelty = useCallback(
    async (serviceNoveltyData: ServiceNoveltyType) => {
      await createServiceNoveltyMutation({
        variables: {
          serviceNoveltyData,
        },
      });
    },
    [createServiceNoveltyMutation],
  );

  const updateServiceNovelty = useCallback(
    async (serviceNoveltyData: ServiceNoveltyType) => {
      await updateServiceNoveltyMutation({
        variables: {
          serviceNoveltyId,
          serviceNoveltyData,
        },
      });
    },
    [serviceNoveltyId, updateServiceNoveltyMutation],
  );

  const deleteServiceNovelty = useCallback(async () => {
    await deleteServiceNoveltyMutation({
      variables: {
        serviceNoveltyId,
      },
    });
  }, [serviceNoveltyId, deleteServiceNoveltyMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      serviceNoveltyType={data?.serviceNovelty ?? null}
      updateServiceNoveltyType={updateServiceNovelty}
      createServiceNoveltyType={createServiceNovelty}
      deleteServiceNoveltyType={deleteServiceNovelty}
      deletingServiceNoveltyType={loading}
    />
  );
};
