import React, { FC, useCallback, useState } from 'react';
import { Button, Input, Pagination, Table } from 'semantic-ui-react';
import { PermissionPanel } from './permission-panel';
import pageStyles from 'assets/styles/page.module.scss';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';

export type ComponentProps = {
  permissions: Permission[];
  pagination: PaginationProps;
  onSearch: (value: string) => void;
  onPageChange: (page: number) => void;
};

export const PermissionsPage: FC<ComponentProps> = ({
  permissions,
  onSearch,
  onPageChange,
  pagination,
}) => {
  const [showPermissionPanel, setShowPermissionPanel] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState<string>(null);
  const openPermissionDetails = useCallback((permissionId: string) => {
    setSelectedPermission(permissionId);
    setShowPermissionPanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Permisos</div>
        <div className={pageStyles.tableHeader}>
          <div className={pageStyles.controls}>
            <Input
              icon="search"
              placeholder="Buscar"
              className={pageStyles.searchInput}
              onChange={(_, data) => onSearch(data.value)}
            />
          </div>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedPermission(null);
              setShowPermissionPanel(true);
            }}
          >
            Crear Permiso <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>

      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Llave</Table.HeaderCell>
                <Table.HeaderCell>Descripción</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {permissions.map((permission) => (
                <Table.Row key={permission.id}>
                  <Table.Cell className={pageStyles.boldText}>{permission.name}</Table.Cell>
                  <Table.Cell>{permission.key}</Table.Cell>
                  <Table.Cell>{permission.description}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openPermissionDetails(permission.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        {pagination && (
          <div className={pageStyles.paginationContainer}>
            <Pagination
              totalPages={pagination.totalPages}
              activePage={pagination.pageNumber}
              firstItem={null}
              lastItem={null}
              onPageChange={(event, data) => onPageChange(data.activePage as number)}
            />
          </div>
        )}
      </div>
      <PermissionPanel
        permissionId={selectedPermission}
        visible={showPermissionPanel}
        onClose={() => {
          setSelectedPermission(null);
          setShowPermissionPanel(false);
        }}
      />
    </div>
  );
};
