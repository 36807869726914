import { gql } from '@apollo/client';

export const allSupplyTypesGQL = gql`
  query allSupplyTypes {
    supplyTypes: allSupplyTypes {
      id
      name
    }
  }
`;

export const supplyTypeGQL = gql`
  query supplyType($supplyTypeId: String!) {
    supplyType(supplyTypeId: $supplyTypeId) {
      id
      name
    }
  }
`;

export const createSupplyTypeGQL = gql`
  mutation createSupplyType($supplyTypeData: SupplyTypeInputType!) {
    createSupplyType(supplyTypeData: $supplyTypeData) {
      id
      name
    }
  }
`;

export const updateSupplyTypeGQL = gql`
  mutation updateSupplyType($supplyTypeId: String!, $supplyTypeData: SupplyTypeInputType!) {
    updateSupplyType(supplyTypeId: $supplyTypeId, supplyTypeData: $supplyTypeData) {
      message
      entityId
    }
  }
`;

export const deleteSupplyTypeGQL = gql`
  mutation deleteSupplyType($supplyTypeId: String!) {
    deleteSupplyType(supplyTypeId: $supplyTypeId) {
      message
      entityId
    }
  }
`;

export const supplyTypeCreatedGQL = gql`
  subscription supplyTypeCreated {
    supplyTypeCreated {
      id
      name
    }
  }
`;

export const supplyTypeUpdatedGQL = gql`
  subscription supplyTypeUpdated {
    supplyTypeUpdated {
      id
      name
    }
  }
`;

export const supplyTypeDeletedGQL = gql`
  subscription supplyTypeDeleted {
    supplyTypeDeleted {
      message
      entityId
    }
  }
`;
