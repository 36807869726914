import React, { FC, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  createRoleMutationGQL,
  deleteRoleMutationGQL,
  roleQueryGQL,
  updateRoleMutationGQL,
} from 'graphql/role';
import { allPermissionsQueryGQL } from 'graphql/permission';
import { RolePanel as Component } from './roles-panel';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  roleId: string;
};

export const RolePanel: FC<ComponentProps> = ({ visible, onClose, roleId }) => {
  const { data: roleData } = useQuery<{ role: Role }>(roleQueryGQL, {
    variables: { roleId: parseFloat(roleId as any) },
    skip: roleId === null,
  });

  const { data: permissionsData } = useQuery<{ permissions: Permission[] }>(
    allPermissionsQueryGQL,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [createRoleMutation] = useMutation(createRoleMutationGQL);
  const [updateRoleMutation] = useMutation(updateRoleMutationGQL);
  const [deleteRoleMutation, { loading }] = useMutation(deleteRoleMutationGQL);

  const createRole = useCallback(
    async (role: Role) => {
      await createRoleMutation({ variables: { roleData: role } });
    },
    [createRoleMutation],
  );

  const updateRole = useCallback(
    async (role: Role) => {
      await updateRoleMutation({
        variables: { roleId: parseFloat(roleId as any), roleData: role },
      });
    },
    [roleId, updateRoleMutation],
  );

  const deleteRole = useCallback(async () => {
    await deleteRoleMutation({ variables: { roleId: parseFloat(roleId) } });
  }, [roleId, deleteRoleMutation]);

  return (
    <Component
      visible={visible}
      onClose={onClose}
      role={roleData?.role ?? null}
      updateRole={updateRole}
      createRole={createRole}
      deleteRole={deleteRole}
      deletingRole={loading}
      permissions={permissionsData?.permissions ?? []}
    />
  );
};
