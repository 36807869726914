import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Button, Form, Icon } from 'semantic-ui-react';
import { FastField, Formik, FormikProps } from 'formik';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as Yup from 'yup';
import { ValidationMessages } from 'utils/validation-messages';
import axios from 'axios';
import styles from './address-modal.module.scss';
import { InputField } from '../input-field';

export type ComponentProps = {
  address?: string;
  trigger: React.ReactElement;
  title: string;
  onSave: (result: { address: string; geoLocation: GeoLocation }) => void;
};

type FormValues = {
  address: string;
  geoLocation: GeoLocation;
};

const validationSchema = Yup.object().shape<FormValues>({
  address: Yup.string().nullable().required(ValidationMessages.required),
  geoLocation: null,
});

const defaultCenter = { lat: 4.6510213, lng: -74.0988917 };

export const AddressModal: FC<ComponentProps> = ({ trigger, title, onSave, address }) => {
  const searchSubject = useRef(new Subject<string>()).current;
  const [open, setOpen] = useState(false);
  const formRef = useRef<FormikProps<FormValues>>();
  const [center, setCenter] = useState(defaultCenter);
  const [marker, setMarker] = useState<{ lat: number; lng: number }>(null);

  const [map, setMap] = React.useState<google.maps.Map>(null);

  const onLoad = useCallback((googleMap: google.maps.Map) => {
    // const bounds = new window.google.maps.LatLngBounds();
    // googleMap.fitBounds(bounds);
    setMap(googleMap);
  }, []);

  const onUnmount = useCallback(() => setMap(null), []);

  const handleFormSubmit = useCallback(
    (values: FormValues) => {
      onSave(values);
      setOpen(false);
    },
    [onSave],
  );

  const initialValues: FormValues = useMemo(() => {
    return {
      address,
      geoLocation: null,
    };
  }, [address]);

  useEffect(() => {
    const searchSub = searchSubject
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((value) => {
        axios
          .get('https://maps.googleapis.com/maps/api/geocode/json', {
            params: {
              address: value,
              key: 'AIzaSyCnfz6Sr337fDJwo9jAoX6d8-3VCeuiqfw',
              language: 'es',
            },
          })
          .then(({ data }) => {
            if (data.status === 'OK') {
              formRef.current?.setFieldValue('geoLocation', {
                latitude: data.results[0].geometry.location.lat,
                longitude: data.results[0].geometry.location.lng,
              });

              setCenter(data.results[0].geometry.location);
              setMarker(data.results[0].geometry.location);
              map.setZoom(17);
            }
          });
      });

    return () => searchSub.unsubscribe();
  }, [searchSubject, map]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      size="small"
      className={styles.container}
    >
      <Modal.Header className={styles.modalTitle}>{title}</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
          innerRef={formRef}
        >
          {() => (
            <Form>
              <FastField
                name="address"
                label="Dirección"
                placeholder="Escriba una dirección"
                component={InputField}
                onChange={(value: string) => {
                  if (value.trim() === '') {
                    map.setCenter(defaultCenter);
                    map.setZoom(11);
                    setMarker(null);
                  } else {
                    searchSubject.next(value);
                  }
                }}
              />
            </Form>
          )}
        </Formik>
        <div className={styles.mapContainer}>
          <LoadScript googleMapsApiKey="AIzaSyCnfz6Sr337fDJwo9jAoX6d8-3VCeuiqfw">
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%' }}
              center={center}
              zoom={11}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                disableDefaultUI: true,
                clickableIcons: false,
              }}
            >
              {marker && <Marker position={marker} />}
            </GoogleMap>
          </LoadScript>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button size="small" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
        <Button
          size="small"
          onClick={() => {
            formRef.current.submitForm();
          }}
          icon
          primary
        >
          Guardar
          <Icon name="save" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

AddressModal.defaultProps = {
  address: '',
};
