import React, { FC, useCallback } from 'react';
import { changeUserPasswordGQL, sessionUserGQL } from 'graphql/auth';
import { useMutation, useQuery } from '@apollo/client';
import { PasswordForm as Component } from './password.form';

export const PasswordForm: FC = () => {
  const { data } = useQuery<{ sessionUser: User }>(sessionUserGQL);
  const [changeUserPasswordMutation, { loading }] = useMutation(changeUserPasswordGQL);

  const changePassword = useCallback(
    async (password: string) => {
      await changeUserPasswordMutation({ variables: { userId: data?.sessionUser.id, password } });
    },
    [changeUserPasswordMutation, data.sessionUser],
  );

  return <Component changePassword={changePassword} changingPassword={loading} />;
};
