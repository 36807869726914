import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import styles from './chat.module.scss';
import { ServiceChat } from './service-chat';

export const ChatPage: FC = () => {
  return (
    <div className={styles.chatPage}>
      <div className={styles.chatContentSection}>
        <Switch>
          <Route path="/chat" exact>
            <Redirect to="/chat/service-chat" />
          </Route>
          <Route path="/chat/service-chat">
            <ServiceChat />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
