import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, DropdownItemProps, Form, Icon } from 'semantic-ui-react';
import { SelectorField } from 'components/selector-field';
import { TextAreaField } from 'components/text-area-field';
import { SidePanel } from 'components/side-panel';
import { DatePickerField } from 'components/date-picker-field';
import moment from 'moment';
import styles from '../../../issues/issue-list/issue-panel/issue-panel.module.scss';
import * as Yup from 'yup';
import { ValidationMessages } from '../../../../utils/validation-messages';

type FormValues = {
  description: string;
  scheduledTime: Date;
  stateId: string;
  reasonId: string;
  issueId: string;
  supplyRequestId: string;
  assignedUserId: string;
  technicianId: string;
  franchiseId: string;
  maintenanceReason: boolean;
  serviceReason: string;
};

const initialValues: FormValues = {
  description: '',
  scheduledTime: new Date(),
  stateId: null,
  reasonId: null,
  issueId: null,
  supplyRequestId: null,
  assignedUserId: null,
  technicianId: null,
  franchiseId: null,
  maintenanceReason: true,
  serviceReason: 'issues',
};

export type ComponentProps = {
  service: Service;
  createService: (service: Service) => Promise<void>;
  updateService: (service: Service) => Promise<void>;
  deleteService: () => Promise<void>;
  deletingService: boolean;
  serviceStates: ServiceState[];
  serviceReasons: ServiceReason[];
  issues: Issue[];
  supplyRequests: SupplyRequest[];
  users: User[];
  technicians: Technician[];
  franchises: Franchise[];
} & PanelProps;

const validationSchema = Yup.object().shape<FormValues>({
  description: Yup.string().nullable().required(ValidationMessages.required),
  scheduledTime: Yup.date().nullable().required(ValidationMessages.required),
  stateId: Yup.string().nullable().required(ValidationMessages.required),
  reasonId: Yup.string().nullable().required(ValidationMessages.required),
  issueId: Yup.string().nullable(),
  supplyRequestId: Yup.string().nullable(),
  assignedUserId: Yup.string().nullable().required(ValidationMessages.required),
  technicianId: Yup.string().nullable().required(ValidationMessages.required),
  franchiseId: Yup.string().nullable().required(ValidationMessages.required),
  maintenanceReason: Yup.boolean().required(ValidationMessages.required),
  serviceReason: Yup.string().nullable().required(ValidationMessages.required),
});

const serviceBaseOptions: DropdownItemProps[] = [
  {
    key: 1,
    text: 'Revisión de falla',
    value: 'issues',
  },
  {
    key: 2,
    text: 'Entrega de Insumos',
    value: 'supplyRequests',
  },
];

export const ServicePanel: FC<ComponentProps> = ({
  visible,
  onClose,
  service,
  issues,
  createService,
  deleteService,
  updateService,
  deletingService,
  serviceStates,
  serviceReasons,
  technicians,
  supplyRequests,
  users,
  franchises,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      delete values.serviceReason;
      helpers.setSubmitting(true);
      if (service) updateService(values).then(onClose);
      else createService(values).then(onClose);
    },
    [service, createService, updateService, onClose],
  );

  const serviceStateOptions = useMemo<DropdownItemProps[]>(() => {
    return serviceStates.map((state) => ({
      key: state.id,
      text: state.name,
      value: state.id,
    }));
  }, [serviceStates]);

  const serviceReasonOptions = useMemo<DropdownItemProps[]>(() => {
    return serviceReasons.map((reason) => ({
      key: reason.id,
      text: reason.name,
      value: reason.id,
    }));
  }, [serviceReasons]);

  const issueOptions = useMemo<DropdownItemProps[]>(() => {
    return issues.map((issue) => ({
      key: issue.id,
      text: issue.idLabel,
      value: issue.id,
    }));
  }, [issues]);

  const userOptions = useMemo<DropdownItemProps[]>(() => {
    return users.map((user) => ({
      key: user.id,
      text: `${user.firstName} ${user.lastName}`,
      value: user.id,
    }));
  }, [users]);

  const techinicianOptions = useMemo<DropdownItemProps[]>(() => {
    return technicians.map((technician) => ({
      key: technician.id,
      text: `${technician.firstName} ${technician.lastName}`,
      value: technician.id,
    }));
  }, [technicians]);

  const supplyRequestOptions = useMemo<DropdownItemProps[]>(() => {
    return supplyRequests.map((request) => ({
      key: request.id,
      text: `SR${request.id.padStart(6, '0')}`,
      value: request.id,
    }));
  }, [supplyRequests]);

  const franchiseOpetions = useMemo<DropdownItemProps[]>(() => {
    return franchises.map((franchise) => ({
      key: franchise.id,
      text: franchise.name,
      value: franchise.id,
    }));
  }, [franchises]);

  useEffect(() => {
    if (service) {
      formRef.current.setValues({
        description: service.description,
        scheduledTime: moment(service.scheduledTime).toDate(),
        stateId: service.state?.id,
        franchiseId: service.franchise?.id,
        issueId: service.issueId,
        technicianId: service.technician?.id,
        assignedUserId: service.assignedUser?.id.toString(),
        reasonId: service.reason?.id,
        supplyRequestId: service.supplyRequestId,
        maintenanceReason: true,
        serviceReason: service.issueId ? 'issues' : 'supplyRequests',
      });
    }
  }, [service]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">{service?.caseNumber ?? 'Crear Serivicio'}</div>
      {service && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingService}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content={
              <div className="content">
                Desea eliminar el Servicio{' '}
                <span className={styles.deletingRole}>{service.caseNumber}</span>?
              </div>
            }
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteService().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, isSubmitting, setFieldValue }: FormikProps<FormValues>) => (
          <Form onSubmit={handleSubmit}>
            <FastField
              name="assignedUserId"
              label="Usuario que recibe"
              placeholder="seleccione usuario"
              options={userOptions}
              component={SelectorField}
              className="margin"
              value={values.assignedUserId}
              search
            />
            <FastField
              name="stateId"
              label="Estado"
              placeholder="Seleccione estado"
              options={serviceStateOptions}
              component={SelectorField}
              value={values.stateId}
              className="margin"
            />
            <FastField
              name="reasonId"
              label="Razon"
              placeholder="Seleccione razon"
              options={serviceReasonOptions}
              component={SelectorField}
              value={values.reasonId}
              className="margin"
            />
            <FastField
              name="scheduledTime"
              label="Fecha de atención"
              component={DatePickerField}
              value={values.scheduledTime}
            />
            <FastField
              name="franchiseId"
              label="Franquicia"
              placeholder="seleccione Franquicia"
              options={franchiseOpetions}
              value={values.franchiseId}
              component={SelectorField}
              search
            />
            <FastField
              name="serviceReason"
              label="Motivo del servicio"
              component={SelectorField}
              options={serviceBaseOptions}
              value={values.serviceReason}
              onChange={(value: string) => {
                if (value === 'issues') {
                  setFieldValue('supplyRequestId', null);
                } else {
                  setFieldValue('issueId', null);
                }
              }}
            />
            {values.serviceReason === 'issues' && (
              <FastField
                name="issueId"
                label="Falla"
                placeholder="seleccione falla"
                options={issueOptions}
                component={SelectorField}
                value={values.issueId}
                search
              />
            )}
            {values.serviceReason === 'supplyRequests' && (
              <FastField
                name="supplyRequestId"
                label="Solicitud de Insumo"
                placeholder="Seleccione Solicitud"
                options={supplyRequestOptions}
                value={values.supplyRequestId}
                component={SelectorField}
                search
              />
            )}
            <FastField
              name="technicianId"
              label="Técnico Asignado"
              placeholder="seleccione un técnico"
              options={techinicianOptions}
              component={SelectorField}
              value={values.technicianId}
              search
            />
            <FastField
              name="description"
              label="Descripción"
              component={TextAreaField}
              value={values.description}
            />
            <div className="buttons-container">
              <Button size="medium" icon onClick={onClose} type="button">
                Cancelar &nbsp;
                <Icon name="times" />
              </Button>
              <Button primary size="medium" icon loading={isSubmitting}>
                Guardar &nbsp;
                <Icon name="save" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </SidePanel>
  );
};
