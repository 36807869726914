import React, { FC } from 'react';
import {
  permissionsQueryGQL,
  permissionCreatedSubGQL,
  permissionUpdatedSubGQL,
  permissionDeletedSubGQL,
} from 'graphql/permission';
import { useQuery, useSubscription } from '@apollo/client';
import { usePagination } from 'utils/hooks/pagination';
import { PermissionsPage as Component } from './permissions';

export const PermissionsPage: FC = () => {
  const { data, loading, refetch } = useQuery<
    { permissions: PaginatedEntityList<Permission> },
    PaginatedVariables
  >(permissionsQueryGQL, {
    variables: { pageSize: parseFloat(process.env.REACT_APP_TABLE_PAGE_SIZE) },
  });

  const { search, changePage } = usePagination(refetch);

  useSubscription(permissionUpdatedSubGQL);
  useSubscription(permissionCreatedSubGQL, { onSubscriptionData: () => refetch() });
  useSubscription(permissionDeletedSubGQL, { onSubscriptionData: () => refetch() });

  return (
    <Component
      permissions={data?.permissions.data || []}
      pagination={
        loading
          ? null
          : { pageNumber: data?.permissions.pageNumber, totalPages: data?.permissions.totalPages }
      }
      onSearch={search}
      onPageChange={changePage}
    />
  );
};
