import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './devices.scss';

import { DevicesMenu } from './menu';
import { DeviceTypesPage } from './device-types';
import { DeviceReferencesPage } from './device-references';
import { DeviceUnitsPage } from './device-units';

export const DevicesPage: FC = () => {
  return (
    <div className="devices-page">
      <div className="devices-content-section">
        <Switch>
          <Route path="/devices" exact>
            <Redirect to="/devices/units" />
          </Route>
          <Route path="/devices/units">
            <DeviceUnitsPage />
          </Route>
          <Route path="/devices/types">
            <DeviceTypesPage />
          </Route>
          <Route path="/devices/references">
            <DeviceReferencesPage />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
