import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { TextAreaField } from 'components/text-area-field';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, Form, Icon } from 'semantic-ui-react';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  technicalTutorial: TechnicalTutorial;
  updateTechnicalTutorial: (tutorial: TechnicalTutorial) => Promise<void>;
  createTechnicalTutorial: (tutorial: TechnicalTutorial) => Promise<void>;
  deleteTechnicalTutorial: () => Promise<void>;
  deletingTechnicalTutorial: boolean;
};

interface FormValues {
  title: string;
  description: string;
}

const initialValues: FormValues = {
  title: '',
  description: '',
};

export const TechnicalTutorialPanel: FC<ComponentProps> = ({
  technicalTutorial,
  createTechnicalTutorial,
  deleteTechnicalTutorial,
  deletingTechnicalTutorial,
  updateTechnicalTutorial,
  visible,
  onClose,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      if (technicalTutorial) updateTechnicalTutorial(values).then(onClose);
      else createTechnicalTutorial(values).then(onClose);
    },
    [updateTechnicalTutorial, createTechnicalTutorial, onClose, technicalTutorial],
  );

  useEffect(() => {
    if (technicalTutorial) {
      formRef.current.setValues({
        title: technicalTutorial.title,
        description: technicalTutorial.description,
      });
    }
  }, [technicalTutorial]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">{technicalTutorial?.title ?? 'Crear Tutorial Técnico'}</div>
      {technicalTutorial && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingTechnicalTutorial}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar el permiso seleccionado?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteTechnicalTutorial().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit} innerRef={formRef}>
        {({ handleSubmit, values, isSubmitting }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <FastField
                name="title"
                label="Titulo"
                component={InputField}
                value={values.title}
                placeholder="Titulo"
              />
              <FastField
                name="description"
                label="Descripción"
                component={TextAreaField}
                value={values.description}
                placeholder="Descripción"
              />
              <div className="buttons-container">
                <Button size="medium" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="medium" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
