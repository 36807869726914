import React, { FC, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { sessionUserGQL, logoutGQL } from 'graphql/auth';
import { MainMenu as Component } from './main-menu';

export const MainMenu: FC = () => {
  const { data, client } = useQuery<{ sessionUser: User }>(sessionUserGQL);
  const [logoutMutation] = useMutation(logoutGQL);

  const logout = useCallback(async () => {
    await logoutMutation();
    await client.clearStore();
  }, [logoutMutation]);

  console.log(data);

  return <Component sessionUser={data?.sessionUser ?? null} logout={logout} />;
};
