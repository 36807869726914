import React, { FC } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  allDemoTypesGQL,
  demoTypeCreatedGQL,
  demoTypeUpdatedGQL,
  demoTypeDeletedGQL,
} from 'graphql/demo-type';

import { DemoTypes as Component } from './demo-types';

export const DemoTypes: FC = () => {
  const { data, refetch } = useQuery<{ demoTypes: DemoType[] }>(allDemoTypesGQL);

  useSubscription(demoTypeUpdatedGQL);
  useSubscription(demoTypeCreatedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(demoTypeDeletedGQL, { onSubscriptionData: () => refetch() });

  return <Component demoTypes={data?.demoTypes ?? []} />;
};
