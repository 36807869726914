import React, { FC, useCallback, useState } from 'react';
import pageStyles from 'assets/styles/page.module.scss';
import { Button, Table } from 'semantic-ui-react';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import { ServiceNoveltyTypePanel } from './service-novelty-panel';

export type ComponentProps = {
  serviceNovelTyTypes: ServiceNoveltyType[];
  loading: boolean;
};

export const ServiceNoveltyTypes: FC<ComponentProps> = ({ serviceNovelTyTypes }) => {
  const [showServiceNoveltyTypePanel, setShowServiceNoveltyTypePanel] = useState(false);
  const [selectedServiceNoveltyType, setSelectedServiceNoveltyType] = useState<string>(null);

  const openServiceNoveltyTypeDetails = useCallback((noveltyTypeId: string) => {
    setSelectedServiceNoveltyType(noveltyTypeId);
    setShowServiceNoveltyTypePanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Tipos de Novedad</div>
        <div className={pageStyles.tableHeader}>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedServiceNoveltyType(null);
              setShowServiceNoveltyTypePanel(true);
            }}
          >
            Crear Tipo de Novedad <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>

      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Descripción</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {serviceNovelTyTypes.map((novelTyType) => (
                <Table.Row key={novelTyType.id}>
                  <Table.Cell>{novelTyType.name}</Table.Cell>
                  <Table.Cell>{novelTyType.description}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openServiceNoveltyTypeDetails(novelTyType.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <ServiceNoveltyTypePanel
        serviceNoveltyId={selectedServiceNoveltyType}
        visible={showServiceNoveltyTypePanel}
        onClose={() => {
          setSelectedServiceNoveltyType(null);
          setShowServiceNoveltyTypePanel(false);
        }}
      />
    </div>
  );
};
