import React, { FC, useCallback, useState } from 'react';
import pageStyles from 'assets/styles/page-old.module.scss';
import { Button, Input, Pagination, PaginationProps, Table, Label } from 'semantic-ui-react';
import { PlusCircle, Sliders } from 'react-feather';

export type ComponentProps = {
  devices: Device[];
  pagination: ListPagination;
  onPageChange: (page: number) => void;
};

export const DeviceCommercePage: FC<ComponentProps> = ({ devices, pagination, onPageChange }) => {
  const [showFiltersPanel, setShowFiltersPanel] = useState(false);

  const handlePageChange = useCallback(
    (_, data: PaginationProps) => {
      onPageChange((data.activePage as number) - 1);
    },
    [onPageChange],
  );

  console.log(devices);

  return (
    <div className={`page ${pageStyles.pageContainer}`}>
      <div className="page-title">Dispositivos de Comercio</div>
      <div className="table-header"></div>
      <Table singleLine className="page-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nombre</Table.HeaderCell>
            <Table.HeaderCell>Tipo</Table.HeaderCell>
            <Table.HeaderCell>Referencia</Table.HeaderCell>
            <Table.HeaderCell>Serial</Table.HeaderCell>
            <Table.HeaderCell>No. Placa</Table.HeaderCell>
            <Table.HeaderCell>Cod. Seguridad</Table.HeaderCell>
            <Table.HeaderCell>Cod. PinPad</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {devices.map((device) => (
            <Table.Row key={device.id}>
              <Table.Cell width={2}>GPRS</Table.Cell>
              <Table.Cell width={2}>{device.deviceReference.name}</Table.Cell>
              <Table.Cell width={2}>{device.serial}</Table.Cell>
              <Table.Cell width={2}>{device.plateNumber}</Table.Cell>
              <Table.Cell width={2}>{device.securityCode}</Table.Cell>
              <Table.Cell width={2}>{device.pinPadCode}</Table.Cell>
              <Table.Cell className="details-action" width={1}>
                Ver Detalles
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {pagination && (
        <div className={pageStyles.paginationContainer}>
          <Pagination
            totalPages={pagination.totalPages}
            activePage={pagination.currentPage + 1}
            firstItem={null}
            lastItem={null}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};
