import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './commerces.scss';
import { FranchisesPage } from './franchises';
import { CommerceUnitsPage } from './commerce-units';
import { FranchiseTypePage } from './franchise-types';

export const CommercesPage: FC = () => {
  return (
    <div className="commerces-page">
      <div className="commerces-content-section">
        <Switch>
          <Route path="/commerces" exact>
            <Redirect to="/commerces/units" />
          </Route>
          <Route path="/commerces/units">
            <CommerceUnitsPage />
          </Route>
          <Route path="/commerces/franchises">
            <FranchisesPage />
          </Route>
          <Route path="/commerces/franchise-types">
            <FranchiseTypePage />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
