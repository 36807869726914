import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, DropdownItemProps, Form, Icon } from 'semantic-ui-react';
import { Checkbox } from 'components/checkbox';
import DefaultUserAvatar from 'assets/images/user.jpg';
import randomstring from 'randomstring';
import * as Yup from 'yup';
import styles from './person-panel.module.scss';
import { SelectorField } from '../../../../components/selector-field';
import { ValidationMessages } from '../../../../utils/validation-messages';

export type ComponentProps = {
  visible: boolean;
  onClose: () => void;
  user: User;
  updateUser: (user: User) => Promise<void>;
  createUser: (user: User) => Promise<void>;
  deleteUser: () => Promise<void>;
  checkEmailAvailavility: (email: string) => Promise<EmailAvailability>;
  deletingUser: boolean;
  roles: Role[];
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  enabled: boolean;
  phoneNumber: string;
  profileImage: File;
  roles: number[];
};

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  enabled: true,
  phoneNumber: '',
  profileImage: null,
  roles: [],
};

export const PersonPanel: FC<ComponentProps> = ({
  visible,
  onClose,
  user,
  createUser,
  deleteUser,
  deletingUser,
  updateUser,
  checkEmailAvailavility,
  roles,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const fileRef = useRef<HTMLInputElement>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);
  const [userAvatarUrl, setUserAvatarUrl] = useState(user?.profileImageUrl ?? DefaultUserAvatar);

  const roleOptions = useMemo<DropdownItemProps[]>(() => {
    return roles.map((role) => ({
      key: role.id,
      text: role.name,
      value: parseFloat(role.id),
    }));
  }, [roles]);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      const submit = async () => {
        const { roles: rolesValue, profileImage, ...rest } = values;
        helpers.setSubmitting(true);
        if (user) {
          updateUser({ ...rest, roles: rolesValue as any, profileImage }).then(onClose);
        } else {
          createUser({
            ...rest,
            roles: rolesValue as any,
            password: randomstring.generate(7),
            profileImage,
          }).then(onClose);
        }
      };
      submit();
    },
    [user, updateUser, createUser, onClose],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape<FormValues>({
        firstName: Yup.string().required(ValidationMessages.required),
        lastName: Yup.string().required(ValidationMessages.required),
        email: Yup.string()
          .required(ValidationMessages.required)
          .email(ValidationMessages.email)
          .test('isAvailable', 'El email no esta disponible', (value) =>
            user === null && value !== undefined
              ? checkEmailAvailavility(value).then((result) => result.available)
              : true,
          ),
        username: Yup.string().required(ValidationMessages.required),
        enabled: Yup.boolean(),
        phoneNumber: Yup.string().required(ValidationMessages.required),
        profileImage: null,
        roles: Yup.array().of(Yup.number()),
      }),
    [checkEmailAvailavility, user],
  );

  useEffect(() => {
    if (user) {
      formRef.current.setValues({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        username: user.username,
        enabled: user.enabled,
        phoneNumber: user.phoneNumber,
        profileImage: null,
        roles: user.roles.map(({ id }) => parseFloat(id)),
      });

      setUserAvatarUrl(user.profileImageUrl ?? DefaultUserAvatar);
    } else {
      setUserAvatarUrl(DefaultUserAvatar);
    }
  }, [user]);

  return (
    <SidePanel onClose={onClose} visible={visible}>
      <div className="panel-title">{user?.username ?? 'Crear Usuario'}</div>
      {user && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingUser}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content={
              <div className="content">
                Desea eliminar el Usuario{' '}
                <span className={styles.deletingRole}>{user.username}</span>?
              </div>
            }
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteUser().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, isSubmitting, setFieldValue }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <div>
                <div className={styles.profileImageSelector}>
                  <div className={styles.avatarContainer}>
                    <img src={userAvatarUrl} alt="" className={styles.avatar} />
                  </div>
                  <Button
                    icon="camera"
                    circular
                    size="small"
                    className={styles.cameraButton}
                    onClick={() => fileRef.current.click()}
                    type="button"
                  />
                </div>
                <div hidden>
                  <input
                    type="file"
                    id="avatar"
                    name="avatar"
                    accept="image/png, image/jpeg"
                    ref={fileRef}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('profileImage', event.target.files[0]);
                      setUserAvatarUrl(URL.createObjectURL(event.target.files[0]));
                    }}
                  />
                </div>
                <FastField
                  name="firstName"
                  label="Nombre"
                  component={InputField}
                  value={values.firstName}
                  placeholder="Nombre"
                />
                <FastField
                  name="lastName"
                  label="Apellido"
                  component={InputField}
                  value={values.lastName}
                  placeholder="Apellido"
                />
                <FastField
                  name="email"
                  label="Email"
                  component={InputField}
                  value={values.email}
                  placeholder="Correo Electronico"
                />
                <FastField
                  name="username"
                  label="Username"
                  component={InputField}
                  value={values.username}
                  placeholder="Username"
                />
                <FastField
                  name="phoneNumber"
                  label="Número de Telefono"
                  component={InputField}
                  value={values.phoneNumber}
                  placeholder="Número de telefono"
                />
                <FastField
                  name="roles"
                  label="Roles"
                  component={SelectorField}
                  value={values.roles}
                  placeholder="seleccione"
                  multiple
                  options={roleOptions}
                />
                <div className={`field ${styles.inlineLabel}`}>
                  <label>Habilitado</label>
                  <Checkbox
                    selected={values.enabled}
                    onSelect={() => setFieldValue('enabled', true)}
                    onDeselect={() => setFieldValue('enabled', false)}
                  />
                </div>
              </div>
              <div className="buttons-container">
                <Button size="medium" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="medium" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
