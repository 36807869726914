import React, { FC, useCallback, useMemo, useState } from 'react';
import { User } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import HowToRegOutlinedIcon from '@material-ui/icons/HowToRegOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CenterFocusWeakOutlinedIcon from '@material-ui/icons/CenterFocusWeakOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import BugReportOutlinedIcon from '@material-ui/icons/BugReportOutlined';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import PermDeviceInformationOutlinedIcon from '@material-ui/icons/PermDeviceInformationOutlined';
import VibrationOutlinedIcon from '@material-ui/icons/VibrationOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import BallotOutlined from '@material-ui/icons/BallotOutlined';
import DeveloperBoardOutlined from '@material-ui/icons/DeveloperBoardOutlined';
import ExitToAppOutlined from '@material-ui/icons/ExitToAppOutlined';
import ChatOutlined from '@material-ui/icons/ChatOutlined';
import HeadsetMicOutlined from '@material-ui/icons/HeadsetMicOutlined';
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';

import { EditProfile } from 'pages/edit-profile';

import styles from './main-menu.module.scss';

import { MenuItem } from './menu-item';

type ComponentProps = {
  sessionUser: User;
  logout: () => Promise<void>;
};

export const MainMenu: FC<ComponentProps> = ({ sessionUser, logout }) => {
  const location = useLocation();
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState(location.pathname.split('/')[1]);
  const permissions = useMemo(() => {
    if (!sessionUser) {
      return [];
    }

    const permissionKeys: string[] = [];
    sessionUser.roles.forEach((role) => {
      role.permissions.forEach((permission) => {
        permissionKeys.push(permission.key);
      });
    });

    return permissionKeys;
  }, [sessionUser]);

  const handleLogout = useCallback(() => {
    logout().then(() => {
      history.replace('/login');
    });
  }, [logout, history]);

  return (
    <div className={styles.mainMenu}>
      <div className={styles.itemsContainer}>
        <MenuItem
          active={selectedItem === 'users'}
          icon={<PeopleOutlinedIcon />}
          title="Usuarios"
          onSelect={() => setSelectedItem('users')}
          subItems={[
            {
              name: 'Personas',
              path: '/users/persons',
              icon: <PersonOutlineOutlinedIcon />,
              hidden: !permissions.includes('user_list'),
            },
            {
              name: 'Roles',
              path: '/users/roles',
              icon: <HowToRegOutlinedIcon />,
              hidden: !permissions.includes('role_list'),
            },
            {
              name: 'Permisos',
              path: '/users/permissions',
              icon: <LockOpenOutlinedIcon />,
              hidden: !permissions.includes('permission_list'),
            },
          ]}
        />
        <MenuItem
          active={selectedItem === 'technicians'}
          icon={<ContactsOutlinedIcon />}
          title="Técnicos"
          onSelect={() => setSelectedItem('technicians')}
          subItems={[
            {
              name: 'Personas',
              path: '/technicians/persons',
              icon: <PersonOutlineOutlinedIcon />,
              hidden: !permissions.includes('technician_list'),
            },
          ]}
        />
        <MenuItem
          active={selectedItem === 'tutorials'}
          icon={<BallotOutlined />}
          title="Tutoriales"
          onSelect={() => setSelectedItem('tutorials')}
          subItems={[
            {
              name: 'Tutoriales Técnicos',
              path: '/tutorials/technical-tutorials',
              icon: <DeveloperBoardOutlined />,
              hidden: !permissions.includes('technical_tutorial_list'),
            },
            {
              name: 'Tipos de Demo',
              path: '/tutorials/demo-types',
              icon: <DeveloperBoardOutlined />,
              hidden: !permissions.includes('demo_type_list'),
            },
            {
              name: 'Demo Tutoriales',
              path: '/tutorials/demo-tutorials',
              icon: <DeveloperBoardOutlined />,
              hidden: !permissions.includes('demo_list'),
            },
          ]}
        />
        <MenuItem
          active={selectedItem === 'services'}
          icon={<EventNoteOutlinedIcon />}
          title="Servicios"
          onSelect={() => setSelectedItem('services')}
          subItems={[
            {
              name: 'Servicios',
              path: '/services/units',
              icon: <EventNoteOutlinedIcon />,
              hidden: !permissions.includes('service_list'),
            },
            {
              name: 'Estados',
              path: '/services/states',
              icon: <AssignmentTurnedInOutlinedIcon />,
              hidden: !permissions.includes('service_state_list'),
            },
            {
              name: 'Razones',
              path: '/services/reasons',
              icon: <AssignmentTurnedInOutlinedIcon />,
              hidden: !permissions.includes('service_reason_list'),
            },
            {
              name: 'Tipos de Novedad',
              path: '/services/novelty-types',
              icon: <AssignmentLateOutlinedIcon />,
              hidden: !permissions.includes('novelty_type_list'),
            },
          ]}
        />
        <MenuItem
          active={selectedItem === 'issues'}
          icon={<BugReportOutlinedIcon />}
          title="Fallas"
          onSelect={() => setSelectedItem('issues')}
          subItems={[
            {
              name: 'Fallas Reportadas',
              path: '/issues/units',
              icon: <ListAltOutlinedIcon />,
              hidden: !permissions.includes('issue_list'),
            },
            {
              name: 'Tipos de Falla',
              path: '/issues/types',
              icon: <CenterFocusWeakOutlinedIcon />,
              hidden: !permissions.includes('issue_type_list'),
            },
            {
              name: 'Fallas Comunes',
              path: '/issues/common-issues',
              icon: <FeaturedPlayListOutlinedIcon />,
              hidden: !permissions.includes('common_issue_list'),
            },
          ]}
        />
        <MenuItem
          active={selectedItem === 'commerces'}
          icon={<StorefrontOutlinedIcon />}
          title="Clientes"
          onSelect={() => setSelectedItem('commerces')}
          subItems={[
            {
              name: 'Clientes',
              path: '/commerces/units',
              icon: <StorefrontOutlinedIcon />,
              hidden: !permissions.includes('customer_list'),
            },
            {
              name: 'Sedes',
              path: '/commerces/franchises',
              icon: <AccountTreeOutlinedIcon />,
              hidden: !permissions.includes('franchise_list'),
            },
            {
              name: 'Tipos de Sede',
              path: '/commerces/franchise-types',
              icon: <AccountTreeOutlinedIcon />,
              hidden: !permissions.includes('franchise_type_list'),
            },
          ]}
        />
        <MenuItem
          active={selectedItem === 'devices'}
          icon={<PhoneIphoneOutlinedIcon />}
          title="Dispositivos"
          onSelect={() => setSelectedItem('devices')}
          subItems={[
            {
              name: 'Unidades',
              path: '/devices/units',
              icon: <PhoneIphoneOutlinedIcon />,
              hidden: !permissions.includes('device_list'),
            },
            {
              name: 'Referencias',
              path: '/devices/references',
              icon: <PermDeviceInformationOutlinedIcon />,
              hidden: !permissions.includes('device_reference_list'),
            },
            {
              name: 'Tipos',
              path: '/devices/types',
              icon: <VibrationOutlinedIcon />,
              hidden: !permissions.includes('device_type_list'),
            },
          ]}
        />
        <MenuItem
          active={selectedItem === 'supplies'}
          icon={<LocalMallOutlinedIcon />}
          title="Suministros"
          onSelect={() => setSelectedItem('supplies')}
          subItems={[
            {
              name: 'Unidades',
              path: '/supplies/units',
              icon: <LocalMallOutlinedIcon />,
              hidden: !permissions.includes('supply_list'),
            },
            {
              name: 'Tipos',
              path: '/supplies/types',
              icon: <ListAltOutlinedIcon />,
              hidden: !permissions.includes('supply_type_list'),
            },
            {
              name: 'Solicitudes',
              path: '/supplies/requests',
              icon: <AssignmentOutlinedIcon />,
              hidden: !permissions.includes('supply_request_list'),
            },
          ]}
        />
        <MenuItem
          active={selectedItem === 'chat'}
          icon={<ChatOutlined />}
          title="Chat"
          onSelect={() => setSelectedItem('chat')}
          subItems={[
            {
              name: 'Chat de Soporte',
              path: '/chat/service-chat',
              icon: <HeadsetMicOutlined />,
              hidden: !permissions.includes('support_chat'),
            },
          ]}
        />
      </div>
      <div className={styles.buttonSection}>
        {sessionUser && (
          <div className={styles.userInfo}>
            <div className={styles.avatarContainer}>
              <img src={sessionUser.profileImageUrl} alt="" className={styles.avatar} />
            </div>
            <div
              className={styles.userFullName}
            >{`${sessionUser.firstName} ${sessionUser.lastName}`}</div>
          </div>
        )}
        <div className={styles.logoutItem}>
          <div className={styles.label}>Cerrar Sesión</div>
          <ExitToAppOutlined className={styles.icon} onClick={handleLogout} />
        </div>
        <EditProfile />
      </div>
    </div>
  );
};
