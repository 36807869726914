import React, { FC, useCallback } from 'react';
import { FastField, Formik } from 'formik';
import { Button, Form, Icon } from 'semantic-ui-react';
import * as Yup from 'yup';
import { InputField } from 'components/input-field';

export type ComponentProps = {
  changePassword: (passsword: string) => void;
  changingPassword: boolean;
};

type FormValues = {
  password: string;
  confirmPassword: string;
};

const initialValues: FormValues = {
  password: '',
  confirmPassword: '',
};

const validationSchema = Yup.object().shape<FormValues>({
  password: Yup.string().required('Este campo es requerido'),
  confirmPassword: Yup.string()
    .required('Este campo es requerido')
    .oneOf([Yup.ref('password')], 'Passwords no coinciden'),
});

export const PasswordForm: FC<ComponentProps> = ({ changePassword, changingPassword }) => {
  const handleFormSubmit = useCallback(
    (values: FormValues) => {
      changePassword(values.password);
    },
    [changePassword],
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <div>
              <FastField
                name="password"
                label="Contraseña"
                component={InputField}
                value={values.password}
                placeholder="Nueva Contraseña"
                type="password"
              />
              <FastField
                name="confirmPassword"
                label="Confirmar Contraseña"
                component={InputField}
                value={values.confirmPassword}
                placeholder="Confirmar Contraseña"
                type="password"
              />
            </div>
            <div className="buttons-container">
              <Button primary size="medium" icon loading={changingPassword}>
                Guardar &nbsp;
                <Icon name="save" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
