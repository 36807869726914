import React, { FC } from 'react';
import pageStyles from 'assets/styles/page.module.scss';
import styles from './service-chat.module.scss';
import { PendingChat } from './pending-chat';
import { ActiveChat } from './active-chat';

export type ComponentProps = {
  pendingChats: Chat[];
  activeChat: Chat;
  initChat: (chatId: string) => void;
  finishChat: (chatId: string) => void;
  canInit: boolean;
};

export const ServiceChat: FC<ComponentProps> = ({
  pendingChats,
  initChat,
  activeChat,
  canInit,
  finishChat,
}) => {
  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Chat de Soporte</div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={styles.serviceChatContainer}>
          <div className={styles.chatsQueue}>
            <div className={styles.queueList}>
              {pendingChats.map((chat) => (
                <PendingChat
                  chat={chat}
                  key={chat.id}
                  onInit={() => initChat(chat.id)}
                  canInit={canInit}
                />
              ))}
            </div>
          </div>
          <div className={styles.chatBox}>
            {activeChat && <ActiveChat chat={activeChat} finishChat={finishChat} />}
          </div>
        </div>
      </div>
    </div>
  );
};
