import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UsersMenu } from './menu';

import { RequestServicePage } from './services';
import './requests.page.scss';

export const RequestsPage: FC = () => {
  return (
    <div className="requests-page">
      <div className="requests-menu-section">
        <UsersMenu />
      </div>
      <div className="requests-content-section">
        <Switch>
          <Route path="/requests" exact>
            <Redirect to="/requests/services" />
          </Route>
          <Route path="/requests/services">
            <RequestServicePage />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
