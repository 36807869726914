import React, { FC } from 'react';
import styles from './panel-item.module.scss';

export type ComponentProps = {
  onClick: () => void;
  text: string;
  icon: React.ReactElement;
};

export const PanelItem: FC<ComponentProps> = ({ text, icon, onClick }) => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>{text}</div>
      <div className={styles.iconContainer} aria-hidden onClick={onClick}>
        {icon}
      </div>
    </div>
  );
};
