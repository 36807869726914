import React, { FC, useCallback, useState } from 'react';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import pageStyles from 'assets/styles/page.module.scss';
import { Button, Input, Pagination, Table } from 'semantic-ui-react';
import { SupplyUnitsPanel } from './supply-units-panel';

export type ComponentProps = {
  supplies: Supply[];
  pagination: PaginationProps;
  onSearch: (searchTerm: string) => void;
  onPageChange: (page: number) => void;
};

export const SupplyUnitsPage: FC<ComponentProps> = ({
  supplies,
  pagination,
  onPageChange,
  onSearch,
}) => {
  const [showSupplynPanel, setShowSupplyPanel] = useState(false);
  const [selectedSupply, setSelectedSupply] = useState<string>(null);

  const openSupplyPanel = useCallback((supplyId: string) => {
    setSelectedSupply(supplyId);
    setShowSupplyPanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Unidades</div>
        <div className={pageStyles.tableHeader}>
          <div className={pageStyles.controls}>
            <Input
              icon="search"
              placeholder="Buscar"
              className={pageStyles.searchInput}
              onChange={(_, data) => onSearch(data.value)}
            />
          </div>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedSupply(null);
              setShowSupplyPanel(true);
            }}
          >
            Crear Suministro <PersonAddOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Tipo</Table.HeaderCell>
                <Table.HeaderCell>Cantidad</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {supplies.map((supply) => (
                <Table.Row key={supply.id}>
                  <Table.Cell className={pageStyles.boldText}>{supply.name}</Table.Cell>
                  <Table.Cell>{supply.supplyType?.name}</Table.Cell>
                  <Table.Cell>{supply.stockAmount}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openSupplyPanel(supply.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        {pagination && (
          <div className={pageStyles.paginationContainer}>
            <Pagination
              totalPages={pagination.totalPages}
              activePage={pagination.pageNumber}
              firstItem={null}
              lastItem={null}
              onPageChange={(event, data) => onPageChange(data.activePage as number)}
            />
          </div>
        )}
      </div>
      <SupplyUnitsPanel
        visible={showSupplynPanel}
        onClose={() => {
          setSelectedSupply(null);
          setShowSupplyPanel(false);
        }}
        supplyId={selectedSupply}
      />
    </div>
  );
};
