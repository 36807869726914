import React, { FC, useCallback, useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import pageStyles from 'assets/styles/page.module.scss';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import { DeviceTypePanel } from './type-panel';
import './device-types.scss';

export type ComponentProps = {
  deviceTypes: DeviceType[];
};

export const DeviceTypesPage: FC<ComponentProps> = ({ deviceTypes }) => {
  const [showDeviceTypePanel, setShowDeviceTypePanel] = useState(false);
  const [selectedDeviceType, setSelectedDeviceType] = useState<string>(null);

  const openDeviceTypePanel = useCallback((deviceTypeId: string) => {
    setSelectedDeviceType(deviceTypeId);
    setShowDeviceTypePanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Tipos de Dispositivo</div>
        <div className={pageStyles.tableHeader}>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedDeviceType(null);
              setShowDeviceTypePanel(true);
            }}
          >
            Crear Tipo <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table className={pageStyles.pageTable} singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {deviceTypes.map((devicetype) => (
                <Table.Row key={devicetype.id}>
                  <Table.Cell>{devicetype.name}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openDeviceTypePanel(devicetype.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>

      <DeviceTypePanel
        visible={showDeviceTypePanel}
        onClose={() => {
          setSelectedDeviceType(null);
          setShowDeviceTypePanel(false);
        }}
        deviceTypeId={selectedDeviceType}
      />
    </div>
  );
};
