import React, { FC } from 'react';
import { ErrorMessage, FastFieldProps } from 'formik';
import { DropdownItemProps, Form } from 'semantic-ui-react';
import fieldStyles from './selector-field.module.scss';

type ComponentProps = {
  label?: string;
  placeholder?: string;
  options: DropdownItemProps[];
  search?: boolean;
  multiple?: boolean;
  allowAditions?: boolean;
  onAdd?: (value: any) => void;
  onChange?: (value: any) => void;
} & FastFieldProps;

export const SelectorField: FC<ComponentProps> = ({
  field,
  form,
  label,
  options,
  placeholder,
  search,
  multiple,
  allowAditions,
  onAdd,
  onChange,
}) => {
  const fieldMeta = form.getFieldMeta(field.name);

  return (
    <div className={fieldStyles.container}>
      <Form.Dropdown
        value={field.value}
        selection
        fluid
        search={search}
        multiple={multiple}
        placeholder={placeholder}
        options={options}
        label={label}
        onChange={(_, data) => {
          form.setFieldValue(field.name, data.value);
          onChange(data.value);
        }}
        onBlur={() => form.setFieldTouched(field.name)}
        allowAdditions={allowAditions}
        onAddItem={(_, { value }) => onAdd(value)}
      />

      {fieldMeta.error && fieldMeta.touched && (
        <span className={fieldStyles.errorMessage}>
          <ErrorMessage name={field.name} />
        </span>
      )}
    </div>
  );
};

SelectorField.defaultProps = {
  label: '',
  placeholder: '',
  search: false,
  multiple: false,
  allowAditions: false,
  onAdd: () => {},
  onChange: () => {},
};
