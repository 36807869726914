import React, { FC } from 'react';
import {
  supplyRequestCreatedGQL,
  supplyRequestDeletedGQL,
  supplyRequestsGQL,
  supplyRequestUpdatedGQL,
} from 'graphql/supply-request';

import { useQuery, useSubscription } from '@apollo/client';
import { usePagination } from 'utils/hooks/pagination';
import { SupplyRequestsPage as Component } from './supply-requests';

export const SupplyRequestsPage: FC = () => {
  const { data, loading, refetch } = useQuery<
    { supplyRequests: PaginatedEntityList<SupplyRequest> },
    PaginatedVariables
  >(supplyRequestsGQL, {
    variables: { pageSize: parseFloat(process.env.REACT_APP_TABLE_PAGE_SIZE) },
  });

  const { search, changePage } = usePagination(refetch);
  useSubscription(supplyRequestUpdatedGQL);
  useSubscription(supplyRequestDeletedGQL, { onSubscriptionData: () => refetch() });
  useSubscription(supplyRequestCreatedGQL, { onSubscriptionData: () => refetch() });

  return (
    <Component
      supplyRequests={data?.supplyRequests.data ?? []}
      pagination={
        loading
          ? null
          : {
              pageNumber: data?.supplyRequests.pageNumber,
              totalPages: data?.supplyRequests.totalPages,
            }
      }
      onPageChange={changePage}
      onSearch={search}
    />
  );
};
