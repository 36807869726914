import React, { FC, useCallback, useState } from 'react';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import pageStyles from 'assets/styles/page.module.scss';
import DefaultUserAvatar from 'assets/images/user.jpg';
import { Button, Table } from 'semantic-ui-react';
import { DeviceReferencePanel } from './device-reference-panel';

export type ComponentProps = {
  deviceReferences: DeviceReference[];
};

export const DeviceReferencesPage: FC<ComponentProps> = ({ deviceReferences }) => {
  const [showDeviceReferencePanel, setShowDeviceReferencePanel] = useState(false);
  const [selectedDeviceReference, setSelectedDeviceReference] = useState<string>(null);

  const openDeviceReferencePanel = useCallback((deviceReferenceId: string) => {
    setSelectedDeviceReference(deviceReferenceId);
    setShowDeviceReferencePanel(true);
  }, []);

  return (
    <div className={pageStyles.pageContainer}>
      <div className={pageStyles.pageHeader}>
        <div className={pageStyles.pageTitle}>Referencias de Dispositivos</div>
        <div className={pageStyles.tableHeader}>
          <Button
            className={pageStyles.addButton}
            onClick={() => {
              setSelectedDeviceReference(null);
              setShowDeviceReferencePanel(true);
            }}
          >
            Crear Referencia <LibraryAddOutlinedIcon />
          </Button>
        </div>
      </div>

      <div className={pageStyles.pageContent}>
        <div className={pageStyles.tableContainer}>
          <Table singleLine className={pageStyles.pageTable}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} textAlign="center" />
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Tipo</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {deviceReferences.map((reference) => (
                <Table.Row key={reference.id}>
                  <Table.Cell className={pageStyles.userAvatar} textAlign="center">
                    <img
                      src={reference.imageUrl ?? DefaultUserAvatar}
                      alt="deviceReference"
                      className={pageStyles.avatar}
                    />
                  </Table.Cell>
                  <Table.Cell width={2}>{reference.name}</Table.Cell>
                  <Table.Cell>{reference.deviceType?.name}</Table.Cell>
                  <Table.Cell className={pageStyles.detailsAction}>
                    <div
                      onClick={() => openDeviceReferencePanel(reference.id)}
                      aria-hidden
                      className="details-link"
                    >
                      Ver Detalles
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <DeviceReferencePanel
        visible={showDeviceReferencePanel}
        onClose={() => {
          setSelectedDeviceReference(null);
          setShowDeviceReferencePanel(false);
        }}
        deviceReferenceId={selectedDeviceReference}
      />
    </div>
  );
};
