import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { SidePanel } from 'components/side-panel';
import { InputField } from 'components/input-field';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Confirm, Form, Icon } from 'semantic-ui-react';
import * as Yup from 'yup';
import { ValidationMessages } from 'utils/validation-messages';

interface ComponentProps {
  visible: boolean;
  onClose: () => void;
  supplyType: SupplyType;
  updateSupplyType: (SupplyType: SupplyType) => Promise<void>;
  createSupplyType: (SupplyType: SupplyType) => Promise<void>;
  deleteSupplyType: () => Promise<void>;
  deletingSupplyType: boolean;
}

interface FormValues {
  name: string;
}

const initialValues: FormValues = {
  name: '',
};

const validationSchema = Yup.object().shape<FormValues>({
  name: Yup.string().required(ValidationMessages.required),
});

export const SupplyTypePanel: FC<ComponentProps> = ({
  visible,
  onClose,
  supplyType,
  updateSupplyType,
  createSupplyType,
  deleteSupplyType,
  deletingSupplyType,
}) => {
  const formRef = useRef<FormikProps<FormValues>>();
  const [showDelConfirmation, setShowDelConfirmation] = useState(false);

  const handleFormSubmit = useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      helpers.setSubmitting(true);
      if (supplyType) updateSupplyType(values).then(onClose);
      else createSupplyType(values).then(onClose);
    },
    [supplyType, updateSupplyType, createSupplyType, onClose],
  );

  useEffect(() => {
    if (supplyType) {
      formRef.current.setFieldValue('name', supplyType.name);
    }
  }, [supplyType]);

  return (
    <SidePanel visible={visible} onClose={onClose}>
      <div className="panel-title">{supplyType?.name ?? 'Crear Tipo de Suministro'}</div>
      {supplyType && (
        <>
          <div className="delete-button-container">
            <Button
              size="small"
              icon
              type="button"
              circular
              onClick={() => setShowDelConfirmation(true)}
              loading={deletingSupplyType}
            >
              <Icon name="trash" />
            </Button>
          </div>
          <Confirm
            open={showDelConfirmation}
            content="Desea eliminar el tipo de suministro seleccionado?"
            onCancel={() => setShowDelConfirmation(false)}
            onConfirm={() => {
              deleteSupplyType().then(onClose);
              setShowDelConfirmation(false);
            }}
            confirmButton="Eliminar"
            size="tiny"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, isSubmitting }: FormikProps<FormValues>) => {
          return (
            <Form onSubmit={handleSubmit} className="panel-form">
              <FastField
                name="name"
                label="Nombre"
                component={InputField}
                value={values.name}
                placeholder="Nombre tipo de suministro"
              />
              <div className="buttons-container">
                <Button size="medium" icon onClick={onClose} type="button">
                  Cancelar &nbsp;
                  <Icon name="times" />
                </Button>
                <Button primary size="medium" icon loading={isSubmitting}>
                  Guardar &nbsp;
                  <Icon name="save" />
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SidePanel>
  );
};
