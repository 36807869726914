import { gql } from '@apollo/client';

export const allDeviceReferencesGQL = gql`
  query allDeviceReferences {
    deviceReferences: allDeviceReferences {
      id
      name
      imageUrl
      deviceType {
        id
        name
      }
    }
  }
`;

export const deviceReferenceGQL = gql`
  query deviceReference($deviceReferenceId: String!) {
    deviceReference(deviceReferenceId: $deviceReferenceId) {
      id
      name
      imageUrl
      deviceType {
        id
        name
      }
    }
  }
`;

export const createDeviceReferenceGQL = gql`
  mutation createDeviceReference($deviceReferenceData: DeviceReferenceInputType!) {
    createDeviceReference(deviceReferenceData: $deviceReferenceData) {
      id
      name
      imageUrl
      deviceType {
        id
        name
      }
    }
  }
`;

export const updateDeviceReferenceGQL = gql`
  mutation updateDeviceReference(
    $deviceReferenceData: DeviceReferenceInputType!
    $deviceReferenceId: String!
  ) {
    updateDeviceReference(
      deviceReferenceData: $deviceReferenceData
      deviceReferenceId: $deviceReferenceId
    ) {
      message
      entityId
    }
  }
`;

export const deleteDeviceReferenceGQL = gql`
  mutation deleteDeviceReference($deviceReferenceId: String!) {
    deleteDeviceReference(deviceReferenceId: $deviceReferenceId) {
      message
      entityId
    }
  }
`;

export const deviceReferenceCreatedGQL = gql`
  subscription deviceReferenceCreated {
    deviceReferenceCreated {
      id
      name
      imageUrl
      deviceType {
        id
        name
      }
    }
  }
`;

export const deviceReferenceUpdatedGQL = gql`
  subscription deviceReferenceUpdated {
    deviceReferenceUpdated {
      id
      name
      imageUrl
      deviceType {
        id
        name
      }
    }
  }
`;

export const deviceReferenceDeletedGQL = gql`
  subscription deviceReferenceDeleted {
    deviceReferenceDeleted {
      message
      entityId
    }
  }
`;
