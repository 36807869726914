import React, { FC } from 'react';
import AppLogo from 'assets/images/app-logo.svg';

import styles from './header.module.scss';

export const Header: FC = () => {
  return (
    <div className={styles.container}>
      <img src={AppLogo} alt="applogo" className={styles.appLogo} />
    </div>
  );
};
